<template>
  <div>
    <el-drawer
      :title="$t('home.MyDocuments')"
      :before-close="handleDrawerClose"
      :visible.sync="drawer"
      direction="rtl"
      size="1060px"
      ref="drawer"
      append-to-body
      v-move-outside
      v-move-draw
    >
      <div class="demo-drawer__content" v-if="!isSkeleton">
        <div class="containerLeft" v-loading="loading">
          <div class="scheduleTitle">
            <el-popover
              ref="folderPopover"
              placement="bottom-start"
              trigger="click">
              <div class="folderBox">
                <div class="add" @click="addFolder">
                  <i class="iconfont icon-Plus"></i>
                </div>
                <div 
                  class="item" 
                  :class="{
                    active: item.check
                  }"
                  v-for="(item, index) in folderList" 
                  :key="index"
                  @click="seeFolderFile(item)">
                  <div class="icon">
                    <i class="iconfont icon-wenjian1"></i>
                  </div>
                  <div 
                    class="name" 
                    v-if="item.mode != 'add' && item.mode != 'edit'">
                    {{ item.subject }}
                    <span>({{ item.fileNumber }})</span>
                  </div>
                  <el-input 
                    v-else 
                    size="mini" 
                    v-model="item.subject" 
                    @keyup.enter.native="createFolderSubmit(item)" 
                  />
                  <el-dropdown
                    v-if="item.wordId && item.wordId != 1"
                    trigger="click" 
                    placement="right-start"
                    @visible-change="(val) => dropDownChange(val, item)">
                    <div
                      class="btn" 
                      :class="item.check ? 'active' : ''"
                      @click.stop="item.check = true">
                      <i class="iconfont icon-Ellipsis"></i>
                    </div>
                    <el-dropdown-menu class="ec-popper" slot="dropdown">
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="iconfont icon-Vector"
                        @click.native="editFolder(item)"
                      >{{ $t("home.Edit") }}</el-dropdown-item>
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="iconfont icon-shanchu"
                        @click.native="deleteFolder(item)"
                      >{{ $t('home.Delete') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div 
                @click.stop="folderSelectChange" 
                class="folder" 
                slot="reference">
                <div class="name">
                  <div class="icon" :class="activeFolderId == 1 ? 'share' : ''">
                    <i class="iconfont icon-wenjian1"></i>
                  </div>
                  {{ folderName }}
                </div>
                <i class="el-icon-arrow-down" :class="($refs.folderPopover && $refs.folderPopover.showPopper) ? 'active' : ''"></i>
              </div>
            </el-popover>
            <div class="searchBox">
              <el-input 
                v-model="folderFileParams.subject" 
                :placeholder="$t('order.Search')" 
                size="small" 
                @input="timeroutInput"
                @keyup.enter.native="getFolderFileList(true)"
              >
                <i 
                  slot="suffix" 
                  @click="getFolderFileList(true)" 
                  class="el-input__icon el-icon-search"
                  style="cursor: pointer;"
                ></i>
              </el-input>
              <div v-if="activeFolderId != 1" class="icon" @click="addFolderFile">
                <i class="iconfont icon-Plus"></i>
              </div>
              <el-button
                v-if="activeFolderId == 1"
                icon="iconfont icon-guolv"
                size="small"
                class="searchButton"
                :type="isFilterSearch ? 'primary' : ''"
                @click="isFilterSearch = !isFilterSearch"
                >{{ $t("xiaoxie.Filter") }}
              </el-button>
            </div>
            <div class="searchBox" v-if="isFilterSearch && activeFolderId == 1">
              <el-date-picker
                v-model="folderFileParams.dateRange"
                size="small"
                type="daterange"
                :start-placeholder="$t('order.Start from—End to').split('—')[0]"
                :end-placeholder="$t('order.Start from—End to').split('—')[1]"
                style="width: 150px;"
                @change="getFolderFileList(true)"
              />
              <el-input
                prefix-icon="iconfont icon-User"
                size="small"
                v-model="folderFileParams.username"
                :placeholder="$t('InformationCenter.Createby')"
                @input="getFolderFileList(true)"
              />
            </div>
          </div>
          <div 
            class="documentList" 
            v-infinite-scroll="load" 
            :infinite-scroll-distance="20">
            <template v-if="dataList.length">
              <div 
                class="item" 
                v-for="(item, index) in dataList" 
                :key="index"
                :class="{
                  'active': (folderContent && folderContent.wordId) == item.wordId,
                  'top': activeFolderId != 1 && item.top,
                  hoverPopoverShow: item.check
                }"
                @click="seeFileContent(item)">
                <member v-if="item.createPerson" :info="item.createPerson" :size="40" />
                <div class="content">
                  <div class="top">
                    <div class="title">
                      <div class="text line1" v-showTootip>{{ item.subject }}</div>
                      <i 
                        class="iconfont tag" 
                        v-if="activeFolderId != 1 && (item.eventLimit == 2 || item.eventLimit == 0)" 
                        :class="item.eventLimit == 2 ? 'icon-bufenrenkejian' : item.eventLimit == 0 ? 'icon-quanburen' : ''"
                      ></i>
                    </div>
                    <el-dropdown
                      v-if="activeFolderId != 1 && item.wordId"
                      trigger="click" 
                      placement="right-start"
                      @visible-change="(val) => dropDownChange(val, item)">
                      <div
                        class="btn" 
                        :class="item.check ? 'active' : ''"
                        @click.stop="item.check = true">
                        <i class="iconfont icon-Ellipsis"></i>
                      </div>
                      <el-dropdown-menu class="ec-popper" slot="dropdown">
                        <el-dropdown
                          trigger="click" 
                          placement="right-start">
                          <el-dropdown-item
                            class="popperMenuItem"
                            :class="isMoveToActive ? 'active' : ''"
                            icon="el-icon-rank"
                            @click.native="isMoveToActive = !isMoveToActive"
                          >{{ $t("Move to") }}</el-dropdown-item>
                          <el-dropdown-menu class="ec-popper" slot="dropdown">
                            <el-dropdown-item
                              class="popperMenuItem"
                              v-for="(row, i) in folderList.filter(item => item.wordId != 1 && item.wordId != activeFolderId)"
                              :key="i"
                              icon="iconfont icon-wenjian1"
                              @click.native="folderMoveToWord(item, row)"
                            >{{ row.subject }}</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown-item
                          class="popperMenuItem"
                          icon="iconfont icon-Vector"
                          @click.native="fileTop(item)"
                        >{{ !item.top ? $t("Topping") : $t("home.Cancel Topping") }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div class="time">{{ item.updateTime }}</div>
                </div>
              </div>
            </template>
            <el-empty
              v-else
              :description="$t('order.No message')"
              :image="$emptyImage"
              :image-size="90"
            ></el-empty>
          </div>
        </div>
        <div class="documentRightBox" v-if="folderContent">
          <template v-if="activeFolderId != 1">
            <div class="header">
              <el-input 
                v-if="isContentEdit"
                v-model="folderContent.subject" 
                :placeholder="$t('home.Please enter file name')" 
                @keyup.enter.native="editSubmit"
              />
              <div v-else-if="folderContent.wordId" class="textTitle line1" v-showTootip>{{ folderContent.subject }}</div>
              <div class="right">
                <div class="companyMoreWrapper" v-if="folderContent.visibleUsers && folderContent.visibleUsers.length">
                  <template v-for="(item, index) in memberListComputed">
                    <member 
                      :key="index"
                      :info="item" 
                      :size="32" 
                      :placement="'bottom'"
                      v-if="index < 3"
                    ></member>
                  </template>
                  <div class="moreImage" @click="onMoreList">
                    <el-image :src="folderContent.visibleUsers[folderContent.visibleUsers.length - 1] && folderContent.visibleUsers[folderContent.visibleUsers.length - 1].avatarFileUrl" />
                    <div class="mask">+{{ folderContent.visibleUsers.length - memberListComputed.length }}</div>
                  </div>
                </div>
                <div 
                  class="addMember" 
                  v-if="isContentEdit" 
                  @click="addMember">
                  <div class="addIcon">
                    <i class="iconfont icon-Plus"></i>
                  </div>
                  {{ !folderContent.wordId ? $t('people.NewMessage') : $t('people.EditMessage') }}
                </div>
                <toolTipIcon 
                  v-if="!isContentEdit"
                  icon="iconfont icon-a-fanyi1" 
                  :tooltipText="$t('home.Translate')"
                  @click="translateRictText"
                />
                <toolTipIcon 
                  icon="iconfont icon-shanchu" 
                  :tooltipText="$t('order.Delete')"
                  @click="deleteFolderFile"
                />
                <el-button 
                  size="small" 
                  type="primary" 
                  :style="{
                    background: isContentEdit ? '#076F49' : ''
                  }"
                  :loading="fileButtonLoading"
                  @click="folderFileSubmit"
                > {{ !isContentEdit ? $t("home.Edit") : $t("order.Update") }} </el-button>
                <toolTipIcon 
                  v-if="!isContentEdit"
                  icon="iconfont icon-download" 
                  :tooltipText="$t('ExportWord')"
                  @click="saveWord"
                />
              </div>
            </div>
            <div class="editorBox">
              <EcEditor
                v-model="folderContent.content"
                :isToolBar="isContentEdit"
                :isBorder="false"
                fileUploadCode="file_event_content_img"
                :editorConfig="{
                  readOnly: !isContentEdit
                }"
                @click.native="!isContentEdit ? $replayImgShow($event) : ''"
              />
              <div v-if="textTranslation" class="contentHtml" v-html="textTranslation" @click="$replayImgShow($event)"></div>
            </div>
          </template>
          <template v-else>
            <div class="fileContentBox">
              <div class="left">
                <div class="title">
                  {{ folderContent.subject }}
                  <toolTipIcon 
                    icon="iconfont icon-a-fanyi1" 
                    :tooltipText="$t('home.Translate')"
                    @click="translateRictText"
                  />
                </div>
                <div class="time">{{ folderContent.updateTime }}</div>
                <div class="isTranslateBox">
                  <div class="contentHtml" v-html="folderContent.content" @click="$replayImgShow($event)"></div>
                  <div v-if="textTranslation" class="contentHtml" v-html="textTranslation" @click="$replayImgShow($event)"></div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="skeletonBox demo-drawer__content" v-else>
        <div class="containerLeft">
          <div class="scheduleTitle">
            <el-skeleton loading animated>
              <template slot="template">
                <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 100%;height: 32px;" />
              </template>
            </el-skeleton>
          </div>
          <div class="documentList">
            <div class="item">
              <el-skeleton class="skeletonItem" loading animated>
                <template slot="template">
                  <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                  <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                </template>
              </el-skeleton>
            </div>
            <div class="item">
              <el-skeleton class="skeletonItem" loading animated>
                <template slot="template">
                  <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                  <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                </template>
              </el-skeleton>
            </div>
            <div class="item">
              <el-skeleton class="skeletonItem" loading animated>
                <template slot="template">
                  <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                  <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                </template>
              </el-skeleton>
            </div>
            <div class="item">
              <el-skeleton class="skeletonItem" loading animated>
                <template slot="template">
                  <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                  <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                </template>
              </el-skeleton>
            </div>
            <div class="item">
              <el-skeleton class="skeletonItem" loading animated>
                <template slot="template">
                  <el-skeleton-item variant="div" style="width: 136px;height: 20px;" />
                  <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                </template>
              </el-skeleton>
            </div>
          </div>
        </div>
        <div class="documentRightBox">
          <div class="header">
            <el-skeleton class="headerSkeleton" animated>
              <template slot="template">
                <el-skeleton-item variant="div" style="width: 106px;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 253px;height: 32px;" />
              </template>
            </el-skeleton>
          </div>
          <div class="quill-editor" style="padding: 20px 30px;overflow: overlay;">
            <el-skeleton animated >
              <template slot="template">
                <el-skeleton-item variant="div" style="width: 106px;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 253px;height: 32px;" />
                <el-skeleton-item variant="image" style="width: 100%; height: 300px;" />
                <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 100%;height: 20px;" />
                <el-skeleton-item variant="div" style="width: 80%;height: 20px;" />
              </template>
            </el-skeleton>
          </div>
        </div>
      </div>
    </el-drawer>
    <seeAuthMemberDialog 
      v-if="folderContent"
      ref="seeAuthMemberDialog" 
      :visibleUsers.sync="folderContent.visibleUsers"
      :eventLimit.sync="folderContent.eventLimit"
    />
  </div>
</template>

<script>
import { 
  createFolder, 
  getFolderList, 
  editFolder, 
  deleteFolder,
  getShareFolderList,
  getShareOrDefaultNumber,
  saveToWord
} from "@/api/document.js";
import { translateMessage } from "@/api/message";
import { uploadFileUrl } from "@/api/files";
import seeAuthMemberDialog from "../seeAuthMemberDialog";

//引入Qill插件
import Quill from "quill";
// 源码中是import直接倒入，这里要用Quill.import引入
const Link = Quill.import("formats/link");
// 自定义a链接
class FileBlot extends Link {
  // 继承Link Blot
  static create (value) {
    let node = undefined;
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value)
    } else {
      // 自定义Link Blot
      node = super.create(value.href)
      node.href = value.href
      node.innerText = value.innerText
      // node.setAttribute('download', value.innerText);  // 左键点击即下载
    }
    return node;
  }
}
FileBlot.blotName = "link" // 这里不用改，如果需要也可以保留原来的，这里用个新的blot
FileBlot.tagName = "A"
Quill.register(FileBlot);

export default {
  name: "myDocuments",
  components: {
    seeAuthMemberDialog
  },
  data() {
    return {
      engine: null,
      items: [['collapse'],['heading','bold']],
      drawer: false,
      loading: false,
      isSkeleton: false,
      fileButtonLoading: false,
      isFilterSearch: false,
      isMoveToActive: false,
      folderContent: null,
      folderColor: [""],
      textTranslation: "",
      folderList: [{
        fileNumber: 0,
        wordId: null,
        subject: this.$t('home.defaultFolder')
      }, {
        fileNumber: 0,
        wordId: 1,
        subject: this.$t('home.Share with me')
      }],
      folderName: "默认文件夹",
      dataList: [],
      isContentEdit: false,
      folderFileParams: {
        type: 1,
        subject: "",
        username: "",
        dateRange: [],
        page: 1,
        size: 30
      },
      folderTotalPage: 0,
      activeFolderId: null,
      timeout: null
    }
  },
  mounted() {
  },
  computed: {
    memberListComputed() {
      if(this.folderContent) {
        if(this.folderContent.visibleUsers.length > 1) {
          return this.folderContent.visibleUsers.filter((item, index) => index < this.folderContent.visibleUsers.length - 1)
        } else {
          return this.folderContent.visibleUsers;
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    open() {
      this.drawer = true;
      this.isSkeleton = true;
      this.getFolderList(true);
      document.addEventListener('keydown', this.keydownCtrlS);
      
    },
    handleDrawerClose() {
      this.drawer = false;
      this.folderContent = null;
      this.textTranslation = "";
      document.removeEventListener('keydown', this.keydownCtrlS);
    },
    async translateRictText() {
      if(this.textTranslation) {
        this.textTranslation = "";
        return;
      }
      const data = await translateMessage({
        text: this.folderContent.content
      });
      this.textTranslation = data;
    },
    keydownCtrlS(e) {
      if (e.keyCode == 83 && (navigator.platform.match("Mac")?e.metaKey:e.ctrlKey)){
        e.preventDefault();
        if(this.isContentEdit) {
          this.folderFileSubmit(false);
        }
      }
    },
    folderMoveToWord(item, row) {
      let obj = JSON.parse(JSON.stringify(item))
      delete obj.createTime;
      delete obj.fileNumber;
      delete obj.status;
      delete obj.top;
      delete obj.updateTime;
      delete obj.check;
      obj.parentWordId = row.wordId;
      obj.visibleUsers = item.visibleUsers && item.visibleUsers.map(val => val.openId).join(",");
      editFolder(obj).then(() => {
        this.$message.success(this.$t('home.editSuccess'));
        this.getFolderFileList(true);
        this.getFolderList(true);
        this.folderContent = null;
      }).finally(() => {
        this.isMoveToActive = false;
      })
    },
    saveWord() {
      saveToWord(this.folderContent.wordId).then((data) => {
        //1.我们需要通过blob对象来处理，需要模拟一个<a>标签来提供下载链接
        const elink = document.createElement('a');
        elink.style.display = 'none';
        //2.blob是二进制大对象，接受后台返回的数据流，导出数据
        const blob = new Blob([data], { type: 'application/msword' });
        //3.创建一个url 对象，并将blob实例作为参数传入，这样href的值就是以blob开头的一个文件流下载链接,类似于href="blob:http://127.0.0.1xxxxx"
        const blobUrl = URL.createObjectURL(blob);
        elink.href = blobUrl;
        elink.download = this.folderContent.subject;//下载后的文件名
        //4.将a标签加入body,并手动点击触发下载
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      })
    },
    splitStr(str){
      return str.split('/')[str.split('/').length - 1]
    },
    folderSelectChange() {
      this.getFolderList(true);
    },
    addMember() {
      this.$refs.seeAuthMemberDialog.open();
    },
    onMoreList() {
      this.$refs.seeAuthMemberDialog.open(false);
    },
    seeFolderFile(row) {
      if(row.mode == 'add' || row.mode == 'edit') return;
      this.folderName = row.subject;
      this.activeFolderId = row.wordId;
      this.$refs.folderPopover.showPopper = false;
      this.folderContent = null;
      this.textTranslation = "";
      this.getFolderFileList(true);
    },
    editFolder(row) {
      row.mode = 'edit';
    },
    deleteFolder(row) {
      this.$confirm(
        this.$t('home.The folder cannot be restored after deletion'),
        this.$t('home.Are you sure to delete the folder'),
        {
          confirmButtonText: this.$t('deleteEmployee.Confirmz'),
          cancelButtonText: this.$t('deleteEmployee.Cancel'),
          type: 'warning',
        }
      ).then(() => {
        deleteFolder(row.wordId).then(() => {
          this.$message.success(this.$t('todo.Delete Successful'));
          this.getFolderList(true);
        })
      }).catch(() => {});
    },
    fileTop({wordId, top, parentWordId, eventLimit, visibleUsers, content }) {
      editFolder({
        wordId,
        eventLimit,
        visibleUsers: visibleUsers && visibleUsers.map(item => item.openId).join(","),
        content,
        top: !top ? 1 : 0,
        parentWordId
      }).then(() => {
        this.$message.success(this.$t('home.editSuccess'));
        this.getFolderFileList(true);
        this.getFolderList(true);
        this.folderContent = null;
      }).finally(() => {
        this.fileButtonLoading = false;
      })
    },
    getFolderList(clear = false) {
      getFolderList({
        type: 0,
        page: 1,
        size: 10000
      }).then((data) => {
        if(clear) this.folderList = [{
          fileNumber: 0,
          wordId: null,
          subject: this.$t('home.defaultFolder')
        }, {
          fileNumber: 0,
          wordId: 1,
          subject: this.$t('home.Share with me')
        }];
        this.folderList = this.folderList.concat(data.list.map(item => ({
          ...item,
          mode: "",
          check: false
        })));
        if(!this.activeFolderId) {
          this.folderName = this.folderList[0].subject;
          this.activeFolderId = this.folderList[0].wordId;
        }
        this.getFolderDefaultShareNumber();
        this.getFolderFileList(true);
      })
    },
    getFolderDefaultShareNumber() {
      getShareOrDefaultNumber().then((data) => {
        this.folderList.forEach((row) => {
          if(row.wordId == null) {
            row.fileNumber = data.defaultFileCount;
          }
          if(row.wordId == 1) {
            row.fileNumber = data.shareToMeCount;
          }
        })
      })
    },
    load() {
      if(this.folderFileParams.page < this.folderTotalPage) {
        this.folderFileParams.page += 1;
        this.getFolderFileList();
      }
    },
    timeroutInput() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getFolderFileList(true)
      }, 500)
    },
    getFolderFileList(clear = false) {
      let api = this.activeFolderId == 1 ? getShareFolderList : getFolderList;
      let params = this.activeFolderId != 1 ? {
        ...this.folderFileParams,
        parentWordId: this.activeFolderId
      } : {
        startTime: this.folderFileParams.dateRange && this.folderFileParams.dateRange.length ? this.$dayjs(this.folderFileParams.dateRange[0]).startOf('date').format("YYYY-MM-DD HH:mm:ss") : "",
        endTime: this.folderFileParams.dateRange && this.folderFileParams.dateRange.length ? this.$dayjs(this.folderFileParams.dateRange[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss") : "",
        subject: this.folderFileParams.subject,
        userName: this.folderFileParams.username,
        page: this.folderFileParams.page,
        size: this.folderFileParams.size,
      }
      this.loading = true;
      api(params).then((data) => {
        if(clear) this.dataList = [];
        this.dataList = this.dataList.concat(data.list.map(item => ({
          ...item,
          check: false
        })));
        this.folderTotalPage = data.list.pages;
        if(this.folderContent) return;
        this.seeFileContent(this.dataList.length && this.dataList[0]);
      }).finally(() => {
        this.loading = false;
        setTimeout(() => {
          this.isSkeleton = false;
        }, 200);
      })
    },
    dropDownChange(val, row) {
      if(!val) {
        row.check = false;
      }
      this.isMoveToActive = false;
    },
    addFolder() {
      this.folderList.splice(2, 0, {
        mode: "add",
        type: 0,
        eventLimit: 1,
        subject: this.$t('home.Unnamed')
      })
    },
    addFolderFile() {
      let obj = {
        wordId: null,
        parentWordId: this.activeFolderId,
        subject: this.$t('home.Unnamed'),
        content: "",
        eventLimit: 1,
        visibleUsers: [],
        type: 1
      }
      this.dataList.unshift(obj);
      this.folderContent = null;
      this.seeFileContent(obj);
      this.isContentEdit = true;
    },
    deleteFolderFile() {
      this.$confirm(
        this.$t('home.The folder cannot be restored after deletion'),
        this.$t('home.Are you sure to delete the folder'),
        {
          confirmButtonText: this.$t('deleteEmployee.Confirmz'),
          cancelButtonText: this.$t('deleteEmployee.Cancel'),
          type: 'warning',
        }
      ).then(() => {
        deleteFolder(this.folderContent.wordId).then(() => {
          this.$message.success(this.$t('todo.Delete Successful'));
          this.getFolderFileList(true);
          this.getFolderList(true);
          this.folderContent = null;
        })
      }).catch(() => {});
    },
    seeFileContent(row) {
      if(row.wordId) {
        this.isContentEdit = false;
      } else {
        this.isContentEdit = true;
      }
      this.textTranslation = "";
      this.folderContent = JSON.parse(JSON.stringify(row));
    },
    createFolderSubmit(row) {
      let rowObject = JSON.parse(JSON.stringify(row));
      if(row.mode == 'add') {
        delete rowObject.mode;
        createFolder(rowObject).then(() => {
          this.$message.success(this.$t('home.createSuccess'));
          this.getFolderList(true);
        }).finally(() => {
          row.mode = "";
        });
        return;
      }
      let obj = {
        wordId: row.wordId,
        type: 0,
        eventLimit: 1,
        subject: row.subject
      }
      editFolder(obj).then(() => {
        this.$message.success(this.$t('home.editSuccess'));
        this.getFolderList(true);
      }).finally(() => {
        row.mode = "";
      });
    },
    folderFileSubmit(editFalse) {
      if(!this.isContentEdit) {
        this.isContentEdit = true;
        this.textTranslation = "";
        return;
      }
      if(!this.folderContent.subject) {
        this.$message.warning(this.$t("home.Please enter file name"))
        return;
      }
      if(!this.folderContent.content) {
        this.$message.warning(this.$t("order.Please input content"))
        return;
      }
      this.fileButtonLoading = true;
      let obj = JSON.parse(JSON.stringify(this.folderContent));
      if(obj.eventLimit == 1 || obj.eventLimit == 0) {
        obj.visibleUsers = null;
      } else {
        obj.visibleUsers = obj.visibleUsers.map(item => item.openId).join(",");
      }
      if(!this.folderContent.wordId) {
        delete obj.wordId;
        createFolder(obj).then(() => {
          this.$message.success(this.$t('home.createSuccess'));
          this.getFolderFileList(true);
          this.getFolderList(true);
          this.folderContent = null;
        }).finally(() => {
          this.fileButtonLoading = false;
          if(!editFalse) return;
          this.isContentEdit = false;
        })
        return;
      }
      delete obj.createTime;
      delete obj.fileNumber;
      delete obj.status;
      delete obj.top;
      delete obj.updateTime;
      delete obj.updateTime;
      delete obj.check;
      editFolder(obj).then(() => {
        this.$message.success(this.$t('home.editSuccess'));
        this.getFolderFileList(true);
      }).finally(() => {
        this.fileButtonLoading = false;
        if(!editFalse) return;
        this.isContentEdit = false;
      })
    }
  },
}
</script>

<style lang="less" scoped>
.folder {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  .el-icon-arrow-down {
    transition: all .3s;
    &.active {
      transform: rotate(180deg);
    }
  }
  .name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #122545;
    .icon {
      background: #ecf6ff;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.share {
        background: #FFFAF5;
        i {
          color: #FF8311;
        }
      }
      i {
        font-size: 12px;
        color: #1093FD;
      }
    }
  }
}
.searchBox {
  display: flex;
  gap: 12px;
  margin-top: 10px;
  /deep/ .el-input {
    flex: 1;
  }
  .searchButton {
    width: auto !important;
    padding: 7px 12px;
    /deep/ .icon-guolv {
      position: relative;
      top: 1px;
      margin-right: 2px;
    }
  }
  .icon {
    width: 32px;
    height: 32px;
    background: #122545;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.folderBox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 8px;
  max-width: 476px;
  .add {
    width: 140px;
    height: 81px;
    border-radius: 4px;
    background: #F7F9FC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      color: #637381;
      font-size: 30px;
    }
  }
  .item {
    width: 140px;
    height: 81px;
    border-radius: 4px;
    background: #F2F8FE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 0 16px;
    cursor: pointer;
    position: relative;
    &:nth-child(3) {
      background: #FFFAF5;
      .icon {
        background: #ff831114;
        i {
          color: #FF8311;
        }
      }
    }
    /deep/ .el-dropdown {
      position: absolute;
      top: 5px;
      right: 5px;
      .btn {
        background: #FFFFFF;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #757D8A;
        cursor: pointer;
        transition: all 0.3s;
        opacity: 0;
        &.active {
          background: #122545;
          i {
            color: #ffffff;
          }
        }
        i {
          transform: scale(.5);
        }
      }
    }
    
    .icon {
      background: #dbedfd;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 12px;
        color: #1093FD;
      }
    }
    /deep/ .el-input__inner {
      background: transparent;
      padding: 0 7px;
    }
    .name {
      font-size: 12px;
      font-weight: 600;
      color: #122545;
      span {
        font-weight: 400;
        color: #637381;
      }
    }
    &.active {
      /deep/ .el-dropdown {
        .btn {
          opacity: 1;
        }
      }
    }
    &:hover {
      /deep/ .el-dropdown {
        .btn {
          opacity: 1;
        }
      }
    }
  }
}
.documentList {
  width: 100%;
  height: calc(100% - 96px);
  overflow: overlay;
  border-top: 1px solid #E0E4EA;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .item {
    width: 100%;
    padding: 15px 23px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    gap: 10px;
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        align-items: center;
        .title {
          font-size: 14px;
          color: #637381;
          line-height: 19.6px;
          flex: 1;
          min-width: none;
          transition: all .3s;
          display: flex;
          align-items: center;
          gap: 8px;
          .text {
            max-width: 152px;
          }
          .tag {
            font-size: 16px;
            color: #004C97;
            &.icon-jinzijikejian {
              color: #004C97;
            }
            &.icon-bufenrenkejian {
              color: #076F49;
            }
            &.icon-quanburen {
              color: #FF8311;
              font-size: 12px;
            }
          }
        }
        /deep/ .el-dropdown {
          .btn {
            background: #FFFFFF;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #757D8A;
            cursor: pointer;
            transition: all 0.3s;
            opacity: 0;
            &.active {
              background: #122545;
              i {
                color: #ffffff;
              }
            }
            i {
              transform: scale(.5);
            }
          }
        }
      }
      .time {
        font-size: 12px;
        color: #757D8A;
      }
    }
    
    &:hover {
      background: #F7F9FC;
      /deep/ .el-dropdown {
        .btn {
          opacity: 1 !important;
        }
      }
      .title {
        font-weight: 500;
        color: #122545;
      }
    }
    &.hoverPopoverShow {
      .active;
    }
    &.top {
      background: #F7F9FC;
      .title {
        font-weight: 500;
        color: #122545;
      }
    }
    &.active {
      background: #F7F9FC;
      position: relative;
      /deep/ .el-dropdown {
        .btn {
          opacity: 1 !important;
        }
      }
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        background: #076F49;
        width: 2px;
        height: 100%;
      }
      .title {
        font-weight: 500;
        color: #122545;
      }
    }
  }
}
.documentRightBox {
  flex: 1;
  min-width: 0;
  position: relative;
  .editorBox {
    display: flex;
    gap: 20px;
    &>div {
      flex: 1;
    }
    .contentHtml {
      height: calc(100vh - 164px) !important
    }
    /deep/ .editorBox {
      height: calc(100vh - 180px) !important;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: 0 20px;
    border-bottom: 1px solid #E0E4EA;
    /deep/ .el-input {
      width: 30%;
      .el-input__inner {
        border: none;
        padding: 0;
        font-size: 18px;
        color: #122545;
        &:focus {
          border: none !important;
        }
      }
    }
    .textTitle {
      font-size: 18px;
      color: #122545;
      font-weight: 600;
      width: 250px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 15px;
      /deep/ .box {
        i {
          font-size: 18px;
        }
      }
    }
  }
  /deep/ .quill-editor {
    border: none !important;
    height: calc(100vh - 164px) !important;
    .ql-toolbar {
      display: block;
      border: none;
      border-bottom: 1px solid #E0E4EA;
    }
    .ql-container {
      height: calc(100% - 65px);
    }
    &.isTabbarShow {
      .ql-toolbar {
        display: none;
      }
      .ql-container {
        height: 100%;
      }
    }
  }
  .fileContentBox {
    padding: 30px 30px 0 30px;
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 33.6px;
      color: #122545;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .time {
      font-size: 12px;
      font-weight: 500;
      line-height: 16.8px;
      color: #637381;
      margin-bottom: 20px;
    }
    .contentHtml {
      height: calc(100vh - 200px);
      padding: 0;
      flex: 1;
      min-width: 0;
      /deep/ ol {
        padding-inline-start: 20px;
        li {
          list-style: auto;
          padding: unset;
        }
      }
    }
    .isTranslateBox {
      display: flex;
      gap: 44px;
      /deep/ img {
        width: 100%;
      }
    }
  }
}
.companyMoreWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-size: 16px;
  color: #48AC42;
  .moreImage {
    width: 40px;
    height: 40px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin: 0;
    .el-image {
      width: 100%;
      height: 100%;
      border: 2px solid #F4F6F9;
      border-radius: 50%;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.5);
      width: 100%;
      height: 100%;
      font-weight: 600;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #f4f6f9;
    }
  }
  /deep/ .member-avatar {
    position: relative;
    z-index: 0;
    margin: 0;
    margin-right: -10px;
    .ma-avatar {
      border: 2px solid #F4F6F9;
      border-radius: 50%;
    }
    &:hover {
      z-index: 1;
    }
  }
  .companyMoreTo {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-left: 40px;
  }
}
.addMember {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #637381;
  cursor: pointer;
  .addIcon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px dashed #E0E4EA;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 14px !important;
      color: #E0E4EA !important;
    }
    &:hover {
      border: 1px dashed #076F49;
      i {
        color: #076F49 !important;
      }
    }
  }
}
.skeletonBox /deep/ {
  .el-skeleton__item {
    display: block;
    margin-top: 14px;
    &:nth-child(1) {
      margin-top: 0;
    }
    &.el-skeleton__image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .header {
    .headerSkeleton {
      width: 100%;
    }
    /deep/ .el-skeleton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-skeleton__item {
        margin-top: 0;
      }
    }
  }
  .skeletonItem {
    width: 100%;
  }
}

</style>