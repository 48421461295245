<template>
  <div>
    <el-dialog
      :title="$t('home.Invitation Code Management')"
      :visible.sync="dialogVisible"
      :width="active == 1 ? '400px' : '733px'"
      append-to-body
      :before-close="handleClose">
      <div class="tabsBox">
        <div 
          class="item" 
          :class="item.id == active ? 'active' : ''"
          v-for="(item, index) in tabs" 
          :key="index"
          @click="active = item.id">
          <i :class="item.icon"></i>
          {{ $t(`home.${item.name}`) }}
        </div>
      </div>
      <div class="generateCode" v-if="active == 1">
        <div class="codeInput">
          <el-input v-model="generateCode" size="small" readonly />
          <el-button type="primary" @click="generateEvent" size="small">{{ $t('Generate') }}</el-button>
        </div>
        <div class="tip">{{ $t('home.This invitation code can only be used once') }}</div>
      </div>
      <div class="historyList" v-else>
        <el-table
          :data="historyList"
          style="width: 100%">
          <el-table-column
            prop="createPerson"
            width="120"
            :label="$t('InformationCenter.Createby')">
            <template slot-scope="{row}">
              <div class="customerName" v-if="row.createPerson">
                <member :info="row.createPerson" :size="28"></member>
                <span>{{ row.createPerson.nickName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            width="120"
            :label="$t('home.Invitation code')">
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('Files.CreateTime')"
            width="180">
          </el-table-column>
          <el-table-column
            prop="updateTime"
            :label="$t('Usage time')"
            width="180">
            <template slot-scope="{row}">
              {{ !row.status ? row.updateTime : '' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label=""
            width="100">
            <template slot-scope="{row}">
              <el-popover
                placement="right"
                trigger="hover"
                :disabled="Boolean(row.status)">
                <div class="customerName" v-if="row.updatePerson">
                  <member :info="row.updatePerson" :size="28"></member>
                  <span>{{ row.updatePerson.nickName }}</span>
                </div>
                <div
                  slot="reference"
                  class="isUse"
                  :class="{
                    active: !row.status,
                    expire: isPastDateOlderThanSevenDays(row.createTime)
                  }">
                  {{ row.status ? isPastDateOlderThanSevenDays(row.createTime) ? $t('Expired') : $t('Not used') : $t('dashboardCapacityMonit.Occupied') }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer" v-if="active == 1">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="sendEmailCode">
          {{ $t('home.Send via email') }}
        </el-button>
        <el-button type="primary" size="small" @click="confirm">
          {{ $t('home.Copy Invite code') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="isSendEmail"
      width="358px"
      :wrapperClosable="true"
      :title="$t('home.Send via email')+' '+$t('home.Invitation code')"
      :before-close="sendEmailClose"
      append-to-body
      v-move-outside
    >
      <el-input
        :placeholder="$t('home.Invitation code')"
        readonly
        v-model="sendEmailForm.inviteCode"
        style="margin-bottom: 12px;"
      />
      <el-input
        :placeholder="$t('people.Email')"
        v-model="sendEmailForm.email"
      />
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="sendEmailClose"
          size="small"
          style="color: #076F49;">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="sendEmailFormCode"
          :loading="sendEmailLoading">
          {{ $t('people.send') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getGenerateInviteCode, sendEmailInviteCode, getInviteCodeUseRecord } from "@/api/customer";

export default {
  name: "inviteCodeMange",
  data() {
    return {
      active: "1",
      dialogVisible: false,
      isSendEmail: false,
      sendEmailLoading: false,
      generateCode: "",
      sendEmailForm: {
        inviteCode: "",
        email: ""
      },
      tabs: [{
        id: 1,
        icon: "el-icon-takeaway-box",
        name: 'Generate invitation code'
      }, {
        id: 2,
        icon: "el-icon-time",
        name: "Invitation Code Usage Record"
      }],
      historyList: []
    }
  },
  computed: {
    isPastDateOlderThanSevenDays() {
      return (dateStr) => {
        const targetDate = new Date(dateStr);
        const currentDate = new Date();
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const daysSinceTarget = Math.floor((currentDate - targetDate) / millisecondsPerDay);
        return daysSinceTarget > 7;
      }
    }
  },
  watch: {
    active(val) {
      if(val == 2) {
        getInviteCodeUseRecord({
          page: 1,
          size: 100000
        }).then((data) => {
          this.historyList = data.list;
        })
      }
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.generateCode = "";
      this.active = "1";
    },
    sendEmailClose() {
      this.isSendEmail = false;
      this.sendEmailForm = {
        inviteCode: "",
        email: ""
      }
    },
    sendEmailCode() {
      if(!this.generateCode) {
        this.$message.warning(this.$t('Please')+' '+this.$t('home.Generate invitation code'))
        return;
      }
      this.isSendEmail = true;
      this.sendEmailForm.inviteCode = this.generateCode;
    },
    sendEmailFormCode() {
      if(!this.sendEmailForm.email) {
        this.$message.warning(this.$t('registerLoging.PleaseinputaEmail'))
        return;
      }
      this.sendEmailLoading = true;
      sendEmailInviteCode(this.sendEmailForm).then(() => {
        this.$message.success(this.$t("home.Sent successfully"));
        this.sendEmailClose();
      }).finally(() => {
        this.sendEmailLoading = false;
      })
    },
    generateEvent() {
      getGenerateInviteCode({
        seconds: 604800,
        type: 1
      }).then(data => {
        this.generateCode = data;
      })
    },
    confirm() {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(this.generateCode).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
      });
    }
  },
}
</script>

<style lang="less" scoped>
.tabsBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .item {
    padding: 0 12px 4px 12px;
    font-size: 12px;
    line-height: 22px;
    color: #637381;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 6px;
    i {
      font-size: 14px;
    }
    &.active {
      color: #076F49;
      font-weight: 600;
      border-bottom: 2px solid #076F49;
    }
    &:hover {
      color: #076F49;
      opacity: .8;
    }
  }
}
.generateCode {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  .codeInput {
    display: flex;
    gap: 10px;
  }
  .tip {
    font-size: 12px;
    line-height: 22px;
    color: #A3B0C6;
  }
}
.historyList {
  margin-top: 20px;
  .isUse {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #637381;
    cursor: pointer;
    &.expire {
      color: #DC3545;
    }
    &.active {
      color: #076F49;
    }
  }
}
</style>