<template>
  <el-drawer
    :title="$t('customerInfo.MessageBoards')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="1060px"
    append-to-body
    v-move-outside
    v-move-draw="990"
  >
    <div class="drawerHeader" slot="title">
      <div class="left">
        <div 
          class="item" 
          v-for="(item, index) in titleTable"
          :key="index"
          :class="item.id == titleTableActive ? 'active' : ''"
          @click="titleTableActive = item.id"
        >
          <i :class="`iconfont ${item.icon}`"></i>
          {{ item.name }}
        </div>
      </div>
      <div class="right" v-if="titleTableActive != 3 && titleTableActive != 4">
        <el-autocomplete
          ref="autocomplete"
          clearable
          v-model="keyword"
          size="small"
          :fetch-suggestions="querySearchAsync"
          placeholder="搜索关键词"
          suffix-icon="el-icon-search"
          popper-class="assisteInput"
          @clear="keywordClear"
        >
          <template slot-scope="{ item }">
            <div class="autoList" @click.stop>
              <div 
                class="configItem" 
                v-for="(row, idx) in item.config" 
                :key="idx">
                <div class="label">{{ row.title }}</div>
                <div 
                  class="item" 
                  v-for="(xm, i) in row.list" 
                  :key="i"
                  @click="selectChage(xm)">
                  <img :src="row.img" />
                  <div class="text">{{ xm.mailTemplateName || xm.newbieGuideName }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-autocomplete>
      </div>
    </div>
    <emailTemplate v-if="titleTableActive == 1" :keyword="keywordObject" />
    <guide v-if="titleTableActive == 2" :keyword="keywordObject" />
    <banner v-if="titleTableActive == 3" />
    <echolaAi v-if="titleTableActive == 4" />
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import emailTemplate from "./components/template";
import guide from "./components/guide";
import banner from "./components/banner";
import echolaAi from "./components/echolaAi";
import { getEmailTemplateSearchList, getGuideDirectoryList } from "@/api/common";
export default {
  name: "emailAssiste",
  components: {
    emailTemplate,
    guide,
    banner,
    echolaAi
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      keyword: "",
      keywordObject: null,
      titleTableActive: 1,
      titleTable: [{
        id: 1,
        icon: "icon-a-youjian51",
        name: this.$t('Email template')
      }, {
        id: 2,
        icon: "icon-a-youjian52",
        name: this.$t('Getting started')
      }, {
        id: 3,
        icon: "icon-a-youjian53",
        name: this.$t('Email banner')
      }, {
        id: 4,
        icon: "icon-a-youjian54",
        name: 'Echola AI'
      }]
    }
  },
  computed: {
    ...mapGetters([])
  },
  watch: {
    keyword(val) {
      if(!val) {
        this.keywordObject = null;
      }
    }
  },
  methods: {
    open(active = 1) {
      console.log(active);
      this.drawer = true;
      this.titleTableActive = active;
    },
    handleClose() {
      this.drawer = false;
      this.$emit("close");
      this.titleTableActive = null;
    },
    selectChage(val) {
      if(val.mailTemplateId) {
        this.titleTableActive = 1;
      } else {
        this.titleTableActive = 2;
      }
      this.keyword = val.mailTemplateName || val.newbieGuideName;
      this.keywordObject = val;
      this.$refs.autocomplete.close();
    },
    keywordClear() {
      this.keywordObject = null;
    },
    async querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([])
        return;
      }
      let arr = [{
        config: [{
          img: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/%E9%82%AE%E4%BB%B6%20%283%29%201.png",
          title: `在邮件模板中搜"${queryString}"`,
          list: []
        }, {
          img: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/fileIcon.png",
          title: `在新手入门中搜"${queryString}"`,
          list: []
        }]
      }]
      await getEmailTemplateSearchList(queryString).then((data) => {
        arr[0].config[0].list = data;
      })
      await getGuideDirectoryList({
        collect: 0,
        page: 1,
        size: 10000,
        keyWord: queryString
      }).then((data) => {
        arr[0].config[1].list = data;
      })
      cb(arr)
    }
  },
}
</script>

<style lang="less" scoped>
.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  height: 32px;
  .left {
    display: flex;
    gap: 60px;
    .item {
      padding: 0 9px;
      font-size: 16px;
      color: #A3B0C6;
      font-weight: 400;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      i {
        font-size: 24px;
        color: #A3B0C6;
        font-weight: 400;
      }
      &.active {
        color: #122545;
        font-weight: 600;
        i {
          color: #122545;
          font-weight: 400;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background: #122545;
          position: absolute;
          left: 0;
          bottom: -17px;
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
}
.autoList {
  padding: 0;
  .configItem {
    border-bottom: 1px solid #E0E4EA;
    padding-bottom: 12px;
    &:last-child {
      border: none;
    }
    .label {
      font-size: 12px;
      line-height: 16.8px;
      margin: 12px 0;
      color: #A3B0C6;
      padding: 0 12px;
      word-wrap:break-word; 
      word-break:break-all;
      white-space: pre-wrap;
    }
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 6px;
      padding: 0 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #f8f8f8;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .text {
        font-size: 12px;
        line-height: 22px;
        color: #637381;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<style lang="less">
.assisteInput {
  .el-autocomplete-suggestion__wrap {
    padding: 0 !important;
  }
  .el-scrollbar__view {
    li {
      padding: 0 !important;
      &:hover {
        background: transparent !important;
      }
    }
  }
}
</style>
