<template>
  <div class="demo-drawer__content" v-loading="loading">
    <div class="containerLeft">
      <div class="directoryList">
        <template v-if="directoryList.length">
          <div 
            class="item" 
            :class="directoryActive == item.mailBannerId ? 'active' : ''"
            v-for="item in directoryList" 
            :key="item.mailBannerId"
            @click="jump(item)">
            <img :src="item.path" />
            <div class="checkbox" v-show="directoryActive == item.mailBannerId">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </template>
        <el-empty 
          v-else 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
        />
      </div>
    </div>
    <div class="containerRight">
      <template v-if="bannerItem">
        <div class="title">
          <div class="text">预览</div>
          <div class="operate">
            <el-input v-model="bannerItem.bannerText" placeholder="banner标题" size="small" />
            <el-color-picker v-model="bannerItem.textStyle.color" size="small" />
            <el-slider v-model="bannerItem.textStyle.fontSize" style="width: 150px"></el-slider>
          </div>
        </div>
        <div class="contentImage">
          <div class="imgDownBox" id="exportPng">
            <p 
              :class="bannerItem.isTitleEdit ? 'active' : ''" 
              :style="{
                ...bannerItem.textStyle,
                fontSize: bannerItem.textStyle.fontSize + 'px'
              }"
              @click.stop="goEditTitle"
              id="bannerText"
            >{{ bannerItem.bannerText }}</p>
            <img :src="bannerItem.path" alt="">
          </div>
        </div>
        <div class="footer">
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="cancel">
            {{ $t('home.Cancel') }}
          </el-button>
          <el-button size="small" @click="sendEmail">
            发送邮件
          </el-button>
          <el-button type="primary" size="small" @click="downImage">
            下载图片
          </el-button>
        </div>
      </template>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { getEmailBannerList, sendEmailBanner } from "@/api/common";
import { uploadFileUrl } from "@/api/files";
export default {
  data() {
    return {
      loading: false,
      isBannerInputText: false,
      isTitleEdit: false,
      directoryActive: null,
      directoryList: [],
      emailInput: "",
      bannerItem: null
    }
  },
  mounted() {
    this.getDirectoryList();
    document.addEventListener("click", () => {
      if(this.bannerItem) {
        this.bannerItem.isTitleEdit = false;
      }
    })
  },
  watch: {},
  methods: {
    getDirectoryList() {
      getEmailBannerList({
        page: 1,
        size: 10000
      }).then((data) => {
        this.directoryList = data.list.map(item => ({
          ...item,
          bannerText: "请输入Banner标题",
          isTitleEdit: false,
          textStyle: {
            top: null,
            left: null,
            color: '#ffffff',
            fontSize: 40
          }
        }));
        if(this.directoryList.length) {
          this.jump(this.directoryList[0])
        }
      })
    },
    goEditTitle() {
      this.bannerItem.isTitleEdit = true;
    },
    cancel() {
      this.directoryActive = null;
      this.bannerItem = null;
    },
    downImage() {
      html2canvas(document.querySelector("#exportPng"), {
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        const oImg = new Image();
        oImg.src = canvas.toDataURL(); // 导出图片
        canvas.toDataURL("image/png", 1);
        // 下载图片
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = oImg.src;
        // 设置下载标题
        a.download = "我的文件";
        a.click();
      });
    },
    emailInputChange(e) {
      this.emailInput = e.target.value;
    },
    base64ToFile(data) {
      let base = window.atob(data.substring(data.indexOf(',') + 1))
      let length = base.length
      let url = new Uint8Array(length)
      while (length--) {
        url[length] = base.charCodeAt(length)
      }
      let file = new File([url], `${new Date().getTime()}.jpg`, {
        type: 'image/jpg'
      })
      return file;
    },
    sendEmail() {
      html2canvas(document.querySelector("#exportPng"), {
        useCORS: true,
        allowTaint: true,
      }).then(async (canvas) => {
        let that = this
        const oImg = new Image();
        oImg.src = canvas.toDataURL(); // 导出图片
        canvas.toDataURL("image/png", 1);
        const data_up = new FormData();
        data_up.append("files", this.base64ToFile(oImg.src));
        data_up.append("configCode", "file_mail_banner");
        let imageUrl = "";
        await uploadFileUrl(data_up).then((data) => {
          if(!data) return;
          imageUrl = data[0].savePath;
        });
        this.$msgbox({
          title: '发送图片到邮件',
          message: (
            <div class="sendEmailBox">
              <input value={that.emailInput} onInput={that.emailInputChange} placeholder="邮箱" />
              <img src={imageUrl} />
            </div>
          ),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '发送中...';
              sendEmailBanner({
                mail: this.emailInput,
                url: imageUrl
              }).then(() => {
                this.$message.success("发送成功！")
              }).finally(() => {
                this.emailInput = "";
                instance.confirmButtonLoading = false;
                done();
              })
            } else {
              this.emailInput = "";
              done();
            }
          }
        }).then(() => {}).catch(() => {});
      });
    },
    jump(row) {
      this.directoryActive = row.mailBannerId;
      this.bannerItem = row;
      this.$nextTick(() => {
        let dragText = document.querySelector('#bannerText');
        let container = document.querySelector("#exportPng");
        dragText.onmousedown = (e) => {
          if(!this.bannerItem.isTitleEdit) return;
          //做到浏览器兼容
          e = e || window.event;
          let diffX = e.clientX - dragText.offsetLeft;
          let diffY = e.clientY - dragText.offsetTop;
          //当拉着box移动时
          document.onmousemove = (e) => {
            // 浏览器兼容
            e = e || window.event;
            let left = e.clientX - diffX;
            let top = e.clientY - diffY;
            if (left < 0) {
              left = 0;
            } else if (left > container.clientWidth - dragText.offsetWidth) {
              left = container.clientWidth - dragText.offsetWidth;
            }
            if (top < 0) {
              top = 0;
            } else if (top > container.clientHeight - dragText.offsetHeight) {
              top = container.clientHeight - dragText.offsetHeight;
            }
            // 实时给元素定位赋值
            this.bannerItem.textStyle.left = left + "px";
            this.bannerItem.textStyle.top = top + "px";
          };

          // 当鼠标抬起时
          document.onmouseup = function (e) {
            this.onmousemove = null;
            this.onmouseup = null;
          };
        };
      })
    }
  },
}
</script>

<style lang="less" scoped>
.directoryList {
  padding: 18px;
  overflow: overlay;
  height: 100%;
  .item {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ffffff;
    position: relative;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
      border-radius: 4px;
    }
    &.active {
      border: 2px solid #48AC42;
    }
    .checkbox {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 24px;
      height: 24px;
      background: #48AC42;
      border-radius: 4px 4px 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
.containerRight {
  background: #F7F9FC;
  position: relative;
  padding: 0;
  min-width: 0;
  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 1;
    .text {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #122545;
    }
    .operate {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .contentImage {
    width: 100%;
    height: 100%;
    padding-top: 58px;
    padding-bottom: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    .imgDownBox {
      width: 100%;
      height: calc(100vh - 500px);
      min-height: 400px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0px;
        z-index: 0;
      }
      p {
        position: absolute;
        z-index: 11;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
        user-select: none;
        border: 2px solid transparent;
        &.active {
          border: 2px solid #48AC42;
          cursor: all-scroll;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 86px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.sendEmailBox {
  input {
    border: 1px solid #dcdfe6;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    padding: 0 15px;
    color: #122545;
    font-weight: 600;
  }
  img {
    width: 100%;
    border-radius: 4px;
    margin-top: 12px;
  }
}
</style>