<template>
    <el-dialog 
        :visible.sync="eventCommentListShow" 
        width="40%" 
        :before-close="handleClose" 
        :show-close="false"
        :eventCommentList="eventCommentList" 
        :eventOrComment="eventOrComment" @open="open()"
        v-move-outside
    >
        <div class="dialog_body">
            <div class="close" @click="handleClose">
                <i class="el-icon-circle-close"></i>
            </div>
            <div class="flex_layout">
                <span class="assign_title">{{ eventOrComment }} List</span>
                <el-table ref="multipleTable" :data="options" tooltip-effect="dark" style="width: 100%" stripe
                    @selection-change="handleSelectionChange">
                    <el-table-column prop="updateUserName" label="Name">
                    </el-table-column>
                    <el-table-column label="Type">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.messageType == 'EVENT' ? 'primary' : 'success'" disable-transitions
                                effect="dark">{{ scope.row.messageType }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Order">
                        <template slot-scope="scope">
                            <el-tag type="info" effect="dark" disable-transitions style="cursor: pointer"
                                @click="linkToOrder('/order/orderDetails', scope.row.CI)">{{ scope.row.CI }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "eventCommentList",
    //home的子组件中使用inject后可以任意地方调用this.reload()来刷新home下的路由
    inject: ["reload"],
    data() {
        return {
            imageUrl: "",
            form: {
                company: "",
                companyId: "",
                showList: [],
            },
            email: {},
            options: [],
            multipleSelection: [],
            businessUserId: [],
        };
    },
    props: ["eventCommentListShow", "eventCommentList", "eventOrComment"],
    computed: {
        ...mapGetters(["timezone", "country", "companyId"]),
    },

    methods: {
        //由于mounted不执行，所以用el-dialog回调函数
        open() {
            this.options = this.eventCommentList;
        },
        //搜索判断名字是否在下面名单里面
        searchItem(arr, rowName) {
            var item = false;
            try {
                arr.forEach(function (curItem) {
                    if (curItem.name == rowName) {
                        item = true;
                        throw Error();
                    }
                });
            } catch (e) {}
            return item;
        },
        handleClose() {
            this.$emit("handleClose");
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        linkToOrder(path, item) {
            this.$router.push({
                path: path,
                query: {
                    ci: item,
                },
            });
            window.localStorage.setItem("currentPath", "/order");
            this.handleClose();
        },
    },
};
</script>

<style lang="less" scoped>
.dialog_body {
    position: relative;

    .close {
        position: absolute;
        top: -10px;
        right: 0;

        i {
            font-size: 22px;
            cursor: pointer;
        }
    }
}

.assign_title {
    font-size: 20px;
    font-weight: 700;
    padding: 2% 0 5% 0;
}

.flex_layout {
    display: flex;
    /**/
    flex-direction: column;
    justify-content: center;
    /*水平居中*/
    align-items: center;
    /*垂直居中*/
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
}

.aliasName {
    font-size: 12px;
    color: #b4b4b4;
}

.companyType {
    font-size: 12px;
    color: #b4b4b4;
    float: right;
}

.el-avatar {
    background: #eee;
}

.avatar_title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: rgb(131, 129, 129);
        line-height: 20px;
        font-weight: 700;
    }
}

.upload_company_logo {
    display: flex;
    /**/
    justify-content: center;
    /*水平居中*/
    align-items: center;

    /*垂直居中*/
    // background-color: #999;
    // margin: 0 10rem 0 10rem;
    //text-align: center;
    img {
        //width: 300px;
        height: 75px;
        display: block;
        border-radius: 5px;
    }
}

.invite_box {
    border: 1px solid #c0c4cc;
    border-radius: 12px;
    // height: 100px;
    position: relative;
    margin-top: 20px;
    padding: 10px 0;

    .invite_or {
        position: absolute;
        top: -12px;
        left: 50%-8px;
        font-size: 16px;
        font-weight: bold;
        background: #fff;
        color: #999;
    }

    h6 {
        text-align: center;
        font-weight: bold;
    }

    .links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5%;

        .company_submit {
            user-select: none;
            margin-left: 2%;
        }
    }
}

.email_box {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;

    .email_left {
        width: 100%;

        .form_item {
            display: flex;
            align-items: center;
            margin-top: 10px;

            i {
                color: #999;
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }

    .company_submit {
        margin-top: 10px;
    }
}
</style>
