<template>
  <div v-loading="loading">
    <div class="message_popover_box" v-infinite-scroll="load" :infinite-scroll-distance="20">
      <ul v-if="duplicateRemoval.length">
        <li
          v-for="(item, index) in duplicateRemoval"
          :key="index"
          class="message_list_item"
          :style="`opacity: ${!item.read ? '1' : '0.5'};`"
        >
          <member v-if="!item.keyEvent" :info="item.createPerson" :size="48" :time="time" />
          <i v-else class="iconfont avatar" :class="iconComputed(item)"></i>
          <div class="content" @click="jump(item)">
            <textDisplay
              :isDropActive="!item.read"
              :titleName="!item.keyEvent ? item.createPerson.nickName : ''"
              :titleConnect="item.keyEvent ? systemName(item) : `${$t('InformationCenter.posted')}${item.subject}${$t('InformationCenter.in')}`"
              :orderCI="!item.keyEvent ? (item.ci || item.pi) : ''"
              :content="item.content"
              :createTime="item.updateTime"
            />
          </div>
        </li>
      </ul>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
  </div>
</template>
<script>
import textDisplay from "@/components/textDisplay/textDisplay.vue";
import dayjs from "dayjs";
import { getEventList, eventRead } from "@/api/event";
import { mapGetters } from "vuex";
import { uniq } from "lodash";

export default {
  name: "event",
  inject: ["reload"],
  data() {
    return {
      data: {
        currentPage: 1,
        pageSize: 20,
        excludeRead: true
      },
      eventList: [],
      loading: false,
      commentTotalPage: 0
    };
  },
  props: ["time"],
  components: {
    textDisplay,
  },
  computed: {
    ...mapGetters(["eventUpdate", "eventAllRead"]),
    duplicateRemoval() {
      return uniq(this.eventList);
    },
    iconComputed() {
      return (row) => {
        let content = row.contentJson;
        if(content) {
          if(content.type == 1) {
            return "icon-dingdan1";
          } else if(content.type == 2) {
            return "icon-yundan";
          } else if(content.type == 3) {
            return "icon-daka";
          } else {
            return "icon-shuaxin";
          }
        }
      }
    },
    behaviorComputed() {
      return (item) => {
        let content = item.contentJson;
        if(content) {
          if(content.type == 1) {
            if(content.status == 1) {
              return this.$t("order.Created");
            } else {
              return this.$t("order.Changed");
            }
          } else if(content.type == 2) {
            if(content.status == 1) {
              return this.$t("order.Created");
            } else {
              return this.$t("order.Changed");
            }
          } else if(content.type == 3) {
            return this.$t("order.Clocked");
          } else  {
            return this.$t("order.Changed");
          }
        }
      }
    },
    titleTypeComputed() {
      return (item) => {
        let content = item.contentJson;
        if(content) {
          if(content.type == 1) {
            return this.$tc("order.OrderInfo", this.$isOrderTitle());
          } else if(content.type == 2) {
            return this.$t("order.shipInfo");
          } else if(content.type == 3) {
            return this.$tc("order.orderHistory", this.$isOrderTitle());
          } else {
            return this.$tc("order.Order node", this.$isOrderTitle());
          }
        }
      }
      
    },
    systemName() {
      return (row) => {
        let content = row.contentJson;
        if(content) {
          return `${content.type != 4 ? row.createPerson.nickName : this.$t('memberList.manage')} ${this.behaviorComputed(row)} ${this.titleTypeComputed(row)}`
        }
      }
    },
  },
  watch: {
    eventUpdate() {
      this.onGetList(true);
    },
    eventAllRead() {
      this.onGetList(true);
    }
  },
  mounted() {
    this.onGetList(true);
  },
  methods: {
    load() {
      if(this.data.currentPage < this.commentTotalPage) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    onGetList(clear = false) {
      this.loading = true;
      getEventList(this.data)
        .then((data) => {
          if (clear) this.eventList = [];
          this.eventList = this.eventList.concat(data.list.map((res) => ({
            ...res,
            updateTime: dayjs(res.createTime).format("MM/DD HH:mm"),
            content: res.keyEvent ? this.$t('order.systemEvent') : res.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, "")
          })));
          this.commentTotalPage = data.pages;
        })
        .finally(() => (this.loading = false));
    },
    jump(data) {
      eventRead(data.eventId).then(() => {
        data.read = true;
        this.$emit("updateCount");
        this.$store.dispatch("user/setReadStatus", "READEVENT");
        this.reload(["OrderDetails"]);
        this.$router.push({
          path: "/order/orderDetails",
          query: {
            orderId: data.orderId,
            eventId: data.eventId
          },
        });
      })
    },
  },
};
</script>
<style lang="less" scoped>
</style>
