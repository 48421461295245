<template>
  <div>
    <el-drawer
      :title="
        mode == 1 ? $t('Purchase.Create Purchase') : mode == 2 ? $t('Purchase.Edit Purchase') : $t('Purchase.Preview Purchase')
      "
      :visible.sync="show"
      size="515px"
      :before-close="() => CloseDrawer()"
      v-move-outside
    >
      <el-form 
        :model="form" 
        :rules="rules" 
        ref="createForm" 
        label-width="0"
        size="small"
        class="drawerForm"
        :style="{
          height: `calc(100% - ${form.purchaseStatus != 3 ? 61 : 0}px)`
        }">
        <el-form-item :prop="mode != 3 ? 'purchaseNo' : null">
          <div class="title">
            <i class="iconfont icon-Vector5"></i>
            {{ $t('Purchase.Purchase Number') }}
          </div>
          <el-input
            v-if="mode != 3"
            v-model="form.purchaseNo"
            :maxlength="64"
            :disabled="form.purchaseStatus != 0"
            clearable
            :placeholder="$t('Purchase.Purchase Number')"
          />
          <div v-else class="inputText" v-showTootip>{{ form.purchaseNo }}</div>
        </el-form-item>
        <el-form-item>
          <div class="blockFormItem">
            <div class="header">
              <div class="left">
                <div class="iconBox">
                  <i class="iconfont icon-mingxi"></i>
                </div>
                <div class="text">{{ $t('Purchase.Details (optional)') }}</div>
              </div>
              <i class="iconfont icon-a-Polygon3" :class="detailShow ? 'active' : ''" @click="detailShow = !detailShow"></i>
            </div>
            <template v-if="detailShow">
              <div 
                class="listItem"
                v-for="(item, index) in form.detailJson" 
                :key="index">
                <div class="formFlex">
                  <el-form-item :prop="'detailJson.'+index+'.productCode'" :rules="rules.productCode" label-width="68px" :label="$tc('Purchase.ProductIndex', index+1)">
                    <el-input v-if="mode != 3" style="width: 130px;" :maxlength="64" v-model="item.productCode" :placeholder="$t('Purchase.ProductCode')" />
                    <div v-else class="inputText" v-showTootip>{{ item.productCode || '--' }}</div>
                  </el-form-item>
                  <el-form-item :prop="'detailJson.'+index+'.productName'" :rules="rules.productName">
                    <el-input v-if="mode != 3" :maxlength="64" style="width: 232px;" v-model="item.productName" :placeholder="$t('Purchase.ProductName')" />
                    <div v-else class="inputText" v-showTootip>{{ item.productName || '--' }}</div>
                  </el-form-item>
                </div>
                <div class="formFlex">
                  <el-form-item label-width="68px" :label="$t('order.Quantity')">
                    <el-input-number v-if="mode != 3" :disabled="!item.productCode || !item.productName" v-model="item.productQuantity" :min="0" />
                    <div v-else class="inputText" v-showTootip>{{ item.productQuantity }}</div>
                  </el-form-item>
                  <el-form-item label-width="68px" :label="$t('unitPrice')">
                    <template v-if="mode != 3">
                      <el-input 
                        v-model="item.productPrice" 
                        :maxlength="64"
                        :disabled="!item.productCode || !item.productName"
                        style="width: 80px" 
                        oninput="
                          this.value=this.value.replace(/\D*(\d{7})(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                          .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                          .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                          .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                        "
                        @blur="() => item.productPrice = item.productPrice == '' ? 0 : item.productPrice"
                      />
                      <el-select
                        v-model="item.productUnit"
                        :disabled="(!item.productCode || !item.productName) || index != 0"
                        appendToBody
                        style="width: 80px;margin-left: 4px;" 
                        :placeholder="`${$t(
                          'xiaoxie.currency'
                        )}`"
                        @change="setProductUnitchange(item, index)"
                      >
                        <el-option
                          v-for="(item, index) in currencys"
                          :key="index"
                          :label="item.key"
                          :value="item.value"
                        />
                      </el-select>
                    </template>
                    <div v-else class="inputText" v-showTootip>{{ item.productPrice }} {{ $isFindData(currencys, item.productUnit, 'value', 'key') }}</div>
                  </el-form-item>
                </div>
                <div class="line" v-if="form.detailJson.length != 1 && index != form.detailJson.length - 1"></div>
              </div>
              <div class="btns" v-if="mode != 3">
                <div class="addProductBtn" @click="form.detailJson.push({
                  productCode: '',
                  productName: '',
                  productQuantity: 0,
                  productPrice: '0',
                  productUnit: form.detailJson[0].productUnit || 'USD'
                })">
                  <i class="el-icon-plus"></i>
                  {{ $t('Purchase.Continue adding products') }}
                </div>
                <div 
                  class="deleteBtn" 
                  v-if="form.detailJson.length != 1"
                  @click="form.detailJson.splice(form.detailJson.length - 1, 1)">
                  <i class="el-icon-delete"></i>
                  {{ $tc('Purchase.Delete product', $tc('Purchase.ProductIndex', form.detailJson.length)) }}
                </div>
              </div>
              <el-form-item label-width="68px" :label="$t('order.cny')">
                <template v-if="mode != 3">
                  <el-input 
                    style="width: 260px;" 
                    v-model="form.totalPrice" 
                    :maxlength="64"
                    :placeholder="$t('order.cny')" 
                    oninput="
                      this.value=this.value.replace(/\D*(\d{7})(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                      .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                      .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                      .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                    "
                    @blur="() => form.totalPrice = form.totalPrice == '' ? 0 : form.totalPrice"
                  />
                  <el-select
                    v-model="form.totalPriceUnit"
                    appendToBody
                    disabled
                    style="width: 100px;margin-left: 4px;" 
                    :placeholder="`${$t(
                      'xiaoxie.currency'
                    )}`"
                  >
                    <el-option
                      v-for="(item, index) in currencys"
                      :key="index"
                      :label="item.key"
                      :value="item.value"
                    />
                  </el-select>
                </template>
                <div v-else class="inputText" v-showTootip>{{ form.totalPrice }} {{ $isFindData(currencys, form.totalPriceUnit, 'value', 'key') }}</div>
              </el-form-item>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="blockFormItem">
            <div class="header">
              <div class="left">
                <div class="iconBox">
                  <i class="iconfont icon-jiaohuo"></i>
                </div>
                <div class="text">{{ $t('Purchase.Delivery (optional)') }}</div>
              </div>
              <i class="iconfont icon-a-Polygon3" @click="deliveryShow = !deliveryShow"></i>
            </div>
            <template v-if="deliveryShow">
              <el-form-item label-width="68px" :label="$t('Purchase.Delivery address')">
                <el-input 
                  v-model="form.deliveryAddress"
                  :maxlength="64" 
                  v-if="mode != 3"
                  :placeholder="$t('Purchase.Delivery address')" 
                />
                <div v-else class="inputText" v-showTootip>{{ form.deliveryAddress || '--' }}</div>
              </el-form-item>
              <el-form-item label-width="68px" :label="$t('Purchase.Delivery date')">
                <template v-if="mode != 3">
                  <el-date-picker
                    v-model="form.deliveryDate"
                    type="datetime"
                    :placeholder="$t('el.datepicker.selectDate')"
                    style="width: 185px;flex: none"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                  <el-select
                    v-model="form.deliveryTimezone"
                    clearable
                    :placeholder="$t('customerInfo.Choose the timezone')"
                    autocomplete="off"
                    appendToBody
                    style="width: 185px;margin-left: 8px;"
                  >
                    <el-option
                      v-for="item in timezone"
                      :key="item.value"
                      :label="item.key"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
                <div v-else class="inputText" v-showTootip>{{ form.deliveryDate || '--' }} {{ form.deliveryTimezone ? $isFindData(timezone, form.deliveryTimezone, 'value', 'key').split(" ")[0] : '--' }}</div>
              </el-form-item>
              <el-form-item label-width="68px" :label="$t('Purchase.Mode of transport')">
                <div class="btnGroupBox" v-if="mode != 3">
                  <div 
                    class="item" 
                    :class="item.value == form.deliveryMethod ? 'active' :''"
                    v-for="(item, index) in transportMode" 
                    :key="index"
                    @click="transportEvent(item)">
                    <i class="iconfont" :class="`icon-purchase-${item.value}`"></i>
                    {{ item.key }}
                    <img v-if="item.value == form.deliveryMethod" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/yibancheck.png" />
                  </div>
                </div>
                <div v-else class="inputText" v-showTootip>
                  <i :class="`iconfont icon-purchase-${form.deliveryMethod}`"></i>
                  {{ $isFindData(transportMode, form.deliveryMethod, 'value', 'key') || '--' }}
                </div>
              </el-form-item>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="blockFormItem">
            <div class="header">
              <div class="left">
                <div class="iconBox">
                  <i class="iconfont icon-fukuan1"></i>
                </div>
                <div class="text">{{ $t('Purchase.Payment (optional)') }}</div>
              </div>
              <i class="iconfont icon-a-Polygon3" @click="paymentShow = !paymentShow"></i>
            </div>
            <template v-if="paymentShow">
              <el-form-item label-width="68px" :label="$t('Purchase.Delivery terms')">
                <el-select
                  v-model="form.transactionTerms"
                  clearable
                  v-if="mode != 3"
                  :placeholder="$t('Purchase.Delivery terms')"
                  autocomplete="off"
                  appendToBody
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in payMentData"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div v-else class="inputText" v-showTootip>
                  {{ $isFindData(payMentData, form.transactionTerms, 'value', 'key') || '--' }}
                </div>
              </el-form-item>
              <el-form-item label-width="68px" :label="$t('Purchase.Payment method')">
                <template v-if="mode != 3">
                  <el-select
                    v-model="form.paymentMethod"
                    clearable
                    :placeholder="$t('Purchase.Payment method')"
                    autocomplete="off"
                    appendToBody
                    style="width: 185px;"
                  >
                    <el-option
                      v-for="item in payMentMethods"
                      :key="item.value"
                      :label="item.key"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-input 
                    v-model="form.paymentDeadline" 
                    style="width: 185px;margin-left: 8px;"
                    placeholder="e.g. 90 days" 
                    :maxlength="64"
                  />
                </template>
                <div v-else class="inputText" v-showTootip>
                  {{ $isFindData(payMentMethods, form.paymentMethod, 'value', 'key') || '--' }}
                  {{ form.paymentDeadline || '--' }}
                </div>
              </el-form-item>
              <el-form-item label-width="68px" :label="$t('Purchase.Payment date')">
                <template v-if="mode != 3">
                  <el-date-picker
                    v-model="form.paymentDate"
                    type="datetime"
                    :placeholder="$t('el.datepicker.selectDate')"
                    style="width: 185px;flex: none"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                  <el-select
                    v-model="form.paymentTimezone"
                    clearable
                    :placeholder="$t('customerInfo.Choose the timezone')"
                    autocomplete="off"
                    appendToBody
                    style="width: 185px;margin-left: 8px;"
                  >
                    <el-option
                      v-for="item in timezone"
                      :key="item.value"
                      :label="item.key"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
                <div v-else class="inputText" v-showTootip>
                  {{ form.paymentDate || '--' }}
                  {{ $isFindData(timezone, form.paymentTimezone, 'value', 'key') || '--' }}
                </div>
              </el-form-item>
            </template>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="title">
            <i class="iconfont icon-shangchuanfujian"></i>
            {{ $t('Upload attachments') }}
          </div>
          <el-upload
            v-loading="uploadLoding"
            style="flex: 1"
            v-if="mode != 3"
            :auto-upload="false"
            action="#"
            drag
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-change="handleChange"
            :class="!form.attachmentUrl ? 'ec-upload' : 'ec-uploader'"
            :accept="uploadFileTypeItem && uploadFileTypeItem.supportFileTypes"
          >
            <template v-if="!form.attachmentUrl">
              <i class="iconfont icon-shangchuan"></i>
              <div class="el-upload__text">
                {{ $t("event.Drag & Drop files here OR Browse Files") }}
              </div>
            </template>
            <template v-else>
              <div class="ec-upload__left">
                <i
                  class="iconfont_file"
                  :class="isFileTypeIcon(fileName.split('.')[1])"
                ></i>
                <div class="name">
                  <p>{{ fileName }}</p>
                </div>
              </div>
              <i class="iconfont icon-shanchu" @click.stop="handleRemove"></i>
            </template>
          </el-upload>
          <fileBlocks
            v-else-if="form.attachmentId"
            type="message"
            background="#F7F9FC"
            :embedUrl="form.attachmentUrl"
            :embedId="form.attachmentId"
          />
          <p v-else class="inputText">--</p>
        </el-form-item>
        <el-form-item class="noCenter">
          <div class="title">
            <i class="iconfont icon-beizhu"></i>
            {{ $t('todo.Notes') }}
          </div>
          <el-input
            type="textarea"
            v-if="mode != 3"
            style="flex: 1"
            v-model="form.remark"
            :maxlength="500"
            clearable
            :placeholder="$t('todo.Notes')"
          />
          <div v-else class="inputText" v-showTootip>{{ form.remark || '--' }}</div>
        </el-form-item>
      </el-form>
      <div class="drawerFooter" v-if="form.purchaseStatus != 3">
        <div class="left">
          <el-button
            class="cancel_button" 
            size="small" 
            v-if="mode != 3 && form.purchaseStatus == 0"
            @click="saveOnly"
          >{{ $t('Save Only') }}</el-button>
          <el-button
            class="cancel_button" 
            size="small" 
            v-if="mode == 3 && purchaseId"
            icon="el-icon-download"
            @click="exportFiles"
          >{{ $t('order.export') }}</el-button>
        </div>
        <div class="right" v-if="!purchaseId || (companyCurrentType == 1 ? businessUserId == form.sellerUserId : form.buyerUserId == businessUserId)">
          <el-button
            class="cancel_button" 
            size="small" 
            @click="CloseDrawer()"
          >{{ $t('home.Cancel') }}</el-button>
          <el-button
            size="small" 
            v-if="mode == 3"
            @click="mode = purchaseId ? 2 : 1"
          >{{ $t('home.Edit') }}</el-button>
          <el-button 
            type="primary" 
            size="small"
            v-if="!isOneVendor"
            @click="previewEvent"
          >{{ mode != 3 ? $t('Purchase.Preview and send') : form.purchaseStatus == '0' ? $t('InformationCenter.Send') : $t('Purchase.Update and send') }}</el-button>
          <el-button 
            type="primary" 
            size="small"
            @click="createOrder"
            v-else
          >{{ $t('Generate Order') }}</el-button>
        </div>
      </div>
    </el-drawer>
    <inside
      :isInside="isInside"
      @onClose="isInside = false"
      type="purchase"
      :purchaseRow="form"
      @CloseDrawer="CloseDrawer(true)"
    />
    <createOrder
      ref="createOrderDialog"
      :editContent="null"
      :customerInfo="null"
      action="purchase"
      @afterCreateOrder="CloseDrawer()"
    />
    <exportFiles ref="exportFiles" :row="form" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { uploadFileUrl } from "@/api/files";
import { isFileTypeIcon } from "@/utils/utils.js";
import { createPurchaseApi, editPurchaseApi, getPurchaseDetailApi } from "@/api/order";
import { sendChatData } from '@/api/news';

import inside from '@/components/fileInside/inside.vue';
import createOrder from "@/components/createOrder/createOrder";
import exportFiles from "@/components/purchaseManage/exportFiles";

export default {
  components: {
    inside,
    createOrder,
    exportFiles
  },
  data() {
    return {
      isInside: false,
      isFileTypeIcon: isFileTypeIcon,
      mode: 1,
      purchaseId: "",
      otherParty: null,
      show: false,
      detailShow: false,
      deliveryShow: false,
      paymentShow: false,
      uploadLoding: false,
      isOneVendor: false,
      uploadFileTypeItem: null,
      file: null,
      form: {
        purchaseNo: "",
        purchaseStatus: 0,
        detailJson: [{
          productCode: "",
          productName: "",
          productQuantity: 0,
          productPrice: "0",
          productUnit: "USD"
        }],
        totalPrice: 0,
        totalPriceUnit: "USD",
        deliveryAddress: "",
        deliveryDate: "",
        deliveryTimezone: "",
        deliveryMethod: "",
        transactionTerms: "",
        paymentMethod: "",
        paymentDeadline: "",
        paymentDate: "",
        paymentTimezone: "",
        attachmentId: "",
        attachmentUrl: "",
        remark: "",
        sellerUserId: "",
        sellerCompanyId: ""
      },
      rules: {
        purchaseNo: [{
          required: true,
          message: this.$tc('Please input', this.$t('Purchase.Purchase Number')),
        }],
        productCode: [{
          validator: (rule, value, callback) => {
            let deleteItem = this.form.detailJson[rule.field.split('.')[1]]
            if(Number(deleteItem.productQuantity) > 0 || Number(deleteItem.productPrice) > 0) {
              if(!value) {
                callback(new Error(this.$tc('Please input', this.$t('Purchase.ProductCode')),));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        productName: [{
          validator: (rule, value, callback) => {
            let deleteItem = this.form.detailJson[rule.field.split('.')[1]]
            if(Number(deleteItem.productQuantity) > 0 || Number(deleteItem.productPrice) > 0) {
              if(!value) {
                callback(new Error(this.$tc('Please input', this.$t('Purchase.ProductName')),));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      }
    }
  },
  mounted() {
    this.$myEvent.on('openPurchase', this.open);
  },
  computed: {
    ...mapGetters(["businessUserId", "currencys", "timezone", "transportMode", "payMentData", "payMentMethods", "fileEventType", "companyCurrentType", "fileType"]),
    fileName() {
      let text = this.form.attachmentUrl?.split("/")?.pop();
      let index_ = text.lastIndexOf("_");
      let indexdian = text.lastIndexOf(".");
      return text.substring(0, index_) + text.substring(indexdian, text.length);
    },
    isEdited() {
      console.log(this.purchaseId);
      if(this.purchaseId) return false;
      return this.form.purchaseNo || this.form.detailJson.length > 1 || this.form.deliveryAddress || this.form.attachmentUrl;
    }
  },
  watch: {
    'form.detailJson': {
      handler(val) {
        let num = 0
        val.forEach(item => {
          num += Number(item.productPrice) * Number(item.productQuantity);
        });
        this.form.totalPrice = num;
      },
      deep: true,
      immediate: true
    },
    mode(val) {
      if(val == 2) {
        this.isOneVendor = false;
      }
    }
  },
  methods: {
    open({mode = 1, otherParty = null, row = null}) {
      this.uploadFileTypeItem = this.fileEventType.find(item => item.configCode == 'file_PO') || this.fileType.find(item => item.configCode == 'file_PO');
      this.show = true;
      this.mode = mode;
      if(otherParty) {
        this.otherParty = otherParty;
        this.form.sellerUserId = otherParty.openId;
        this.form.sellerCompanyId = otherParty.company?.companyId;
      }
      if(this.companyCurrentType == 1) {
        this.isOneVendor = true;
      }
      if(row) {
        this.purchaseId = row?.purchaseId;
        getPurchaseDetailApi(row?.purchaseId).then((data) => {
          this.form = data || {};
        });
      }
      if(this.mode == 3 || this.mode == 2) {
        this.detailShow = true;
        this.deliveryShow = true;
        this.paymentShow = true;
      }
      setTimeout(() => {
        this.$refs.createForm.clearValidate();
      }, 100)
    },
    setProductUnitchange(item, index) {
      this.form.totalPriceUnit = item.productUnit;
      this.form.detailJson.forEach(row => {
        row.productUnit = item.productUnit;
      });
    },
    exportFiles() {
      this.$refs.exportFiles.open();
    },
    createOrder() {
      this.$refs.createOrderDialog.open(this.form);
    },
    CloseDrawer(cross = false) {
      if(cross) {
        this.show = false;
        this.mode = 1;
        this.detailShow = false;
        this.deliveryShow = false;
        this.paymentShow = false;
        this.purchaseId = "";
        this.otherParty = null;
        this.clearForm();
        return;
      }
      if(!this.isEdited) {
        this.show = false;
        this.mode = 1;
        this.detailShow = false;
        this.deliveryShow = false;
        this.paymentShow = false;
        this.purchaseId = "";
        this.otherParty = null;
        this.clearForm();
        return;
      }
      this.$confirm(this.$t("If the edited content is not saved, it will be cleared"), this.$t("Have you edited it"), {
        confirmButtonText: this.$t('save'),
        cancelButtonText: this.$t('Do not save'),
        type: 'warning'
      }).then(() => {
        this.$refs.createForm.validate((valid) => {
          if(!valid) return;
          this.form.purchaseStatus = 0;
          this.form.sellerUserId = "";
          this.form.sellerCompanyId = "";
          createPurchaseApi(this.form).then(() => {
            this.show = false;
            this.mode = 1;
            this.detailShow = false;
            this.deliveryShow = false;
            this.paymentShow = false;
            this.otherParty = null;
            this.purchaseId = "";
            this.clearForm();
            this.$myEvent.emit("onPurchaseList", true);
            this.$message.success(this.$t("Successfully saved"));
          }).catch(() => {
            this.clearForm();
          })
        })
      }).catch(() => {
        this.show = false;
        this.mode = 1;
        this.detailShow = false;
        this.deliveryShow = false;
        this.paymentShow = false;
        this.otherParty = null;
        this.purchaseId = "";
        this.clearForm();
      });
    },
    transportEvent(row) {
      if(this.form.deliveryMethod) {
        if(row.value != this.form.deliveryMethod) {
          this.form.deliveryMethod = row.value;
        } else {
          this.form.deliveryMethod = "";
        }
      } else {
        this.form.deliveryMethod = row.value;
      }
    },
    beforeUpload(file) {
      let type = '.'+file.name.split(".").pop();
      if(!this.uploadFileTypeItem.supportFileTypes.split(",").includes(type.toLowerCase())) {
        this.$message.error(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < Number(this.uploadFileTypeItem.fileMaxSize || this.uploadFileTypeItem.maxUploadSize);
      if (!isLt10M) {
        this.$message.error(this.$tc("order.File is too large to upload", Number(this.uploadFileTypeItem.fileMaxSize || this.uploadFileTypeItem.maxUploadSize)));
      }
      return isLt10M;
    },
    handleChange(file) {
      //文件数量改变
      if (!this.beforeUpload(file)) return;
      this.file = file.raw;
      this.confirmUpload();
    },
    handleRemove() {
      this.file = null;
      this.form.attachmentUrl = "";
    },
    confirmUpload() {
      this.uploadLoding = true;
      var data_up = new FormData();
      data_up.append("files", this.file);
      data_up.append("configCode", "file_PO");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        this.form.attachmentUrl = response[0].savePath;
        this.form.attachmentId = response[0].fileId;
        this.$message({
          message: this.$t("event.Upload succeeded"),
          duration: 1000,
        });
      }).finally((error) => {
        this.uploadLoding = false;
      });
    },
    clearForm() {
      this.file = null;
      this.form = {
        purchaseNo: "",
        purchaseStatus: 0,
        detailJson: [{
          productCode: "",
          productName: "",
          productQuantity: 0,
          productPrice: "0",
          productUnit: "USD"
        }],
        totalPrice: 0,
        totalPriceUnit: "USD",
        deliveryAddress: "",
        deliveryDate: "",
        deliveryTimezone: "",
        deliveryMethod: "",
        transactionTerms: "",
        paymentMethod: "",
        paymentDeadline: "",
        paymentDate: "",
        paymentTimezone: "",
        attachmentId: "",
        attachmentUrl: "",
        remark: "",
        sellerUserId: "",
        sellerCompanyId: ""
      }
    },
    saveOnly() {
      this.$refs.createForm.validate((valid) => {
        if(!valid) return;
        this.form.sellerUserId = "";
        this.form.sellerCompanyId = "";
        if(this.purchaseId) {
          editPurchaseApi(this.form).then(() => {
            this.CloseDrawer();
            this.$myEvent.emit("onPurchaseList", true);
            this.$message.success(this.$t("Successfully saved"));
          }).catch(() => {
            this.clearForm();
          })
          return;
        }
        this.form.purchaseStatus = 0;
        createPurchaseApi(this.form).then(() => {
          this.CloseDrawer(true);
          this.$myEvent.emit("onPurchaseList", true);
          this.$message.success(this.$t("Successfully saved"));
        }).catch(() => {
          this.clearForm();
        })
      })
    },
    previewEvent() {
      this.$refs.createForm.validate((valid) => {
        if(!valid) return;
        if(this.mode == 3) {
          if(!this.purchaseId) {
            this.form.purchaseStatus = 1;
            if(this.otherParty) {
              createPurchaseApi(this.form).then((data) => {
                this.$myEvent.emit("onPurchaseList", true);
                sendChatData({
                  toUserId: this.otherParty.openId,
                  content: data.purchaseId,
                  messageType: 10,
                  chatType: 0
                }).then((data) => {
                  this.$message.success(this.$t("home.Sent successfully"));
                });
                this.CloseDrawer(true);
              }).catch(() => {
                this.mode = 1;
                this.detailShow = false;
                this.deliveryShow = false;
                this.paymentShow = false;
                this.clearForm();
              })
            } else {
              this.isInside = true;
            }
          } else {
            if(this.form.purchaseStatus == 0) {
              this.isInside = true;
            } else {
              editPurchaseApi(this.form).then(() => {
                this.$myEvent.emit("onPurchaseList", true);
                sendChatData({
                  toUserId: this.otherParty ? this.otherParty.openId : this.companyCurrentType == 0 ? this.form.sellerUserId : this.form.buyerUserId,
                  content: this.purchaseId,
                  messageType: 10,
                  chatType: 0
                }).then((data) => {
                  this.$message.success(this.$t("home.Sent successfully"));
                });
                this.CloseDrawer();
              }).catch(() => {
                this.mode = 2;
                this.detailShow = false;
                this.deliveryShow = false;
                this.paymentShow = false;
                this.clearForm();
              })
            }
          }
        } else {
          this.mode = 3;
          this.detailShow = true;
          this.deliveryShow = true;
          this.paymentShow = true;
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.drawerForm {
  .is-required {
    .title {
      &::after {
        content: "*";
        font-size: 16px;
        font-weight: 500;
        color: #DC3545;
        position: relative;
        top: 2px;
        margin-left: 2px;
      }
    }
  }
  .inputText {
    font-size: 14px;
    font-weight: 600;
    color: #122545;
    line-height: 22px;
    flex: 1;
    min-width: 0;
    i {
      color: #637381;
    }
  }
  /deep/ .el-form-item {
    align-items: center;
    display: flex;
    &.noCenter {
      .el-form-item__content {
        align-items: flex-start;
        .title {
          margin-top: 2px;
        }
      }
    }
    .el-form-item__content {
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      margin-left: 0 !important;
      .title {
        width: 102px;
        margin: 0;
        i {
          font-size: 12px;
        }
        &::before {
          display: none;
        }
      }
      .el-form-item__error {
        left: 102px;
      }
      .el-input {
        flex: 1;
      }
      .el-input-number {
        .el-input__inner {
          text-align: center;
        }
      }
    }
  }
  .btnGroupBox {
    display: flex;
    gap: 8px;
    .item {
      width: 88px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      gap: 2px;
      font-size: 13px;
      color: #637381;
      border: 1px solid #E0E4EA;
      cursor: pointer;
      position: relative;
      i {
        font-size: 18px;
        line-height: 1;
      }
      img {
        position: absolute;
        width: 24px;
        right: 0;
        bottom: 0;
      }
      &.active {
        border: 1px solid #004C97;
        color: #004C97;
      }
    }
  }
  .blockFormItem {
    padding: 16px 16px 0 16px;
    background: #F7F9FC;
    border-radius: 4px;
    width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      margin-bottom: 20px;
      .left {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #637381;
        line-height: 28px;
        .iconBox {
          background: #004C97;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 12px;
            color: #ffffff;
            transform: scale(.9);
          }
        }
      }
      .icon-a-Polygon3 {
        font-size: 12px;
        transform: scale(.5) rotate(180deg);
        color: #64748B;
        cursor: pointer;
        &.active {
          transform: scale(.5) rotate(0deg);
        }
      }
    }
    .listItem {
      .formFlex {
        display: flex;
        gap: 8px;
        /deep/ .el-form-item__error {
          left: 0;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background: #E3E9F1;
        margin-bottom: 18px;
      }
    }
    .btns {
      margin-bottom: 18px;
      display: flex;
      gap: 8px;
      .addProductBtn {
        flex: 1;
        height: 32px;
        border: 1px solid #004C97;
        color: #004C97;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        cursor: pointer;
      }
      .deleteBtn {
        width: 120px;
        height: 32px;
        border: 1px solid #E0E4EA;
        border-radius: 4px;
        font-size: 12px;
        color: #757D8A;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          border: 1px solid #004C97;
          color: #004C97;
        }
      }
    }
  }
}

.ec-upload {
  /deep/ .el-upload-dragger {
    border: 1px dashed #004C97;
    .icon-shangchuan {
      color: #004C97;
    }
    .el-upload__text {
      color: #004C97;
    }
  }
}

.drawerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>