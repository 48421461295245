<template>
  <div>
    <el-dialog
      append-to-body
      :visible.sync="approveListShow"
      width="358px"
      :before-close="handleClose"
      @open="open()"
      :title="
        func == 'Invite'
          ? $t('home.Invite')
          : $t('home.Assign colleagues to check')
      "
      custom-class="customerAssignPopover"
      v-move-outside
    >
      <div class="inviteBox" v-loading="approveListloading">
        <div class="item" v-for="(item, idx) in options" :key="idx">
          <member :info="item" :size="48" />
          <div class="content">
            <div class="name">
              {{ item.nickName }}
              <div class="tag" v-if="!item.type">{{ $t('memberList.manage') }}</div>
            </div>
            <div class="job">{{ item.position }}</div>
          </div>
          <el-checkbox :disabled="!item.type" v-model="item.check" />
        </div>
      </div>
      <div class="footerFlex" slot="footer">
        <div class="selectTotal" @click="allSelect">
          {{ options.filter(item => item.check).length ? $tc('home.selectedPerson', options.filter(item => item.check).length) : $t('home.allSelect') }}
        </div>
        <div>
          <el-button 
            class="cancel_button" 
            size="small" 
            @click="$emit('update:approveListShow', false)">
            {{ $t('home.Cancel') }}
          </el-button>
          <el-button
            type="primary"
            size="small"
            v-if="func == 'Assign'"
            @click="confirmApprove"
            :loading="loadingApprove"
          >
            {{ $t("home.Approveconfirm") }}
          </el-button>
          <el-button
            type="primary"
            size="small"
            v-else-if="func == 'Invite'"
            @click="confirmInvite"
            :loading="loadingApprove"
          >
            {{ $t("home.InviteConfirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 公司审核关系二次确认 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogVisible"
      width="390px"
      top="35vh"
      :before-close="() => (dialogVisible = false)"
      v-move-outside
    >
      <span class="dialog_text">
        {{ $t("home.Areinvitation") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("home.Cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="confirmApprove()"
          :loading="loadingApprove"
        >
          {{ $t("home.ConfirmAgree") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyEmployeeList,
  approveCustomer,
  getCompanyMemberData,
} from "@/api/customer";
import { mapGetters } from "vuex";
import { updateCustomerUser } from "../../api/customer";
export default {
  name: "assignApproveList",
  //home的子组件中使用inject后可以任意地方调用this.reload()来刷新home下的路由
  inject: ["reload"],
  data() {
    return {
      imageUrl: "",
      form: {
        company: "",
        companyId: "",
        showList: [],
      },
      email: {},
      options: [],
      loadingApprove: false,
      approveListloading: false,
      dialogVisible: false,
      dialogVisible1: false,
    };
  },
  props: ["approveListShow", "customerCompanyId", "func", "allConnection"],
  computed: {
    ...mapGetters(["timezone", "country", "companyId"]),
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open() {
      //初始化公司人员头像等信息
      this.approveListloading = true;
      getCompanyMemberData(this.customerCompanyId)
        .then((response) => {
          if (!response) return;
          this.options = response.map(item => ({
            ...item, 
            check: this.func == 'Invite' ? item.flag : !item.type
          }));
          this.$nextTick(() => {
            this.options.forEach((item) => {
              // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
              if (this.searchItem(this.allConnection, item.nickName)) {
                item.check = true;
              }
            });
          });
        })
        .finally(() => (this.approveListloading = false));
    },
    allSelect() {
      if(!this.options.filter(item => item.check).length) {
        this.options.forEach(item => {
          item.check = true;
        });
      }
    },
    //搜索判断名字是否在下面名单里面
    searchItem(arr, rowName) {
      var item = false;
      try {
        arr.forEach(function (curItem) {
          if (curItem.nickName == rowName) {
            item = true;
            throw Error();
          }
        });
      } catch (e) {}
      return item;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    confirmApprove() {
      let arr = this.options.filter(item => item.check).map(item => item.openId);
      if(!arr.length) {
        this.$message.success(this.$t('home.Please select member'));
        return;
      }
      this.loadingApprove = true;
      this.$confirm(this.$t("home.Areinvitation"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        approveCustomer({
          unid: this.customerCompanyId,
          groupUsers: arr.join(","),
          status: 1
        }).then(() => {
          //审批过了之后再初始化一次邀请清单
          this.$message.success(this.$t('home.Relationship established successfully'));
          this.$store.dispatch("user/setReadStatus", "READAPPLY");
          this.$emit("handleClose");
        }).finally(() => (this.loadingApprove = false));
      }).catch(() => {
        this.loadingApprove = false
      });
    },
    confirmInvite() {
      let arr = this.options.filter(item => item.check).map(item => item.openId);
      let data = {
        unid: this.customerCompanyId,
        userIds: arr.join(","),
      };
      this.loadingApprove = true;
      this.$confirm(this.$t("home.Confirm to modify the partner？"), this.$t("Tips"), {
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        updateCustomerUser(data)
          .then(() => {
            this.$message.success(this.$t("home.Successfully invited employees"));
            this.$store.dispatch("user/setReadStatus", "READAPPLY");
            this.$emit("handleClose");
          })
          .finally(() => (this.loadingApprove = false));
      }).catch(() => {
        this.loadingApprove = false
      });
    },
  },
};
</script>

<style lang="less" scoped>
.inviteBox {
  height: 250px;
  overflow: overlay;
  padding-bottom: 16px;
  .item {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 6px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        .tag {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 17px;
          background: #F7F9FC;
          border-radius: 4px;
          color: #076F49;
        }
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="less" scoped>
 /deep/.customerAssignPopover {
  // padding: 0;
 .el-dialog__body {
    padding: 0;
  }
}
.footerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .selectTotal {
    font-size: 12px;
    line-height: 17px;
    color: #076F49;
  }
}
</style>
