<template>
  <el-badge :value="chatCount" :max="99" class="messageBadge" :hidden="!chatCount">
    <el-tooltip
      popper-class="ec-tooltip"
      effect="light"
      :content="$t('InformationCenter.ping')"
      placement="bottom"
    >
      <div class="headerMessageBox" @click="openMessage">
        <i class="iconfont icon-Union"></i>
      </div>
    </el-tooltip>
  </el-badge>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { messageStat } from "@/api/news";
import textDisplay from "@/components/textDisplay/textDisplay.vue";

export default {
  components: {
    textDisplay
  },
  data() {
    return {
      dayjs: dayjs,
      pingVisible: false,
      chatCount: 0,
      data: {
        currentPage: 1
      },
      totalPage: 0,
      msglists: [],
      pingLoading: false
    }
  },
  computed: {
    ...mapGetters([
      "chatMessage",
      "chatRead"
    ]),
  },
  watch: {
    chatMessage() {
      this.getCount();
    },
    chatRead() {
      this.getCount();
    }
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getCount() {
      messageStat().then((data) => {
        this.chatCount = data.chat;
      });
    },
    openMessage() {
      if(this.$route.name == 'MessageList') return;
      this.$store.dispatch("user/setMessageDialogOpen", {
        visible: true,
        isElastic: true,
        top: null,
        otherParty: null,
        isHeaderMessage: true
      });
    }
  },
}
</script>

<style lang="less" scoped>
.messageBadge {
  /deep/ .el-badge__content {
    border-radius: 2px;
    border: 1px solid #ffffff;
    line-height: 16px;
    height: auto;
    padding: 0 3px;
    z-index: 1;
  }
}
</style>