import request from '@/utils/request';
import trackingZH from '@/language/tracking/zh'

//创建文件夹/文件
export function createFolder(data) {
  return request({
    url: '/word/add',
    method: 'post',
    data,
  });
}

// 编辑文件夹/文件
export function editFolder(data) {
  return request({
    url: '/word/update',
    method: 'post',
    data,
  })
}

// 删除文件夹/文件
export function deleteFolder(id) {
  return request({
    url: '/word/delete',
    method: 'post',
    params: {
      id
    },
  })
}

//获取文件夹/文件列表
export function getFolderList(params) {
  return request({
    url: '/word/page',
    method: 'get',
    params,
  });
}

//获取分享给我的文件夹/文件列表
export function getShareFolderList(params) {
  return request({
    url: '/word/sharePage',
    method: 'get',
    params,
  });
}

// 查询默认文档/分享给我的文档的数量
export function getShareOrDefaultNumber() {
  return request({
    url: '/word/findCount',
    method: 'get'
  });
}

// 导出为Word
export function saveToWord(id) {
  return request({
    url: '/word/exportWord',
    method: 'get',
    responseType: "blob",
    params: {
      id
    }
  });
}
