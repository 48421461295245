<template>
  <el-dialog
    :visible.sync="show"
    width="360px"
    append-to-body
    :before-close="close"
    v-move-outside
  >
    {{ callLoadingText }}
    <video ref="videoElement" autoplay></video>
    <button @click="startChat()">Start</button>
    <button @click="stopChat()">Stop</button>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Peer from 'simple-peer';

import { 
  videoPhoneCall, 
  videoPhoneResponse, 
  videoPhoneOpen, 
  setVideoPhoneStreamOffer 
} from "@/api/news";

export default {
  name: "home",
  props: {
    otherParty: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      peer: null,
      stream: null,
      callLoadingText: "",
      openId: ""
    };
  },
  computed: {
    ...mapGetters([
      "userInfo", 
      "callPhoneUpdate", 
      "videoPhoneResponseUpdate", 
      "notifySurrenderOfferUpdate",
      "getSurrenderOfferUpdate"
    ]),
  },
  mounted() {
    this.$myEvent.on('openVideoPhone', this.open);
  },
  watch: {
    callPhoneUpdate(val) {
      this.$nextTick(() => {
        document.querySelector("#videoPhoneRefuse").addEventListener('click', () => {
          videoPhoneResponse({
            toUserId: val.openId,
            agree: false
          }).then(() => {
            this.otherOpen({
              openId: val.openId
            })
          })
        })
        document.querySelector("#videoPhoneAgree").addEventListener('click', () => {
          videoPhoneResponse({
            toUserId: val.openId,
            agree: true
          }).then(() => {
            this.otherOpen({
              openId: val.openId
            })
          })
        })
      })
    },
    videoPhoneResponseUpdate(val) {
      if(val.agree) {
        this.startChat();
      } else {
        this.close();
      }
    },
    notifySurrenderOfferUpdate(val) {
      this.startChat(val);
    },
    getSurrenderOfferUpdate(val) {
      console.log(val);
    },
  },
  methods: {
    open({ openId }) {
      this.show = true;
      this.openId = openId;
      videoPhoneCall(openId).then(() => {
        this.callLoadingText = this.$t('Waiting to answer');
      });
    },
    otherOpen({ openId }) {
      this.$store.state.user.callPhoneNotify.close();
      this.show = true;
      this.openId = openId;
    },
    close() {
      this.show = false;
    },
    async startChat(mode = null) {
      this.stream = this.$refs.videoElement.srcObject;
      this.peer = new RTCPeerConnection({
        "iceServers":[{"urls":["turn:192.168.2.130:3478"],"username":"echola","credential":"Echola12345!"}],
        "iceTransportPolicy":"all"
      });
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(async (stream) => {
        // this.$refs.videoElement.srcObject = stream;
        // stream.getTracks().forEach((track) => {
        //   this.peer.addTrack(track, stream)
        // })
        const offer = await this.peer.createOffer();
        await this.peer.setLocalDescription(offer);
        console.log(offer);
        if(!mode) {
          videoPhoneOpen(this.openId);
        } else {
          setVideoPhoneStreamOffer({
            toUserId: mode.openId,
            message: offer
          })
        }
        this.stream = stream;
      });
      
      // console.log(this.peer);
      // this.peer.createOffer().then(function(offer) {
      //   return this.peer.setLocalDescription(offer);
      // }).then(function() {
      //   // 发送本地流给对方
      //   navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      //     .then(function(stream) {
      //       this.$refs.videoElement.srcObject = stream;
      //       stream.getTracks().forEach((track) => {
      //         this.peer.addTrack(track, stream);
      //       });
      //     });
      // });
      // this.peer.on('stream', (remoteStream) => {
      //   console.log(remoteStream);
      //   this.$refs.videoElement.srcObject = remoteStream;
      // });
      // this.peer.on('connection', (connection) => {
      //   console.log(connection);
      //   connection.createStream().then((stream) => {
      //     this.$refs.videoElement.srcObject = stream;
      //   });
      // });
      // this.peer.on('data', (data) => {
      //   console.log('Received message:', data);
      // });
    },
    stopChat() {
      if (this.peer) {
        this.peer.close();
        this.stream.getTracks().forEach(item => {
          item.stop();
        })
        this.$refs.videoElement.srcObject = null;
        this.peer = null;
      }
      if (this.stream) {
        this.stream = null;
      }
    },
    formatTime(seconds) {
      const date = new Date(seconds * 1000);
      const minutes = date.getMinutes();
      const second = date.getSeconds();
      let formattedMinutes = "";
      let formattedSeconds = "";
      if (minutes < 10) {
        formattedMinutes = "0" + minutes;
      } else {
        formattedMinutes = minutes;
      }
      if (second < 10) {
        formattedSeconds = "0" + second;
      } else {
        formattedSeconds = second;
      }
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  display: none;
}
</style>
