<template>
  <el-dialog
    :title="$t('Email Customization')+`(${$t('Only applicable to waybill/order sharing emails')})`"
    :visible.sync="show"
    width="668px"
    append-to-body
    modal-append-to-body
    :before-close="cancel"
  >
    <diaogConfigLayout>
      <template #config>
        <el-form
          ref="ruleForm"
          label-width="0"
          size="small"
          :model="form"
          class="drawerForm"
        >
          <el-form-item>
            <div class="title">{{ $t("home.email") }}</div>
            <el-input
              v-model="form.mail"
              clearable
              maxlength="50"
              show-word-limit
              :placeholder="$t('home.email')"
            />
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("login.phoneNo") }}</div>
            <el-input
              v-model="form.phone"
              clearable
              maxlength="15"
              show-word-limit
              :placeholder="$t('login.phoneNo')"
            />
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("Propaganda slogan") }}</div>
            <el-input
              v-model="form.slogan"
              clearable
              maxlength="40"
              show-word-limit
              :placeholder="$t('Propaganda slogan')"
            />
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("QR code 1 name") }}</div>
            <el-input
              v-model="form.qrCode1Title"
              clearable
              maxlength="20"
              show-word-limit
              :placeholder="$t('QR code 1 name')"
            />
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("Upload QR code") }}1</div>
            <el-upload
              action="#"
              class="avatar-uploader"
              accept=".png,.jpg,.jpeg"
              :show-file-list="false"
              :http-request="uploadPath"
            >
              <img
                v-if="form.qrCode1FileUrl"
                :src="form.qrCode1FileUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("QR code 2 name") }}</div>
            <el-input
              v-model="form.qrCode2Title"
              clearable
              maxlength="20"
              show-word-limit
              :placeholder="$t('QR code 2 name')"
            />
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t("Upload QR code") }}2</div>
            <el-upload
              action="#"
              class="avatar-uploader"
              accept=".png,.jpg,.jpeg"
              :show-file-list="false"
              :http-request="uploadPath2"
            >
              <img
                v-if="form.qrCode2FileUrl"
                :src="form.qrCode2FileUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </template>
      <template #component>
        <div class="previewPage">
          <div class="header">
            <img :src="platformLogo" />
          </div>
          <div class="content">{{ $t("Email Content") }}</div>
          <div class="footer">
            <div class="top">
              <div class="footerLogo">
                <div class="theme">
                  <span>{{ form.slogan }}</span>
                </div>
              </div>
              <div class="concat">
                <div class="title">{{ $t('xiaoxie.Contact us') }}</div>
                <div class="value phone">{{ form.phone }}</div>
                <div class="value">{{ $t('home.email') }}: {{ form.mail }}</div>
              </div>
              <div class="wecat">
                <div class="item">
                  <img v-if="form.qrCode1FileUrl" :src="form.qrCode1FileUrl" />
                  <div class="text">{{form.qrCode1Title}}</div>
                </div>
                <div class="item">
                  <img v-if="form.qrCode2FileUrl" :src="form.qrCode2FileUrl" />
                  <div class="text">{{form.qrCode2Title}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </diaogConfigLayout>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancel_button" size="small" @click="cancel">
        {{ $t("home.Cancel") }}
      </el-button>
      <el-button type="primary" size="small" @click="confirm">
        {{ $t("home.Confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import diaogConfigLayout from "../customFormConfig/config/components/layout.vue";

import { uploadFileUrl } from "@/api/files";
import { saveShareEmailConfig, getShareEmailConfig } from "@/api/common";

export default {
  name: "emailPageConfig",
  components: {
    diaogConfigLayout,
  },
  data() {
    return {
      show: false,
      form: {
        mail: "",
        phone: "",
        slogan: "",
        qrCode1Title: "",
        qrCode1FileId: "",
        qrCode1FileUrl: "",
        qrCode2Title: "",
        qrCode2FileId: "",
        qrCode2FileUrl: "",
      },
    };
  },
  computed: {
    ...mapGetters(["platformLogo", "companyId"]),
  },
  methods: {
    open() {
      this.show = true;
      getShareEmailConfig(this.companyId).then((data) => {
        if(data) {
          this.form = {
            mail: data.mail || '',
            phone: data.phone || '',
            slogan: data.slogan || '',
            qrCode1Title: data.qrCode1Title || '',
            qrCode1FileId: data.qrCode1FileId,
            qrCode1FileUrl: data.qrCode1FileUrl,
            qrCode2Title: data.qrCode2Title || '',
            qrCode2FileId: data.qrCode2FileId,
            qrCode2FileUrl: data.qrCode2FileUrl,
          };
        }
      })
    },
    cancel() {
      this.show = false;
      this.form = {
        mail: "",
        phone: "",
        slogan: "",
        qrCode1Title: "",
        qrCode1FileId: "",
        qrCode1FileUrl: "",
        qrCode2Title: "",
        qrCode2FileId: "",
        qrCode2FileUrl: "",
      };
    },
    beforeUpload(file) {
      let type = file.name.split(".").pop();
      if (!["png", "jpg", "jpeg"].includes(type.toLowerCase())) {
        this.$message.error(this.$t("File format not supported"));
        return;
      }
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.$message.error(this.$tc("order.File is too large to upload", 20));
      }
      return isLt10M;
    },
    uploadPath(options) {
      if (!this.beforeUpload(options.file)) return;
      const data_up = new FormData();
      data_up.append("files", options.file);
      data_up.append("configCode", "file_mail_banner");
      uploadFileUrl(data_up).then((data) => {
        if (!data) return;
        this.form.qrCode1FileUrl = data[0].savePath;
        this.form.qrCode1FileId = data[0].fileId;
      });
    },
    uploadPath2(options) {
      if (!this.beforeUpload(options.file)) return;
      const data_up = new FormData();
      data_up.append("files", options.file);
      data_up.append("configCode", "file_mail_banner");
      uploadFileUrl(data_up).then((data) => {
        if (!data) return;
        this.form.qrCode2FileUrl = data[0].savePath;
        this.form.qrCode2FileId = data[0].fileId;
      });
    },
    confirm() {
      saveShareEmailConfig({
        ...this.form,
        companyId: this.companyId
      }).then(() => {
        this.$message.success(this.$t('Successfully saved'));
        this.cancel();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawerForm {
  padding: 0px;
}
/deep/ .componentBox {
  .drawerForm {
    padding: 0 !important;
    height: auto;
  }
  .right {
    padding: 0;
    background: transparent;
    .components {
      padding-top: 30px;
    }
  }
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.previewPage {
  width: 100%;
  background-image: url(https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/%E6%B3%A8%E5%86%8C%E8%B4%A6%E5%8F%B7.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding: 11px 15px;
  .header img {
    width: 80px;
  }
  .content {
    width: 100%;
    height: 157px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a3b0c6;
  }
  

  .footer {
    zoom: 0.4;
    margin-top: 50px;
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      .footerLogo {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-top: 10px;
        img {
          width: 100px;
        }
        .theme {
          background: linear-gradient(179deg, #f8fdff, rgba(248, 253, 255, 0));
          font-size: 14px;
          justify-content: center;
          width: 100%;
          font-weight: 600;
        }
      }
      .concat {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        .phone {
          font-size: 16px;
          font-weight: bold;
          color: #121212;
        }
      }
      .wecat {
        display: flex;
        gap: 20px;
        flex: 1;
        .item {
          text-align: center;
          img {
            width: 100px;
            height: 100px;
            border-radius: 8px;
          }
          .text {
            font-size: 16px;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>
