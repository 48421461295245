<template>
  <div class="container_box" :class="isVipModeType == '1' ? 'vip' : ''" :style="{
    '--collapse-width': isCollapse ? '100px' : '200px'
  }">
    <div class="bodyMain"></div>
    <div class="menuLeft" :class="isCollapse ? 'shrink' : ''"></div>
    <div class="menu" :class="isCollapse ? 'shrink' : ''">
      <div class="logo">
        <img :src="platformLogo" alt="">
        <!-- <img v-else src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/logoVip.png" alt=""> -->
      </div>
      <div class="menuBox">
        <template v-for="(item, index) in menuList">
          <router-link 
            :key="index"
            :id="'menuItem'+index" 
            @mouseenter.native="mouseover($event, index)" 
            @mouseleave.native="mouseleave($event, index)" 
            v-if="!item.childTreeMenu.length" 
            :to="item.menuPath">
            <i class="iconfont" :class="item.icon"></i>
            <span @mouseenter.stop @mouseleave.stop :id="'menuTootip'+index">{{ item.menuPath == '/order' ? $isOrderTitle() : item.menuName }}</span>
          </router-link>
          <a
            v-else class="childrenMenu"
            :key="index+'children'"
            :id="'menuItem'+index"
            :class="$route.path.split('/')[1] === item.menuPath.split('/')[1] ? 'router-link-active' : ''"
            @click="menuChildrenRouter(item)"
            @mouseenter="mouseover($event, index)"
            @mouseleave="mouseleave($event, index)">
            <div>
              <i class="iconfont" :class="item.icon"></i>
              <span @mouseenter.stop @mouseleave.stop :id="'menuTootip'+index">{{ item.menuName }}</span>
            </div>
            <i class="iconfont icon-a-Polygon3" :class="item.isOpen ? 'down' : ''"></i>
          </a>
          <template>
            <div
              class="chidlren"
              :key="index+'childrenBox'"
              :class="item.isOpen ? 'show' : ''"
              :style="{
                '--menu-chileren-active': childrenAvtive(item)
              }">
              <router-link
                class="item"
                v-for="(row, idx) in item.childTreeMenu"
                :key="idx"
                :to="row.menuPath"
              >{{ row.menuName }}</router-link>
            </div>
          </template>
        </template>
      </div>

      <div 
        class="emailAssisteBox" 
        :class="isEmailAssiste ? 'active' : ''"
        @click="openEmailAssiste(1)">
        <div class="icon"></div>
        <div class="text">{{ $t('Email assistance') }}</div>
      </div>

      <el-popover
        placement="right-end"
        trigger="click"
        popper-class="configPopover"
        @hide="otherShow = false">
        <el-popover
          placement="right-end"
          trigger="click"
          popper-class="configPopover"
          @hide="otherThemeShow = false">
          <div 
            class="popperMenuItem" 
            :class="isVipModeType == 0 ? 'active' : ''" 
            @click="isVipModeType = 0"
          >{{ $t("Dashboard.ordinary") }}</div>
          <div 
            class="popperMenuItem" 
            :class="isVipModeType == 1 ? 'active' : ''" 
            @click="isVipModeType = 1"
          >{{$t('Dashboard.vip')}}</div>
          <div 
            slot="reference" 
            class="popperMenuItem"
            :class="otherThemeShow ? 'active' : ''" 
            @click="otherThemeShow = true"
          >{{ $t("Theme switch") }}</div>
        </el-popover>
        <div class="popperMenuItem" @click="getDictinfo">
          {{ $t("home.language") }}
        </div>
        <div class="popperMenuItem" @click="openEmailConfig">{{$t('Email setting')}}</div>
        <div
          slot="reference" 
          class="otherAction"
          :class="otherShow ? 'active' : ''"
          @click="otherShow = true">
          <p>{{ $t("home.moreAction") }}</p>
          <i class="iconfont icon-gengduo"></i>
        </div>
      </el-popover>
      <div class="menuCollapse" :class="isCollapse ? 'shrink' : ''" @click="collapseChange">
        <i class="iconfont icon-gengduo"></i>
      </div>
    </div>
    <div class="main">
      <div class="mainBox">
        <div
          class="header" 
          :style="`width: calc(100% - ${isCollapse ? '100px' : '200px'});`">
          <div class="left">
            <div 
              class="aiOpenBox" 
              :class="isEmailAssiste ? 'active' : ''" 
              @click="openEmailAssiste(4)"
              @mouseenter="() => aiLeaveShow = true"
              @mouseleave="() => aiLeaveShow = false">
              <div class="aiBox">
                <i class="iconfont" :class="isEmailAssiste || aiLeaveShow ? 'icon-xieyankan' : 'icon-AI'"></i>
              </div>
              <span class="name">Echola AI</span>
            </div>
          </div>
          <div class="right">
            <!-- 上传提示 -->
            <el-tooltip
              popper-class="ec-tooltip"
              effect="light"
              :content="$t('File upload in progress')"
              placement="bottom"
              v-if="isUpload"
            >
              <div class="uploadTipBox">
                <el-progress 
                  type="circle" 
                  :percentage="percentLoading" 
                  :color="uploadColors"
                  :width="34"
                  :stroke-width="2"
                  v-if="isUpload"
                ></el-progress>
                <div class="box">
                  <i v-if="percentLoading < 100" :class="`iconfont ${currentFileType == 'video' ? 'icon-shipinshangchuan' : 'icon-wenjianshangchuan'}`"></i>
                  <i v-else class="el-icon-check"></i>
                </div>
              </div>
            </el-tooltip>
            <!-- Ping -->
            <ping />
            <!-- 铃铛 -->
            <smallBell />
            <!-- 个人信息 -->
            <info />
          </div>
        </div>
        <div class="router-view">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </div>
    </div>
    
    <eventCommentListVue
      :eventCommentListShow.sync="eventCommentListShow1"
      :eventCommentList="eventCommentList"
      @handleClose="handleEventClose"
      @afterEventComment="afterEventComment"
      :eventOrComment="eventOrComment1"
    />

    <createPurchase />

    <emailConfig ref="emailConfig" />
    <emailAssiste ref="emailAssiste" @close="isEmailAssiste = false" />
    <audioVideoChat />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

// 消息
import ping from "./components/ping";
// 铃铛
import smallBell from "./components/smallBell";
// 个人信息
import info from "./components/info";
import headerSearch from "@/components/HeaderSearch/HeaderSearch";
import eventCommentListVue from "@/components/eventCommentList/eventCommentList";

import emailConfig from "@/components/emailConfig";
import emailAssiste from "@/components/emailAssiste/index.vue";

import createPurchase from "@/components/createPurchase";

import audioVideoChat from "@/components/audioVideoChat";

import { setPersonalization } from "@/api/common";
import { chunkFileUpload, testChunkFileUpload } from "@/api/files";

export default {
  name: "Home",
  components: {
    headerSearch,
    eventCommentListVue,
    ping,
    smallBell,
    info,
    emailConfig,
    emailAssiste,
    createPurchase,
    audioVideoChat
  },
  // 父组件中返回要传给下级的数据
  provide() {
    return {
      reload: this.reload,
      parent: this,
    };
  },
  data() {
    return {
      isVipModeType: "0",
      isEmailAssiste: false,
      otherThemeShow: false,
      aiLeaveShow: false,
      otherShow: false,
      isCollapse: true,
      otherPartyId: null,
      isRouterAlive: true,
      path: "",
      eventCommentListShow1: false,
      eventCommentList: null,
      eventOrComment1: null,
      baseSocketUrl: "ws://42.192.50.25:7777/echola/ws/user/",
      activeName: "Comment",
      //铃铛
      time: "",
      popoverVisible: false,
      commentCount: 0,
      eventCount: 0,
      applyCount: 0,
      todoCount: 0,
      //1v1
      isComment: false,
      pingVisible: false,
      msglists: [],
      pingLoading: false,
      routerViewList: [],
      fileQueue: [],
      tasks: [],
      uploaded: 0,
      percentLoading: 0,
      isUpload: false,
      currentFileType: "",
      uploadColors: [
        {color: '#076F49', percentage: 20},
        {color: '#076F49', percentage: 40},
        {color: '#076F49', percentage: 60},
        {color: '#076F49', percentage: 80},
        {color: '#076F49', percentage: 100}
      ]
    };
  },
  computed: {
    ...mapGetters([
      "menuList",
      "language",
      "businessUserId",
      "personalization",
      "routerViewExclude",
      "isVipMode",
      "platformLogo"
    ]),
    childrenAvtive() {
      return function (row) {
        const id = this.$route.params?.id;
        if(id) {
          let isVipMode = localStorage.getItem("isVipMode");
          if(isVipMode == 1) {
            return (Number(id == 5 ? 4 : id == 4 ? 3 : id) * 44) + 'px';
          }
          return (Number(id) * 44) + 'px';
        }
        return '-44px';
      }
    },
    i18n() {
      return this.$i18n.locale;
    },
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      let menuListFilter = this.menuList.filter(item => to.path.split('/')[1] === item.menuPath.split('/')[1])[0];
      if(menuListFilter && menuListFilter.childTreeMenu.length) {
        menuListFilter.isOpen = true;
      } else {
        this.menuList.forEach(item => {
          if(item.isOpen) {
            item.isOpen = false;
          }
        })
      }
    },
    isCollapse(val) {
      if(val) {
        this.menuList.forEach(item => {
          item.isOpen = false;
        })
      } else {
        let menuListFilter = this.menuList.filter(item => this.$route.path.split('/')[1] === item.menuPath.split('/')[1])[0];
        if(menuListFilter && menuListFilter.childTreeMenu.length) {
          menuListFilter.isOpen = true;
        } else {
          this.menuList.forEach(item => {
            if(item.isOpen) {
              item.isOpen = false;
            }
          })
        }
      }
    },
    isVipMode: {
      handler() {
        this.isVipModeType = this.isVipMode;
      },
      immediate: true
    },
    isVipModeType: {
      handler() {
        this.$store.dispatch("layout/setVipMode", this.isVipModeType);
        if(this.isVipModeType == '0') {
          this.$store.dispatch("user/clearVipMenuList");
        } else {
          this.$store.dispatch("user/setVipMenuList");
        }
      },
      immediate: true
    },
    fileQueue: {
      handler(val) {
        if(this.percentLoading > 0 && this.percentLoading < 100) return;
        this.isUpload = false;
        this.uploaded = 0;
        this.percentLoading = 0;
        this.tasks = [];
        if(val.length) {
          this.upload(val[0])
        }
      },
      deep: true
    }
  },
  created() {
    this.path = this.$route.path;
    this.$store.dispatch("dict/getCountry");
    this.$store.dispatch('dict/getDictData');
    this.$store.dispatch('dict/getFileAllConfigList');
    this.$store.dispatch('user/getInfo', 'login');
    this.isCollapse = JSON.parse(localStorage.getItem("isCollapse"));
  },
  mounted() {
    console.log(this.$route.meta);
    this.routerViewList = this.$router.options.routes.filter(item => item.name === 'Home')[0].children;
    this.$nextTick(() => {
      this.menuList.forEach((item, index) => {
        if(this.$route.path.split('/')[1] === item.menuPath.split('/')[1]) {
          if(item.childTreeMenu.length) {
            item.isOpen = true;
          }
        }
        this.mouseover({}, index)
      })
    })
    this.$myEvent.on('uploadFile', (data) => {
      this.fileQueue.push(data);
    })
  },
  methods: {
    openEmailConfig() {
      this.$refs.emailConfig.open();
    },
    openEmailAssiste(active = 1) {
      this.$refs.emailAssiste.open(active);
      this.isEmailAssiste = true;
    },
    async upload({ file, configCode }) {
      this.currentFileType = file.type.split("/")[0];
      const chunkSize = 1024 * 1024 * 10; // 每个块的大小为 1MB
      const fileSize = file.size;  // 文件大小
      const chunks = Math.ceil(fileSize / chunkSize);  // 总块数
      const fileMd5 = await this.$getFileMd5(file);
      this.isUpload = true;
      // // 文件切割
      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, fileSize);
        this.tasks.push({
          index: i+1,
          chunkTotal: chunks,
          md5: fileMd5,
          chunk: file.slice(start, end),
          chunkSize: chunkSize / 1024,
          fileName: file.name,
          fileSize: Math.round(file.size / 1024),
          configCode: configCode
        });
      }
      if(this.tasks.length) {
        this.percentLoading = 0;
        testChunkFileUpload({
          md5: fileMd5,
          fileName: file.name,
          configCode: configCode
        }).then((data) => {
          if(!data.uploaded && !data.chunkList.length) {
            this.uploadEvent();
          } else if(data.chunkList.length) {
            this.uploaded = data.chunkList[data.chunkList.length-1];
            this.uploadEvent();
          } else {
            this.percentLoading = 100;
            this.fileQueue[0].apiFn({
              ...this.fileQueue[0].params,
              eventFileId: data.fileId
            }).finally(() => {
              setTimeout(() => {
                this.fileQueue.splice(0, 1);
              }, 2000);
            });
          }
        }).catch(() => {
          this.fileQueue = [];
        })
      }
    },
    uploadEvent() {
      const formData = new FormData();
      formData.append('index', this.tasks[this.uploaded].index);  // 块编号
      formData.append('chunkTotal', this.tasks[this.uploaded].chunkTotal);  // 总块数
      formData.append('md5', this.tasks[this.uploaded].md5);  // 文件ID
      formData.append('chunk', this.tasks[this.uploaded].chunk);  // 块数据
      formData.append('chunkSize', this.tasks[this.uploaded].chunkSize);  // 文件大小
      formData.append('fileName', this.tasks[this.uploaded].fileName);  // 文件名称
      formData.append('fileSize', this.tasks[this.uploaded].fileSize);  // 文件名称
      formData.append('configCode', this.tasks[this.uploaded].configCode);  // 文件名称
      chunkFileUpload(formData).then(data => {
        this.uploaded += 1;
        this.percentLoading = Math.floor((this.uploaded / this.tasks[this.uploaded - 1].chunkTotal) * 100);
        if(this.uploaded == this.tasks[this.uploaded - 1].chunkTotal) {
          this.fileQueue[0].apiFn({
            ...this.fileQueue[0].params,
            eventFileId: data.fileId
          }).finally(() => {
            setTimeout(() => {
              this.fileQueue.splice(0, 1);
            }, 2000);
          });
          return;
        }
        this.uploadEvent();
      }).catch(err => {
        this.uploaded = 0;
        this.isUpload = false;
        this.percentLoading = 0;
      });
    },
    getDictinfo() {
      setPersonalization({
        ...this.personalization,
        language: this.$i18n.locale === "zh" ? "en" : "zh"
      }).then(() => {
        localStorage.setItem("lang", this.$i18n.locale === "zh" ? "en" : "zh");
        location.reload();
      });
    },
    menuChildrenRouter(item) {
      item.isOpen = !item.isOpen;
      setTimeout(() => {
        this.$router.push(item.childTreeMenu[0].menuPath);
      }, 2)
    },
    mouseover(e, index) {
      if(this.isCollapse) {
        let width = document.querySelector(`#menuItem${index}`).offsetWidth - 30;
        let top = document.querySelector(`#menuItem${index}`).offsetTop+15;
        let left = document.querySelector(`#menuItem${index}`).offsetLeft+width;
        document.querySelector(`#menuTootip${index}`).style.top = top+'px';
        document.querySelector(`#menuTootip${index}`).style.left = left+'px';
        var arr = Object.keys(e);
        if(arr.length) {
          document.querySelector(`#menuTootip${index}`).style.opacity = '1';
        }
      }
    },
    mouseleave(e, index) {
      if(this.isCollapse) {
        document.querySelector(`#menuTootip${index}`).style.opacity = '0';
      }
    },
    collapseChange() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"))
      }, 500)
      this.isCollapse = !this.isCollapse;
      localStorage.setItem("isCollapse", this.isCollapse);
      this.$nextTick(() => {
        this.menuList.forEach((item, index) => {
          if(this.isCollapse) {
            document.querySelector(`#menuTootip${index}`).style.opacity = '0';
          } else {
            document.querySelector(`#menuTootip${index}`).style.opacity = '1';
          }
        })
      })
    },
    //调试用
    onDebug() {
      this.$store.dispatch("updateCount/orderUpdate");
    },
    //同步初始化重构界面客户清单，按顺序
    linkTo(path) {
      if (this.path == path) {
        return this.reload();
        // 暂时不做reload操作
      } else {
        this.$router.push({
          path,
        });
        this.currentPath = path;
        window.localStorage.setItem("currentPath", path);
      }
      //下面的程序实现点击按钮关闭popover，前提需要在popover上加上ref=popover
      this.$refs.popover.showPopper = false;
    },
    reload(value) {
      this.$nextTick(() => {
        if(value.length) {
          this.routerViewList.forEach(row => {
            value.forEach(item => {
              if(row.name === item) {
                row.isRouterAlive = false;
                this.$nextTick(() => {
                  row.isRouterAlive = true;
                });
              }
            })
          })
        } else {
          this.routerViewList.forEach(row => {
            row.isRouterAlive = false;
            this.$nextTick(() => {
              row.isRouterAlive = true;
            });
          })
        }
        let arr = this.routerViewExclude;
        this.$store.dispatch("user/setRouterExclude", this.routerViewExclude.concat(value));
        this.$nextTick(() => {
          this.$store.dispatch("user/setRouterExclude", arr);
        })
      })
    },
    afterEventComment() {},
    handleEventClose() {
      this.eventCommentListShow1 = false;
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>

<style lang="less">
@import "./layoutGolabl.less";
</style>
