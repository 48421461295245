<template>
  <div>
    <el-popover
      class="item"
      ref="popover"
      placement="bottom"
      :width="popoverWidth"
      trigger="click"
      :content="$t('InformationCenter.Notification')"
      popper-class="notification-popover"
      @hide="popoverVisible = false"
      @show="notificationShowEvent"
    >
      <div class="small_bell" @click="timeStamp()">
        <ul class="small_bell_title">
          <li
            v-for="(item, index) in [
              {
                name: '2',
                count: commentCount,
                title: $t('InformationCenter.Comment'),
              },
              {
                name: '3',
                count: eventCount,
                title: $t('InformationCenter.Event'),
              },
              {
                name: '1',
                count: todoCount,
                title: $t('InformationCenter.Todo'),
              },
              {
                name: '4',
                count: applyCount,
                title: $t('InformationCenter.Apply'),
              },
            ].splice(0, manageCompany ? 4 : 3)"
            :key="index"
            :class="`${activeName == item.name ? 'active' : ''}`"
            @click="activeName = item.name"
          >
            <span>{{ item.title }}</span>
            <span
              :class="`${activeName == item.name ? 'active_span' : ''}`"
            >
              {{ item.count > 99 ? '99+' : item.count  }}
            </span>
          </li>
        </ul>
        <comment
          v-show="activeName == '2'"
          ref="comment"
          @updateCount="getCount"
          :time="time"
        />
        <event
          v-show="activeName == '3'"
          ref="event"
          @updateCount="getCount"
          :time="time"
        />
        <todo
          v-show="activeName == '1'"
          ref="todo"
          @updateCount="getCount"
          :time="time"
        />
        <apply
          v-show="activeName == '4'"
          ref="apply"
          v-if="manageCompany"
          @updateCount="getCount"
          :time="time"
        />
        <div class="seeMore">
          <el-tooltip
            v-if="activeName == '2' || activeName == '3'" 
            popper-class="ec-tooltip"
            effect="light"
            :content="$t('One click read')"
            placement="bottom"
          >
            <i 
              class="iconfont icon-yijianyidu"
              @click="oneClickRead"
            ></i>
          </el-tooltip>
          <i v-else></i>
          <span @click="goMessagePage">{{ $t('home.seeMore') }}<i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
      <el-badge
        slot="reference"
        :is-dot="commentCount + eventCount + applyCount + todoCount > 0"
        class="messageBadge"
      >
        <el-tooltip
          popper-class="ec-tooltip"
          effect="light"
          :content="$t('InformationCenter.notified')"
          placement="bottom"
        >
          <div class="headerMessageBox" :class="popoverVisible ? 'active' : ''" @click="popoverVisible = !popoverVisible">
            <i class="iconfont icon-Vector3"></i>
          </div>
        </el-tooltip>
      </el-badge>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import comment from "./comment.vue";
import event from "./event.vue";
import todo from "./todo.vue";
import apply from "./apply.vue";

import { 
  messageStat,
  eventOneClickRead,
  commentOneClickRead
} from "@/api/news";

export default {
  components: {
    comment,
    event,
    todo,
    apply,
  },
  data() {
    return {
      time: "",
      activeName: "2",
      popoverVisible: false,
      commentCount: 0,
      eventCount: 0,
      applyCount: 0,
      todoCount: 0
    }
  },
  computed: {
    ...mapGetters([
      "manageCompany", 
      "commentUpdate",
      "readComment",
      "eventUpdate", 
      "readEvent",
      "todoUpdate",
      "readTodo",
      "applicationUpdate",
      "readApply",
      "commentAllRead",
      "eventAllRead"
    ]),
    i18n() {
      return window.vm.$i18n.locale;
    },
    popoverWidth() {
      return this.i18n === 'zh' ? 312 : 340;
    }
  },
  watch: {
    commentAllRead() {
      this.getCount();
    },
    eventAllRead() {
      this.getCount();
    },
    commentUpdate() {
      this.getCount();
    },
    readComment() {
      this.getCount();
    },
    eventUpdate() {
      this.getCount();
    },
    readEvent() {
      this.getCount();
    },
    todoUpdate() {
      this.getCount();
    },
    readTodo() {
      this.getCount();
    },
    applicationUpdate() {
      this.getCount();
    },
    readApply() {
      this.getCount();
    }
  },
  mounted() {
    this.getCount();
  },
  methods: {
    goMessagePage() {
      this.$router.push({
        path: "/messageList",
        query: { type: this.activeName },
      });
    },
    oneClickRead() {
      if(this.activeName == '2') {
        commentOneClickRead().then(() => {
          this.$message.success(this.$t('Read completed'));
          this.$store.dispatch("user/setReadStatus", "AllREADCOMMENT");
        });
      }
      if(this.activeName == '3') {
        eventOneClickRead().then(() => {
          this.$message.success(this.$t('Read completed'));
          this.$store.dispatch("user/setReadStatus", "ALLREADEVENT");
        });
      }
    },
    //铃铛去除点击头像重复问题
    timeStamp() {
      this.time = Date.now();
    },
    notificationShowEvent() {
      if(this.activeName == '2') {
        this.$refs.comment.onGetList(true);
      }
      if(this.activeName == '3') {
        this.$refs.event.onGetList(true);
      }
      if(this.activeName == '1') {
        this.$refs.todo.onGetList(true);
      }
      if(this.activeName == '4') {
        this.$refs.apply.onGetList(true);
      }
      this.getCount();
    },
    getCount() {
      messageStat().then((data) => {
        this.todoCount = data.todo || 0;
        this.commentCount = data.comment || 0;
        this.eventCount = data.event || 0;
        if(this.manageCompany) {
          this.applyCount = data.apply || 0;
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
.seeMore {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #076F49;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  .icon-yijianyidu {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #c6c6c6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
  }
}
</style>