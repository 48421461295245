<template>
  <div class="guideBox">
    <div class="list" v-loading="loading">
      <el-row :gutter="28">
        <el-col 
          :span="6" 
          v-for="(item, index) in searchDirectoryList" 
          :key="index">
          <div 
            class="item" >
            <div class="title">
              <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/guide.svg" />
              <div class="text line1" v-showTootip>{{ item.newbieGuideName }}</div>
            </div>
            <div class="articeList">
              <div 
                class="row line1" 
                v-for="(row, idx) in item.children.filter((d, index) => index < 3)" 
                :key="idx"
                v-showTootip
                @click="jump(item, row)"
              >{{row.newbieGuideName}}</div>
            </div>
            <div class="seeMore" @click="jump(item, null)">{{ $t('home.seeMore') }}</div>
            <!-- <div class="collectBox"> -->
              <!-- <toolTipIcon  -->
                <!-- :icon="`iconfont ${!item.collect ? 'icon-shoucang' : 'icon-yishoucang'}`"  -->
                <!-- :tooltipText="!item.collect ? $t('home.Follow') : $t('home.Unfollow')" -->
                <!-- @click="collectEvent(item)" -->
              <!-- /> -->
            <!-- </div> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <guideDrawer ref="guideDrawer" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getGuideDirectoryList, collectGuide } from "@/api/common";
import guideDrawer from '../drawer/guideDrawer.vue';
export default {
  props: {
    keyword: {
      type: Object,
      default: null
    }
  },
  components: {
    guideDrawer
  },
  data() {
    return {
      dayjs: dayjs,
      loading: false,
      directoryList: [],
      directoryKeyword: "",
      isCollect: 0
    }
  },
  mounted() {
    this.getDirectoryList();
  },
  computed: {
    searchDirectoryList() {
      return this.directoryList.filter(row => {
        return row.newbieGuideName.toLowerCase().indexOf(this.directoryKeyword.toLowerCase()) !== -1;
      })
    }
  },
  watch: {
    keyword(val) {
      if(val) {
        this.directoryList = this.directoryList.map(item => {
          return {
            ...item,
            children: item.children.filter(row => row.newbieGuideId == val.newbieGuideId)
          }
        }).filter(item => {
          return item.newbieGuideId == (item.children.find(row => row.newbieGuideId == val.newbieGuideId)?.newbieGuideParentId)
        })
      } else {
        this.getDirectoryList();
      }
    }
  },
  methods: {
    getDirectoryList(active) {
      getGuideDirectoryList({
        collect: this.isCollect,
        page: 1,
        size: 1000000
      }).then((data) => {
        this.directoryList = data;
        if(this.keyword) {
          this.directoryList = this.directoryList.map(item => {
            return {
              ...item,
              children: item.children.filter(row => row.newbieGuideId == this.keyword.newbieGuideId)
            }
          }).filter(item => {
            return item.newbieGuideId == (item.children.find(row => row.newbieGuideId == this.keyword.newbieGuideId)?.newbieGuideParentId)
          })
        }
      })
    },
    searchCollect() {
      this.isCollect = !this.isCollect ? 1 : 0;
      this.getDirectoryList();
    },
    collectEvent(item) {
      if(item.collect == 1) {
        this.$confirm(
          this.$t('Are you sure to cancel the collection'), 
          this.$t("Tips"),
          {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          collectGuide(item.newbieGuideId).then(() => {
            this.getDirectoryList();
          })
        }).catch(() => {});
        return;
      }
      collectGuide(item.newbieGuideId).then(() => {
        this.getDirectoryList();
      })
    },
    jump(item, row = null) {
      this.$refs.guideDrawer.open(item, row)
    }
  },
}
</script>

<style lang="less" scoped>
.guideBox {
  padding: 20px 20px 0 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /deep/ .el-input {
      width: 168px;
    }
    /deep/ .icon-yishoucang {
      color: #DF8F31;
    }
  }
  .list {
    height: calc(100vh - 100px);
    .item {
      width: 100%;
      height: 180px;
      background: #F7F9FC;
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 24px 20px 16px 20px;
      position: relative;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-bottom: 12px;
        border-bottom: 1px solid #E0E4EA;
        img {
          width: 28px;
          height: 28px;
        }
        .text {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          flex: 1;
          min-width: 0;
          padding-right: 20px;
        }
      }
      .articeList {
        .row {
          margin-top: 12px;
          font-size: 12px;
          line-height: 12px;
          color: #122545;
          cursor: pointer;
          &:hover {
            color: #004C97;
          }
        }
      }
      .seeMore {
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        color: #004C97;
        text-align: right;
        position: absolute;
        bottom: 16px;
        left: 0;
        width: 100%;
        padding: 0 20px;
        cursor: pointer;
      }
      .collectBox {
        position: absolute;
        top: 8px;
        right: 8px;
        /deep/ .icon-yishoucang {
          color: #DF8F31;
        }
      }
    }
  }
}
</style>