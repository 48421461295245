<template>
  <el-dialog 
    :visible.sync="dialogExportShow"
    :title="$t('Files.Choose Download Content')"
    :before-close="() => dialogExportShow = false"
    width="360px"
    append-to-body
    v-move-outside>
    <div class="exportFileBox">
      <div class="item" :class="exportActive == 1 ? 'active' : ''" @click="exportActive = 1">
        <i class="iconfont_file icon-Pdf"></i>
        <div class="text">Pdf</div>
      </div>
      <div class="item" :class="exportActive == 2 ? 'active' : ''" @click="exportActive = 2">
        <i class="iconfont_file icon-Word"></i>
        <div class="text">Word</div>
      </div>
      <div class="item" :class="exportActive == 3 ? 'active' : ''" @click="exportActive = 3">
        <i class="iconfont_file icon-Excel"></i>
        <div class="text">Excel</div>
      </div>
    </div>
    <div slot="footer">
      <el-button
        class="cancel_button"
        @click="dialogExportShow = false;"
        size="small">
        {{ $t('Cancel') }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="fileExportSubmit">
        {{ $t('Confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { exportPurchaseWord, exportPurchasePdf } from "@/api/order";

export default {
  props: {
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialogExportShow: false,
      exportActive: 1
    }
  },
  computed: mapGetters(["transportMode", "payMentData", "payMentMethods"]),
  methods: {
    open() {
      this.dialogExportShow = true;
    },
    fileExportSubmit() {
      if(this.exportActive == 1) {
        this.exportOffice(1);
      }
      if(this.exportActive == 2) {
        this.exportOffice(2);
      }
      if(this.exportActive == 3) {
        this.handleDownload();
      }
    },
    exportOffice(mode) {
      let api = mode == 1 ? exportPurchasePdf : exportPurchaseWord;
      let fileType = mode == 2 ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf";
      api(this.row.purchaseId).then((data) => {
        const elink = document.createElement('a');
        elink.style.display = 'none';
        //2.blob是二进制大对象，接受后台返回的数据流，导出数据
        const blob = new Blob([data], { type: fileType });
        //3.创建一个url 对象，并将blob实例作为参数传入，这样href的值就是以blob开头的一个文件流下载链接,类似于href="blob:http://127.0.0.1xxxxx"
        const blobUrl = URL.createObjectURL(blob);
        elink.href = blobUrl;
        elink.download = this.row.purchaseNo;//下载后的文件名
        //4.将a标签加入body,并手动点击触发下载
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      })
    },
    handleDownload() {
      var tHeader = [
        this.$t('Purchase.Purchase Number'),
        ...this.row.detailJson.map((item, index) => [
          this.$tc('Purchase.ProductIndex', index+1),
          this.$t('order.Quantity'),
          this.$t('unitPrice'),
        ]),
        this.$t('order.cny'),
        this.$t('Purchase.Delivery address'),
        this.$t('Purchase.Delivery date'),
        this.$t('Purchase.Mode of transport'),
        this.$t('Purchase.Delivery terms'),
        this.$t('Purchase.Payment method'),
        this.$t('Purchase.Payment date'),
        this.$t('Upload attachments'),
        this.$t('todo.Notes')
      ].flat(Infinity);
      var filterVal = [
        this.row.purchaseNo,
        ...this.row.detailJson.map((item, index) => [
          `${item.productCode}${item.productName}`,
          item.productQuantity,
          item.productPrice+item.productUnit,
        ]),
        this.row.totalPrice+this.row.totalPriceUnit,
        this.row.deliveryAddress,
        this.row.deliveryDate,
        this.$isFindData(this.transportMode, this.row.deliveryMethod, 'value', 'key'),
        this.$isFindData(this.payMentData, this.row.transactionTerms, 'value', 'key'),
        this.$isFindData(this.payMentMethods, this.row.paymentMethod, 'value', 'key'),
        this.row.paymentDate,
        this.row.attachmentUrl,
        this.row.remark
      ].flat(Infinity);
      var filename = this.$t('eventExport.eventFile');
      this.$export_json_to_excel({
        header: tHeader,
        data: [filterVal],
        filename,
      });
    },
  }
}
</script>

<style lang="less" scoped>
.exportFileBox {
  display: flex;
  gap: 25px;
  .item {
    width: 92px;
    height: 75px;
    background: #F7F9FC;
    border-radius: 4px;
    border: 1px solid #F7F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 32px;
    }
    .text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #A3B0C6;
      margin-top: 3px;
    }
    &.active {
      border-color: #004C97;
      .text {
        color: #004C97;
      }
    }
  }
}
</style>