<template>
  <div v-loading="loading">
    <div class="message_popover_box" v-infinite-scroll="load" :infinite-scroll-distance="20">
      <ul v-if="duplicateRemoval.length">
        <li
          v-for="(item, index) in duplicateRemoval"
          :key="index"
          class="message_list_item"
          :style="`opacity: ${!item.read ? '1' : '0.5'};`"
        >
          <member :info="item.createPerson" :size="48" :time="time" />
          <div class="content" @click="jump(item)">
            <textDisplay
              :isDropActive="!item.read"
              :titleName="item.createPerson.nickName"
              :titleConnect="`${item.replyPerson ? $t('InformationCenter.replied') : $t('InformationCenter.Comments')}${$t('InformationCenter.in')}`"
              :orderCI="item.subject"
              :content="item.content"
              :createTime="item.createTime"
            />
          </div>
        </li>
      </ul>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
  </div>
</template>
<script>
import textDisplay from "@/components/textDisplay/textDisplay.vue";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { getCommentList, commentRead } from "@/api/event";
import { uniq } from "lodash";

export default {
  name: "comment",
  inject: ["reload"],
  data() {
    return {
      data: {
        currentPage: 1,
        pageSize: 20,
        excludeRead: true
      },
      commentList: [],
      loading: false,
      commentTotalPage: 0
    };
  },
  components: {
    textDisplay,
  },
  computed: {
    ...mapGetters(["commentUpdate", "commentAllRead"]),
    duplicateRemoval() {
      return uniq(this.commentList);
    },
  },
  props: ["time"],
  watch: {
    commentUpdate() {
      this.onGetList(true);
    },
    commentAllRead() {
      this.onGetList(true);
    }
  },
  mounted() {
    this.onGetList(true);
  },
  methods: {
    load() {
      if (this.data.currentPage < this.commentTotalPage) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    onGetList(clear = false) {
      this.loading = true;
      getCommentList(this.data)
        .then((data) => {
          if (clear) this.commentList = [];
          this.commentList = this.commentList.concat(data.list.map((res) => ({
            ...res,
            content: res.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
            createTime: dayjs(res.createTime).format("MM/DD HH:mm")
          })));
          this.commentTotalPage = data.pages;
        })
        .finally(() => (this.loading = false));
    },
    jump(data) {
      commentRead(data.commentId).then(() => {
        data.read = true;
        this.$emit("updateCount");
        this.$store.dispatch("user/setReadStatus", "READCOMMENT");
        this.reload(["OrderDetails"]);
        this.$router.push({
          path: "/order/orderDetails",
          query: {
            orderId: data.orderId,
            commentId: data.commentId
          },
        });
      })
    },
  },
};
</script>
<style lang="less" scoped>
</style>
