<template>
  <div class="AiBox">
    <div class="left">
      <div class="header">
        <el-button
          icon="el-icon-plus"
          :disabled="isNewDialogue || sendMessageLoading"
          @click="addDialogue"
          >{{ $t("EcholaAi.New dialogue") }}</el-button
        >
      </div>
      <div class="dialogueList">
        <div class="title">{{ $t("EcholaAi.Recent conversations") }}</div>
        <div class="list">
          <div
            class="item"
            :class="{
              'active': item.chatAiTopicId == params.topId,
              'moreAction': item.check,
              'pending': sendMessageLoading
            }"
            v-for="(item, index) in dialogueList"
            :key="index"
            @click="seeMessage(item)"
          >
            <div class="iconBox">
              <i class="iconfont icon-xiaoxi"></i>
            </div>
            <div class="content line1">
              <el-tooltip
                popper-class="ec-tooltip"
                effect="light"
                :content="item.title"
                placement="top"
                :open-delay="500"
              >
                <span>{{ item.title }}</span>
              </el-tooltip>
            </div>
            <el-popover
              ref="popover_order"
              class="el-pop"
              placement="right-start"
              trigger="click"
              @hide="item.check = false"
              popper-class="ec-popper"
            >
              <div
                class="popperMenuItem"
                @click="deleteDialogue(item)"
              >
                <i class="iconfont icon-shanchu"></i>
                <span>{{ $t("home.Delete") }}</span>
              </div>
              <i
                class="iconfont icon-Ellipsis downEllipsis"
                slot="reference"
                @click.stop="activePopover(dialogueList, item, 'popover_order')"
              />
            </el-popover>
          </div>
        </div>
        <div class="openMore" v-if="dialogueListTotal > 3" @click="openMore">
          <i :class="dialogueListTotal != dialogueList.length ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
          {{ dialogueListTotal != dialogueList.length ? $t('EcholaAi.Open more') : $t('retract') }}
        </div>
      </div>
      <div class="userInfoBox">
        <div class="userBox">
          <member :info="userInfo" :size="40" />
          <div class="name line1">{{ userInfo.nickName }}</div>
        </div>
        <div class="aiUserTag">{{ $t('EcholaAi.Questioner') }}</div>
      </div>
    </div>
    <div class="right">
      <div class="liveContainer" ref="chatLive" @scroll="scroll($event)">
        <div class="item" v-for="(item, index) in messageList" :key="index">
          <template v-if="item.role == 'user'">
            <member v-if="item.role == 'user'" :info="userInfo" :size="28" />
            <div class="content">
              {{ item.content }}
            </div>
          </template>
          <template v-else>
            <div class="aiMessageBox">
              <div class="echolaAiIcon">
                <i class="iconfont icon-AI"></i>
              </div>
              <div class="content">
                <MarkdownItVue :content="item.content" />
              </div>
              <div class="copytext" v-if="!item.oneMessage" @click="copyText(item.content)">
                <i class="el-icon-copy-document"></i>
                {{ $t('Copy') }}
              </div>
            </div>
          </template>
        </div>
        <img 
          v-if="sendMessageLoading" 
          class="loadingMessage" 
          src="@/assets/image/loading.svg" 
        />
      </div>
      <div class="footerInput">
        <input
          v-model="params.content"
          :placeholder="$t('EcholaAi.Enter your question here')"
          @keyup.enter="sendMessage"
        />
        <i
          class="iconfont icon-fasong1"
          :class="params.content ? 'active' : ''"
          @click="sendMessage"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MarkdownItVue from 'markdown-it-vue'
import {
  getDialogueListApi,
  sendEcholaAIMessage,
  getEcholaAIMessage,
  deleteDialogueApi
} from "@/api/chart";
import 'markdown-it-vue/dist/markdown-it-vue.css'
export default {
  name: "echolaAI",
  components: {
    MarkdownItVue
  },
  data() {
    return {
      aiType: 1,
      aiTypeOption: [
        {
          id: 1,
          name: "Baidu",
        },
        {
          id: 2,
          name: "ChatGPT",
        },
      ],
      isNewDialogue: true,
      params: {
        topId: "",
        content: "",
      },
      messageParams: {
        page: 1,
        size: 100000,
      },
      isOpen: false,
      sendMessageLoading: false,
      dialogueList: [],
      messageList: [],
      messageTotal: 0,
      dialogueListTotal: 0,
    };
  },
  computed: {
    ...mapGetters(["businessUserId", "userInfo"]),
  },
  mounted() {
    this.getDialogueList(3);
  },
  watch: {

  },
  methods: {
    addDialogue() {
      this.messageList = [];
      this.params.topId = "";
      this.isNewDialogue = true;
      this.messageList.push({
        content: this.$t('EcholaAi.One message'),
        role: "assistant",
        oneMessage: true
      });
    },
    openMore() {
      this.isOpen = !this.isOpen;
      this.getDialogueList(this.isOpen ? 10000 : 3);
    },
    getDialogueList(size = 10000) {
      getDialogueListApi({
        chatType: this.aiType,
        page: 1,
        size: size,
      }).then((data) => {
        this.dialogueList = data.list.map(item => ({
          ...item,
          check: false
        }));
        this.dialogueListTotal = data.total;
        if (data.list.length) {
          this.isNewDialogue = false;
          if(this.params.topId) return;
          this.params.topId = data.list[0].chatAiTopicId;
          this.getMessageDetail();
        } else {
          this.addDialogue();
        }
      });
    },
    deleteDialogue(row) {
      this.$confirm(
        this.$t("EcholaAi.This conversation will no longer appear here This operation will also delete relevant activity records from your Bard activity records, such as questions, answers, and feedback"),
        this.$t("EcholaAi.Do you want to delete the conversation?"),
        {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteDialogueApi(row.chatAiTopicId).then(() => {
          this.params.topId = "";
          this.messageList = [];
          this.getDialogueList(this.isOpen ? 10000 : 3);
        })
      }).catch(() => {});
    },
    activePopover(arr, row, key) {
      row.check = !row.check;
      this.$nextTick(() => {
        arr.forEach((item, index) => {
          if(item.chatAiTopicId != row.chatAiTopicId) {
            item.check = false;
            this.$refs[key][index].showPopper = false;
          }
        });
      });
    },
    seeMessage(row) {
      if(this.sendMessageLoading) return;
      this.isNewDialogue = false;
      this.params.topId = row.chatAiTopicId;
      this.getMessageDetail();
    },
    getMessageDetail() {
      getEcholaAIMessage({
        ...this.messageParams,
        chatAiTopicId: this.params.topId,
      }).then((data) => {
        this.messageList = data.list;
        this.messageTotal = data.total;
        this.setTopChatRoom();
      });
    },
    setTopChatRoom() {
      setTimeout(() => {
        if(this.$refs.chatLive) {
          this.$refs.chatLive.scrollTo({
            top: this.$refs.chatLive?.scrollHeight,
            behavior: 'instant'
          })
        }
      }, 10);
    },
    copyText(content) {
      const theClipboard = navigator.clipboard;
      theClipboard.writeText(content).then(() => {
        this.$message.success(this.$t('home.Copysucceeded'));
      });
    },
    sendMessage() {
      if(this.sendMessageLoading) return;
      if (!this.params.content) return;
      this.messageList.push({
        role: "user",
        content: this.params.content,
      });
      let obj = JSON.parse(JSON.stringify(this.params));
      this.params.content = "";
      this.sendMessageLoading = true;
      this.setTopChatRoom();
      sendEcholaAIMessage({
        ...obj,
        chatType: this.aiType,
      }).then((data) => {
        this.getDialogueList(this.isOpen ? 10000 : 3);
        this.params.topId = data.topId;
        this.messageList.push({
          ...data,
          role: "assistant"
        });
        this.setTopChatRoom();
      }).finally(() => {
        this.sendMessageLoading = false;
      });
    },
    scroll(e) {},
  },
};
</script>

<style lang="less" scoped>
.AiBox {
  padding: 20px 20px 20px 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    width: 250px;
    height: 100%;
    .header {
      padding: 0 20px;
      .el-button /deep/ {
        border-radius: 40px;
        border: none;
        background: #004c97;
        color: #ffffff;
        &.is-disabled {
          background: #e3e9f1;
        }
      }
    }
    .userInfoBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      margin-top: 20px;
      .userBox {
        display: flex;
        align-items: center;
        gap: 12px;
        height: auto;
        line-height: inherit;
        flex: 1;
        .name {
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          color: #122545;
          width: 75px;
        }
      }
      .aiUserTag {
        width: 78px;
        height: 32px;
        background: #004C97;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .dialogueList {
      margin-top: 32px;
      height: calc(100% - 150px);
      overflow: overlay;
      .title {
        padding-left: 20px;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
        color: #122545;
      }
      .openMore {
        margin-top: 16px;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        color: #004C97;
        cursor: pointer;
        i {
          font-weight: 600;
        }
      }
      .list {
        margin-top: 8px;
        .item {
          width: 100%;
          height: 64px;
          padding: 0 12px 0 20px;
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          .iconBox {
            width: 28px;
            height: 28px;
            background: #ecf6ff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              color: #004C97;
            }
          }
          .content {
            flex: 1;
            min-width: 0;
          }
          .icon-Ellipsis {
            border-radius: 50%;
            width: 28px;
            height: 28px;
            opacity: 0;
            transition: all .3s;
            &::before {
              transform: rotate(90deg);
              position: relative;
              left: -1px;
            }
          }
          &.pending {
            opacity: .7;
            cursor: not-allowed;
            .icon-Ellipsis {
              pointer-events: none;
            }
          }
          &.active {
            background: #F7F9FC;
          }
          &.moreAction {
            background: #F7F9FC;
            .icon-Ellipsis {
              background: #122545;
              color: #ffffff;
              opacity: 1;
            }
          }
          &:hover {
            .active;
            .icon-Ellipsis {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .right {
    min-width: 0;
    flex: 1;
    height: 100%;
    background: #f7f9fc;
    border-radius: 8px;
    padding: 0 20px 20px 20px;
    position: relative;
    .liveContainer {
      width: 100%;
      height: calc(100% - 81px);
      overflow: overlay;
      padding-bottom: 10px;
      padding-top: 20px;
      .item {
        display: flex;
        gap: 6px;
        margin-bottom: 12px;
        .content {
          margin-top: 3px;
          word-break: break-all;
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          color: #122545;
        }
        .aiMessageBox {
          background: #ffffff;
          border-radius: 8px;
          padding: 30px 16px 22px 16px;
          display: flex;
          gap: 8px;
          position: relative;
          .copytext {
            position: absolute;
            top: 8px;
            right: 20px;
            font-size: 12px;
            line-height: 24px;
            color: #637381;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            i {
              font-size: 14px;
            }
            &:hover {
              color: #004C97;
            }
          }
          .echolaAiIcon {
            background: linear-gradient(180deg, #0094D5, #004C97);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              color: #ffffff;
            }
          }
          .content {
            flex: 1;
          }
        }
      }
      .loadingMessage {

      }
    }
    .footerInput {
      background: #ffffff;
      border-radius: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 16px 24px 16px 40px;
      gap: 20px;
      input {
        flex: 1;
        height: 40px;
        font-size: 16px;
        border: none;
      }
      i {
        font-size: 44px;
        color: #e0e4ea;
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          color: #004c97;
        }
      }
    }
  }
}
</style>
