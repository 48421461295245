<template>
  <div v-loading="loading">
    <div class="message_popover_box" v-infinite-scroll="load" :infinite-scroll-distance="20">
      <ul v-if="duplicateRemoval.length">
        <li v-for="(item, index) in duplicateRemoval" class="message_list_item" :key="index">
          <el-popover
            v-if="item.type == '0'"
            placement="left"
            width="100%"
            trigger="hover"
            class="message_popover"
          >
            <el-table stripe size="small" :data="item.customer.memberList">
              <el-table-column
                width="120"
                property="nickName"
                :label="$t('InformationCenter.Name')"
              ></el-table-column>
              <el-table-column
                width="150"
                property="position"
                :label="$t('InformationCenter.Title')"
              ></el-table-column>
            </el-table>
            <template slot="reference">
              <div>
                <el-avatar class="avatarImage" shape="square" fit="cover" :src="item.customer.companyLogoUrl" />
                <div class="content">
                  <textDisplay
                    :isDropActive="true"
                    :titleName="item.customer.aliasName"
                    :titleConnect="$t('InformationCenter.wantstoconnectwithyourcompany')"
                    :createTime="item.applyTime"
                    :content="item.remark"
                    isApply
                    @agree="operation('公司通过', item)"
                    @refuse="OpenDialogVisible('公司', item)"
                  />
                </div>
              </div>
            </template>
          </el-popover>
          <template
            v-if="item.type == '1'"
          >
            <member :info="item.memberUser" :size="48" :time="time" />
            <div class="content">
              <textDisplay
                :isDropActive="true"
                :titleName="item.memberUser.nickName"
                :titleConnect="$t('InformationCenter.wantstojoinyourcompany')"
                :createTime="item.applyTime"
                :content="item.remark"
                isApply
                @agree="OpenDialogVisible('用户通过', item)"
                @refuse="OpenDialogVisible('用户', item)"
              />
              <!-- https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/agree.png -->
            </div>
          </template>
        </li>
      </ul>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
    <assignApproveList
      :approveListShow.sync="approveListShow1"
      :customerCompanyId="customerCompanyId"
      :func="'Assign'"
      @handleClose="approveListShow1 = false"
    />
    <el-dialog
      append-to-body
      :visible.sync="dialogVisible"
      width="390px"
      top="35vh"
      :before-close="() => (dialogVisible = false)"
      v-move-outside
    >
      <span class="dialog_text">
        {{
          dialogText == "用户"
            ? $t("home.Whetherusercompany")
            : dialogText == "公司"
            ? $t("home.youinvitation")
            : $t("home.Whethercompany")
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("deleteEmployee.Cancel")
        }}</el-button>
        <el-button
          :loading="buttonLoading"
          type="primary"
          @click="
            dialogText == '用户'
              ? operation('用户拒绝', dialogData)
              : dialogText == '公司'
              ? operation('公司拒绝', dialogData)
              : operation('用户通过', dialogData)
          "
        >
          {{
            dialogText == "用户通过"
              ? $t("home.ConfirmAgree")
              : $t("home.ConfirmReject")
          }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { uniq } from "lodash";

import textDisplay from "@/components/textDisplay/textDisplay.vue";
import assignApproveList from "@/components/assignApproveList/assignApproveList";

import { approveCustomer, onApprove } from "@/api/customer";
import { companyApplyList } from "@/api/news";

export default {
  name: "apply",
  data() {
    return {
      loading: false,
      data: {
        currentPage: 1,
        pageSize: 20,
        status: 2,
        type: 0
      },
      applyList: [],
      approveListShow1: false,
      customerCompanyId: "",
      dialogVisible: false,
      dialogText: "",
      dialogData: "",
      buttonLoading: false,
      totalPage: 0
    };
  },
  computed: {
    ...mapGetters(["applicationUpdate"]),
    duplicateRemoval() {
      return uniq(this.applyList);
    },
  },
  components: { assignApproveList, textDisplay },
  props: ["time"],
  watch: {
    applicationUpdate() {
      this.onGetList(true);
    }
  },
  mounted() {
    this.onGetList(true);
  },
  methods: {
    load() {
      if (this.data.currentPage < this.totalPage) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    onGetList(clear = false) {
      this.loading = true;
      companyApplyList(this.data).then((data) => {
        if (clear) this.applyList = [];
        this.applyList = this.applyList.concat(data.list);
        this.totalPage = data.pages;
      }).finally(() => {
        this.loading = false;
      });
    },
    OpenDialogVisible(text, data) {
      this.dialogText = text;
      this.dialogData = data;
      this.dialogVisible = true;
    },
    operation(type, data) {
      switch (type) {
        case "公司通过":
          this.approveListShow1 = true;
          this.customerCompanyId = data.unid;
          break;
        case "公司拒绝":
          this.buttonLoading = true;
          approveCustomer({
            unid: data.unid,
            groupUsers: "",
            status: 3,
          })
            .then(() => {
              this.dialogVisible = false;
              this.$message.success(this.$t('home.Refuse to establish a relationship'));
              this.$store.dispatch("user/setReadStatus", "READAPPLY");
              this.onGetList(true);
            })
            .finally(() => (this.buttonLoading = false));
          break;
        case "用户通过":
          this.buttonLoading = true;
          onApprove({
            unid: data.unid,
            status: 1,
          })
            .then(() => {
              this.$message.success(this.$t('home.Member successfully joined'));
              this.$store.dispatch("user/setReadStatus", "READAPPLY");
              this.dialogVisible = false;
              this.onGetList(true);
            })
            .finally(() => (this.buttonLoading = false));
          break;
        case "用户拒绝":
          this.buttonLoading = true;
          onApprove({
            unid: data.unid,
            status: 3,
          })
            .then(() => {
              this.onGetList(true);
              this.$message.success(this.$t('home.Refuse members to join'));
              this.$store.dispatch("user/setReadStatus", "READAPPLY");
              this.dialogVisible = false;
            })
            .finally(() => (this.buttonLoading = false));
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.message_popover {
  width: 100%;
  /deep/ &>.el-popover__reference-wrapper{
    width: 100%;
    display: inline-block;
    &>.el-popover__reference {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
