<template>
  <div v-loading="loading">
    <div class="message_popover_box">
      <ul v-if="ToDoList.length">
        <li
          v-for="(item, index) in ToDoList.filter(item => item.updatePerson)"
          :key="index"
          class="message_list_item"
        >
          <member
            :info="item.updatePerson"
            :size="48"
            :time="time"
          />
          <div class="content" @click="operation(item)">
            <textDisplay
              :isDropActive="!item.read"
              :titleName="item.updatePerson.nickName"
              :titleConnect="`${item.updatePerson ? $t('InformationCenter.update') : $t('InformationCenter.created')}${$t('InformationCenter.Todo')}${item.content}${$t('InformationCenter.in')}`"
              :orderCI="item.ci"
              :content="`${distinguish(item) == 1 ? $t('InformationCenter.notified') : $t('InformationCenter.assignedto')}${$t('InformationCenter.you')}`"
              :createTime="item.createTime"
            />
          </div>
        </li>
      </ul>
      <el-empty 
        v-else 
        :description="$t('overallSituation.noData')" 
        :image="$emptyImage"
        :image-size="90"
      />
    </div>
    <todoInnerDrawer
      :innerDrawer.sync="innerDrawer"
      :todoItemId="todoItemId"
      @handleClose="innerDrawer = false"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import textDisplay from "@/components/textDisplay/textDisplay.vue";
import todoInnerDrawer from "@/components/todoDrawer/todoInnerDrawer";
import { mapGetters } from "vuex";
import { receiveItem, todoItemPage } from "@/api/news";

export default {
  name: "todo",
  data() {
    return {
      loading: false,
      data: {
        currentPage: 1,
        pageSize: 200,
        finish: false
      },
      ToDoList: [],
      todoItemId: null,
      innerDrawer: false,
      commentTotalPage: 0
    };
  },
  computed: {
    ...mapGetters(["businessUserId", "todoUpdate", "readTodo"]),
  },
  components: { todoInnerDrawer, textDisplay },
  props: ["time"],
  watch: {
    todoUpdate() {
      this.onGetList(true);
    },
    readTodo() {
      this.onGetList(true);
    }
  },
  mounted() {
    this.onGetList(true);
  },
  methods: {
    onGetList(clear = false) {
      this.loading = true;
      todoItemPage(this.data).then((data) => {
        if (clear) this.ToDoList = [];
        this.ToDoList = this.ToDoList.concat(data.list.map((res) => ({
          ...res,
          content: res.content.replaceAll(/\<[\s\S]*?\>/g, ""),
          createTime: dayjs(res.updateTime).format("MM/DD HH:mm")
        })));
      }).finally(() => {
        this.loading = false;
      });
    },
    operation(data) {
      this.todoItemId = data.todoItemId;
      this.innerDrawer = true;
    },
    //判断To-do是分配给我，还是完成后通知我
    distinguish(data) {
      const { assignMe, notifyMe } = data;
      let index = 1;
      if (assignMe) {
        index = 2;
      } else if (notifyMe) {
        index = 1;
      }
      return index;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
