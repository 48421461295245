<template>
  <el-drawer
    :title="$t('Getting started')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="1000px"
    append-to-body
    v-move-outside
    v-move-draw="700"
  >
    <div class="demo-drawer__content" v-loading="loading">
      <div class="containerLeft">
        <div class="scheduleTitle">
          <el-input 
            v-model="directoryKeyword" 
            size="small" 
            suffix-icon="el-icon-search"
            :placeholder="$t('dashboardCapacityMonit.Search')" 
          />
          <toolTipIcon 
            :icon="`iconfont ${!isCollect ? 'icon-shoucang' : 'icon-yishoucang'}`" 
            :tooltipText="!isCollect ? $t('home.AlreadyFollow') : $t('home.Nofollow')"
            @click="searchCollect"
          />
        </div>
        <div class="directoryList">
          <template v-if="searchDirectoryList.length">
            <div 
              class="item" 
              :class="directoryActive == item.newbieGuideId ? 'active' : ''"
              v-for="item in searchDirectoryList" 
              :key="item.newbieGuideId"
              @click="jump(item, item.newbieGuideId)">
              <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/fileIcon.png" />
              <div class="content">
                <div class="name">{{ item.newbieGuideName }}</div>
              </div>
            </div>
          </template>
          <el-empty 
            v-else 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="90"
          />
        </div>
      </div>
      <div class="containerRight">
        <template v-if="guideDetail">
          <div class="guideBox">
            <div class="title">
              {{ guideDetail.newbieGuideName }}
              <toolTipIcon 
                :icon="`iconfont ${!guideDetail.collect ? 'icon-shoucang' : 'icon-yishoucang'}`" 
                :tooltipText="!guideDetail.collect ? $t('home.Follow') : $t('home.Unfollow')"
                @click="collectEvent"
              />
            </div>
            <div class="time">{{ this.$dayjs(guideDetail.updateTime).format('YYYY-MM-DD') }}</div>
            <div class="content ql-editor" v-html="guideDetail.newbieGuideContent" @click="$replayImgShow($event)"></div>
          </div>
        </template>
        <el-empty 
          v-else 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
        />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { collectGuide } from "@/api/common";
export default {
  name: "emailAssiste",
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      guideRow: null,
      loading: false,
      directoryActive: null,
      directoryList: [],
      directoryKeyword: "",
      guideDetail: null,
      isCollect: 0
    }
  },
  computed: {
    ...mapGetters([]),
    searchDirectoryList() {
      return this.directoryList.filter(row => {
        return row.newbieGuideName.toLowerCase().indexOf(this.directoryKeyword.toLowerCase()) !== -1 && (this.isCollect == 1 ? row.collect == this.isCollect : 1);
      })
    }
  },
  methods: {
    open(item, row = null) {
      this.drawer = true;
      this.directoryList = item.children;
      if(item.children.length) {
        if(row) {
          this.jump(row, row.newbieGuideId)
        } else {
          this.jump(item.children[0], item.children[0].newbieGuideId)
        }
      } else {
        this.guideDetail = null;
        this.directoryActive = null;
      }
    },
    handleClose() {
      this.drawer = false;
    },
    searchCollect() {
      this.isCollect = !this.isCollect ? 1 : 0;
    },
    collectEvent() {
      if(this.guideDetail.collect == 1) {
        this.$confirm(
          this.$t('Are you sure to cancel the collection'), 
          this.$t("Tips"),
          {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          collectGuide(this.guideDetail.newbieGuideId).then(() => {
            this.guideDetail.collect = 0;
            this.directoryList.forEach(item => {
              if(item.newbieGuideId == this.guideDetail.newbieGuideId) {
                item.collect = 0;
              }
            })
          })
        }).catch(() => {});
        return;
      }
      collectGuide(this.guideDetail.newbieGuideId).then(() => {
        this.guideDetail.collect = 1;
        this.directoryList.forEach(item => {
          if(item.newbieGuideId == this.guideDetail.newbieGuideId) {
            item.collect = 1;
          }
        })
      })
    },
    jump(row, active) {
      this.directoryActive = active;
      this.guideDetail = row;
    }
  },
}
</script>

<style lang="less" scoped>
.scheduleTitle {
  display: flex;
  gap: 20px;
  .el-input {
    flex: 1;
  }
  /deep/ .box {
    i {
      &.icon-yishoucang {
        color: #DF8F31;
      }
    }
  }
}
.directoryList {
  width: 100%;
  overflow: overlay;
  height: calc(100% - 62px);
  .item {
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    img {
      width: 28px;
      height: 28px;
    }
    .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .name {
        max-width: 150px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #122545;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .totalMember {
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
      }
    }
    &.active {
      background: #f7f9fc;
      border-right: 2px solid #076f49;
    }
    &:hover {
      background: #f7f9fc;
    }
  }
}

.guideBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33.6px;
    color: #122545;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .box {
      i {
        &.icon-yishoucang {
          color: #DF8F31;
        }
      }
    }
  }
  .time {
    font-size: 12px;
    font-weight: 500;
    color: #637381;
  }
  .ql-editor {
    padding: 0 !important;
    /deep/ img {
      width: 100%;
    }
  }
}
</style>
