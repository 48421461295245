<template>
  <el-dialog
    :title="$t('Email setting')"
    :visible.sync="show"
    width="577px"
    :before-close="cancel"
    append-to-body>
    <div class="emailConfigBox">
      <div class="item" v-for="(item, index) in options" :key="index">
        <div class="title" @click="item.open = !item.open">
          <div class="iconName">
            <div class="icon">
              <i :class="item.icon"></i>
            </div>
            {{ $t(`emailConfig.${item.name}`) }}
          </div>
          <i :class="item.open ? 'active' : ''" class="iconfont icon-a-Polygon3"></i>
        </div>
        <div class="childBox" v-show="item.open">
          <div class="childItem" v-for="(row, idx) in item.children" :key="idx">
            <div class="label">{{ $t(`emailConfig.${row.label}`) }}</div>
            <div class="content">
              <el-switch
                v-model="row.value"
                active-color="#48AC42"
                inactive-color="#d8d8d8">
              </el-switch>
              <span>{{ row.value ? $t('emailConfig.Opened') : $t('emailConfig.Open Notes') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="left">
        <el-button 
          class="cancel_button" 
          size="small" 
          v-if="userInfo && userInfo.authCompany.admin"
          icon="iconfont icon-youjiandingzhi"
          @click="openEmailPageConfig">
          {{ $t('Email Customization') }}
        </el-button>
      </div>
      <div class="right">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="cancel">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button type="primary" size="small" @click="confirm">
          {{ $t('home.Confirm') }}
        </el-button>
      </div>
    </span>
    <emailPageConfig ref="emailPageConfig" />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { setPersonalization } from "@/api/common";
import emailPageConfig from './emailPageConfig.vue';

export default {
  name: "emailConfig",
  components: {
    emailPageConfig
  },
  data() {
    return {
      show: false,
      options: [{
        name: "Event flow email reminder",
        icon: "el-icon-s-tools",
        open: true,
        children: [{
          label: "allEmailRemind",
          value: false
        }, {
          label: "createOrder",
          value: false
        }, {
          label: "updateOrder",
          value: false
        }, {
          label: "createWaybill",
          value: false
        }, {
          label: "updateWaybill",
          value: false
        }, {
          label: "logisticsPunchCard",
          value: false
        }, {
          label: "purchaseOrder",
          value: false
        }]
      }, {
        name: "System notification email settings",
        icon: "el-icon-s-tools",
        open: false,
        children: [{
          label: "toDoInformation",
          value: false
        }, {
          label: "announcementNotice",
          value: false
        }, {
          label: "historicalOrderImportNotification",
          value: false
        }]
      }]
    }
  },
  computed: {
    ...mapGetters(["personalization", "userInfo"]),
  },
  methods: {
    open() {
      this.show = true;
      this.options.forEach(item => {
        item.children.forEach(row => {
          row.value = this.personalization.emailReminder[row.label]
        });
      });
    },
    cancel() {
      this.show = false;
      this.options.forEach((item, index) => {
        if(!index) item.open = true;
        else item.open = false;
      });
    },
    openEmailPageConfig() {
      this.$refs.emailPageConfig.open();
    },
    confirm() {
      let emailReminder = {};
      this.options.forEach(item => {
        item.children.forEach(row => {
          emailReminder[row.label] = row.value;
        });
      });
      setPersonalization({
        ...this.personalization,
        emailReminder
      }).then(() => {
        this.$store.dispatch("user/setPersonalization", {
          ...this.personalization,
          emailReminder
        })
        this.$message.success(this.$t('Email setting')+this.$t('success'));
        this.cancel();
      });
    }
  },
}
</script>

<style lang="less" scoped>
.emailConfigBox {
  height: calc(100vh - 600px);
  min-height: 400px;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .item {
    width: 100%;
    background: #F7F9FC;
    padding: 14px;
    border-radius: 4px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #637381;
      cursor: pointer;
      .iconName {
        display: flex;
        align-items: center;
        .icon {
          width: 22px;
          height: 22px;
          border-radius: 4px;
          background: #637381;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          i {
          }
        }
      }
      .icon-a-Polygon3 {
        font-size: 12px;
        transform: scale(.5) rotate(180deg);
        &.active {
          transform: scale(.5) rotate(0);
        }
      }
    }
    .childBox {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 22px;
      padding: 0 0 0 35px;
      .childItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
          font-size: 14px;
          line-height: 28px;
          font-weight: 500;
          color: #122545;
          text-align: left;
          width: 160px;
        }
        .content {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 20px;
          span {
            color: #A3B0C6;
            font-size: 12px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    /deep/ .el-button {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 6px 0;
      i {
        font-size: 20px;
      }
    }
  }
}
</style>