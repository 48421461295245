<template>
  <div>
    <el-dialog
      append-to-body
      :visible.sync="show"
      width="358px"
      :before-close="handleClose"
      :title="$t('View permission settings')"
      custom-class="customerAssignPopover"
      v-move-outside
    > 
      <div class="tabs" v-if="mode">
        <div 
          class="item" 
          :class="item.id == tabsValue ? 'active' : ''"
          v-for="(item, index) in tabs" 
          :key="index"
          @click="tabsValue = item.id">
          <i :class="`iconfont ${item.icon}`"></i>
          {{ item.name }}
        </div>
      </div>
      <template v-if="tabsValue == 2">
        <div class="userSearch">
          <el-input v-model="userSearch" :placeholder="$t('Files.Search')" size="small" />
        </div>
        <div class="label">
          <div class="title">{{ $t('Files.Input Name') }}</div>
          <el-checkbox v-if="mode" v-model="allSelect" @change="allSelectChange" />
        </div>
        <div class="inviteBox" v-loading="loading">
          <div class="item" v-for="(item, idx) in options.filter(item => item.nickName.toLowerCase().indexOf(userSearch) != -1)" :key="idx">
            <member :info="item" :size="48" />
            <div class="content">
              <div class="name">
                {{ item.nickName }}
                <div class="tag" v-if="!item.type">{{ $t('memberList.manage') }}</div>
              </div>
              <div class="job">{{ item.position }}</div>
            </div>
            <el-checkbox :disabled="businessUserId == item.openId || !mode" v-model="item.check" />
          </div>
        </div>
      </template>
      <div slot="footer" v-if="mode" class="dialog-footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="handleClose">
          {{ $t('home.Cancel') }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="submit"
        >
          {{ $t("home.Confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCompanyEmployeeList
} from "@/api/customer";
export default {
  name: "seeAuthMemberDialog",
  //home的子组件中使用inject后可以任意地方调用this.reload()来刷新home下的路由
  inject: ["reload"],
  data() {
    return {
      mode: true,
      show: false,
      loading: false,
      allSelect: false,
      userSearch: "",
      options: [],
      tabsValue: 1,
      tabs: [{
        id: 1,
        icon: "icon-jinziji",
        name: this.$t("event.Only me")
      }, {
        id: 0,
        icon: "icon-quanburen",
        name: this.$t("event.Everyone")
      }, {
        id: 2,
        icon: "icon-bufenren",
        name: this.$t("event.Some people can see")
      }]
    };
  },
  props: ["visibleUsers", "eventLimit"],
  computed: {
    ...mapGetters(["companyId", "businessUserId"]),
  },
  watch: {
    options: {
      handler(val) {
        let arr = val.map(item => item.check)
        if(!arr.includes(false)) {
          this.allSelect = true;
        } else {
          this.allSelect = false;
        }
      },
      deep: true,
      immediate: true
    },
    tabsValue(val) {
      if(val == 2) {
        this.loading = true;
        getCompanyEmployeeList(this.companyId).then((data) => {
          this.options = data.memberList.map(item => ({
            ...item, 
            check: item.openId == this.businessUserId
          }));
          this.$nextTick(() => {
            this.options.forEach((item) => {
              // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
              if (this.searchItem(this.visibleUsers, item.openId)) {
                item.check = true;
              }
            });
          });
        }).finally(() => (this.loading = false));
      } else {
        this.options.forEach(item => {
          item.check = false;
        });
      }
    }
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open(isSee = true) {
      //初始化公司人员头像等信息
      this.show = true;
      this.tabsValue = JSON.parse(JSON.stringify(this.eventLimit));
      this.mode = isSee;
    },
    //搜索判断名字是否在下面名单里面
    searchItem(arr, rowName) {
      var item = false;
      try {
        arr.forEach(function (curItem) {
          if (curItem.openId == rowName) {
            item = true;
            throw Error();
          }
        });
      } catch (e) {}
      return item;
    },
    allSelectChange(val) {
      this.options = this.options.map(item => {
        if(item.openId == this.businessUserId) {
          return item
        } else {
          return {
            ...item,
            check: val
          }
        }
      });
    },
    handleClose() {
      this.show = false;
    },
    submit() {
      this.$emit("update:eventLimit", this.tabsValue);
      if(this.tabsValue == 2) {
        let arr = this.options.filter(item => item.check);
        if(!arr.length) {
          this.$message.success(this.$t('home.Please select member'));
          return;
        }
        this.$emit("update:visibleUsers", arr);
      } else {
        this.$emit("update:visibleUsers", []);
      }
      this.handleClose();
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.customerAssignPopover {
  // padding: 0;
 .el-dialog__body {
    padding: 0;
  }
}
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .item {
    padding: 0 10px 5px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #637381;
    line-height: 22px;
    cursor: pointer;
    i {
      font-size: 14px;
      color: #A3B0C6;
    }
    &.active {
      color: #076F49;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #076F49;
        border-radius: 4px;
      }
      i {
        color: #076F49;
      }
    }
    &:hover {
      color: #076F49;
      i {
        color: #076F49;
      }
    }
  }
}
.userSearch {
  padding: 0 16px;
  margin-bottom: 20px;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  .title {
    font-size: 12px;
    line-height: 22px;
    color: #637381;
  }
}
.inviteBox {
  height: 250px;
  overflow: overlay;
  padding-bottom: 16px;
  .item {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 10px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        gap: 5px;
        .tag {
          padding: 2px 6px;
          font-size: 12px;
          line-height: 17px;
          background: #F7F9FC;
          border-radius: 4px;
          color: #076F49;
        }
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
