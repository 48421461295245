<template>
  <el-popover
    :placement="placement"
    width="263" 
    trigger="hover"
    :open-delay="1500">
    <div class="content_user_name_popover">
      <div class="title">
        {{ titleName+titleConnect }}
        <span>{{ orderCI }}</span>
      </div>
      <div class="content" v-if="content">{{ content }}</div>
    </div>
    <template slot="reference">
      <div>
        <div class="content_user_name">
          <div class="left">
            <span :class="isDropActive ? 'active' : ''" />
            {{ titleName+titleConnect }}
            <span v-if="orderCI">{{ orderCI }}</span>
          </div>
          <div class="badge" v-if="unReadNum != 0">
            <span :class="unReadNum > 100 ? 'scale' : ''">{{ unReadNum > 100 ? '99+' : unReadNum }}</span>
          </div>
        </div>
        <div class="content_news">
          <p v-if="content">
            {{ content }}
          </p>
          <span>{{ createTime }}</span>
        </div>
        <div class="btns" v-if="isApply">
          <el-button size="mini" @click="$emit('refuse')">{{ $t("InformationCenter.Refuse") }}</el-button>
          <el-button 
            type="primary" 
            size="mini" 
            @click="$emit('agree')"
            icon="iconfont icon-thumb_up_alt"
          >{{ $t("InformationCenter.Agree") }}</el-button>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: "textDisplay",
  props: {
    isDropActive: {
      type: Boolean,
      default: false,
    },
    createTime: String,
    titleName: String,
    titleConnect: String,
    orderCI: String,
    isApply: {
      type: Boolean,
      default: false
    },
    unReadNum: {
      type: String | Number,
      default: ""
    },
    // 显示主体内容
    content: String,
    // popover 的出现位置top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end
    placement: {
      type: String,
      default: () => {
        return "left";
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content_user_name {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #122545;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .active {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #DC3545;
  }
  span {
    font-size: 14px;
    color: #076f49;
  }
  .badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    background: #DC3545;
    margin-left: 10px;
    span {
      font-size: 12px;
      color: white !important;
      &.scale {
        transform: scale(.8);
      }
    }
  }
}
.content_user_name_popover {
  width: 100%;
  .title {
    font-weight: 500;
    color: #122545; 
    font-size: 14px;
    line-height: 20px;
    span {
      font-size: 14px;
      color: #076f49;
      text-decoration: underline;
    }
  }
  .content {
    font-size: 12px;
    line-height: 17px;
    color: #757D8A;
    margin-top: 2px;
  }
}
.content_news {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #757D8A;
  margin-top: 4px;
  P {
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.btns {
  width: 100%;
  margin-top: 11px;
  display: flex;
  /deep/ .el-button {
    width: 109px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin-right: 4px;
    }
    &+.el-button {
      margin-left: 4px;
    }
  }
}
</style>
