<template>
  <div>
    <div class="avatar">
      <el-popover
        ref="popover"
        placement="bottom"
        width="185"
        trigger="click"
        popper-class="avatar-popover"
        @hide="isSelected = false"
      >
        <div
          @click="isSelected = !isSelected"
          slot="reference"
          class="userBox"
          :class="`${isSelected ? 'active' : ''}`"
        >
          <div class="avatar_name">
            <p>{{ $t(getGreet) }}</p>
            <p>{{ nickname }}</p>
          </div>
          <el-avatar :src="avatarImg" class="avatarImg" />
          <i class="iconfont icon-zhankai" :class="isSelected ? 'active' : ''"></i>
          <i class="socket_online" :class="socketOnline ? 'el-icon-success' : 'el-icon-warning'"></i>
        </div>
        <ul class="user_popover" v-if="userInfo">
          <li>
            <i class="iconfont icon-Mail"></i>
            <span>{{ email }}</span>
          </li>
          <li
            @click="
              linkTo(
                `/profile?name=yourself&businessUserId=${businessUserID}`
              )
            "
          >
            <i class="iconfont icon-User"></i>
            <span>{{ nickname }}</span>
          </li>
          <li
            v-if="userInfo && userInfo.authCompany"
            @click="
              linkTo(
                `/companyProfile?companyName=yourownpage&companyId=${company.companyId}&type=edit`
              )
            "
          >
            <i class="iconfont icon-Home1"></i>
            <span>{{ company_name }}</span>
          </li>
          <li
            v-if="userInfo.authCompany && userInfo.authCompany.admin"
            @click="linkTo('/dashboardCapacityMonit')"
          >
            <i class="iconfont icon-a-zongliuliang1"></i>
            <span>{{ $t("home.dashboardCapacityMonit") }}</span>
          </li>
          <li
            @click="openNoticeServer">
            <i class="iconfont icon-shop"></i>
            <span>{{ serverShopFlag == 1 ? $t('Service Management') : $t("Commodity management") }}</span>
          </li>
          <li 
            v-if="userInfo.authCompany" 
            @click="openMyDocuments">
            <i class="iconfont icon-wodewendang"></i>
            <span>{{ $t("home.MyDocuments") }}</span>
          </li>
          <li 
            v-if="(companyCurrentType == 0 || companyCurrentType == 1) && purchaseStatus == 1" 
            @click="openPurchaseDrawer">
            <i style="font-weight: bold;" class="iconfont icon-caigoudanguanli"></i>
            <span>{{ $t("Purchase.Purchase manage") }}</span>
          </li>
          <li 
            v-if="userInfo.authCompany" 
            @click="openNoticeDrawer">
            <i class="iconfont icon-Bell"></i>
            <span>{{ $t("home.MessageBoard") }}</span>
          </li>
          <li 
            v-if="userInfo.authCompany" 
            @click="openInviteCodeDialog">
            <i class="iconfont icon-a-yaoqingma1"></i>
            <span>{{ $t("home.Invitation Code Management") }}</span>
          </li>
          <li @click="openMessageDialog" v-if="!manageCompany">
            <i class="iconfont icon-lianxiguanliyuan"></i>
            <span>{{ $t("home.ContactAdministrator") }}</span>
          </li>
          <li>
            <el-button
              style="width: 100%"
              type="primary"
              size="small"
              icon="iconfont icon-Export"
              @click="logOut"
            >
              {{ $t("home.LOGOUT") }}
            </el-button>
          </li>
        </ul>
      </el-popover>
    </div>
    <noticeDrawer ref="noticeDrawer" />
    <myDocuments ref="myDocuments" />
    <inviteCodeMange ref="inviteCodeMange" />
    <purchaseManage ref="purchaseManage" />
    <shopServerManage ref="shopServerManage" />
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

import { getCompanyEmployeeList } from "@/api/customer";

import noticeDrawer from "@/components/noticeDrawer";
import myDocuments from "@/components/myDocuments";
import inviteCodeMange from "@/components/inviteCodeMange";
import purchaseManage from "@/components/purchaseManage";
import shopServerManage from "@/components/shopServerManage";

export default {
  components: {
    noticeDrawer,
    myDocuments,
    inviteCodeMange,
    purchaseManage,
    shopServerManage
  },
  data() {
    return {
      user: store.state.user,
      currentPath: "",
      nickname: "",
      businessUserID: "",
      avatarImg: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      email: "xxxxx@echola.cn",
      company_name: "xxxxx LTD.",
      isSelected: false
    }
  },
  inject: ['reload'],
  computed: {
    ...mapGetters([
      "userInfo",
      "company",
      "socketOnline",
      "companyId",
      "manageCompany",
      "companyCurrentType",
      "orderAlias",
      "purchaseStatus",
      "serverShopFlag"
    ]),
    path_change() {
      return window.localStorage.getItem("currentPath");
    },
    getGreet() {
      const now = new Date()
      const hour = now.getHours()
      let greet = ''
      if(hour < 10) {
        greet = 'home.Good morning'
      } else if(hour < 13) {
        greet = 'home.Good noon'
      } else if(hour < 18) {
        greet = 'home.Good afternoon'
      } else if(hour < 24) {
        greet = 'home.Good evening'
      }
      return greet
    }
  },
  watch: {
    userInfo: {
      handler(val) {
        this.businessUserID = val?.openId;
        this.avatarImg = val?.avatarUrl;
        this.nickname = val?.nickName;
        this.email = val?.email;
      },
      deep: true,
      immediate: true
    },
    company: {
      handler(val) {
        this.company_name = val?.aliasName;
      },
      deep: true,
      immediate: true
    },
    path_change(newData, oldData) {
      this.currentPath = newData;
      window.localStorage.setItem("currentPath", this.currentPath);
    }
  },
  created() {
    let beginTime = 0; //开始时间
    let differTime = 0; //时间差
    window.onunload = function () {
      differTime = new Date().getTime() - beginTime;
      if (differTime <= 5) {
        this.currentPath = "/index";
        window.localStorage.setItem("currentPath", this.currentPath);
      } else {
        this.currentPath = window.localStorage.getItem("currentPath") || "";
      }
    };
    window.onbeforeunload = function () {
      beginTime = new Date().getTime();
    };
  },
  mounted() {

  },
  methods: {
    openNoticeServer() {
      this.$refs.shopServerManage.open(this.companyId, this.serverShopFlag);
      this.$refs.popover.showPopper = false;
    },
    openNoticeDrawer() {
      this.$refs.noticeDrawer.open();
      this.$refs.popover.showPopper = false;
    },
    openMyDocuments() {
      this.$refs.myDocuments.open();
      this.$refs.popover.showPopper = false;
    },
    openPurchaseDrawer() {
      this.$refs.purchaseManage.open();
      this.$refs.popover.showPopper = false;
    },
    openInviteCodeDialog() {
      this.$refs.inviteCodeMange.open();
    },
    openMessageDialog() {
      getCompanyEmployeeList(this.companyId).then((data) => {
        this.$store.dispatch("user/setMessageDialogOpen", {
          visible: true,
          isElastic: false,
          top: null,
          otherParty: data.memberList.filter(item => !item.type)[0]
        });
      }).finally(() => (this.forwarderLoading = false));
    },
    //同步初始化重构界面客户清单，按顺序
    linkTo(path) {
      if (this.path == path) {
        return this.reload();
        // 暂时不做reload操作
      } else {
        this.$router.push({
          path,
        });
        this.currentPath = path;
        window.localStorage.setItem("currentPath", path);
      }
      //下面的程序实现点击按钮关闭popover，前提需要在popover上加上ref=popover
      this.$refs.popover.showPopper = false;
    },
    logOut() {
      this.$confirm(
        `${this.$t("home.Confirmtheexit")}?`,
        this.$t("home.warn"),
        {
          confirmButtonText: this.$t("home.Confirm"),
          cancelButtonText: this.$t("home.Cancel"),
          type: "warning",
        }
      ).then(async () => {
        await this.$store.dispatch("socket/logout");
        await this.$store.dispatch("user/logout");
      });
      this.$refs.popover.showPopper = false;
      //导航栏退出后重新初始化
      this.currentPath = "/index";
      window.localStorage.setItem("currentPath", this.currentPath);
    },
  },
}
</script>

<style lang="less" scoped>
.user_popover {
  padding: 7px 0;

  li {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 28px;
    cursor: pointer;
    color: #122545;
    display: flex;
    // align-items: center;
    font-weight: 500;
    transition: all .1s;
    &:last-child {
      padding-top: 8px;
      padding-bottom: 0;
    }
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    span {
      margin-top: 8px;
      line-height: 16px;
      flex: 1;
    }
    i {
      margin-right: 6px;
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #076F49;
      font-size: 14px;
      background: #F7F9FC;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background: #F7F9FC;
    }
  }

  li:last-child {
    &:hover {
      background: none;
    }
  }
}
</style>