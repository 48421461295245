<template>
  <div>
    <el-drawer
      :title="$t('Purchase.Purchase manage')"
      :visible.sync="show"
      size="982px"
      :before-close="CloseDrawer"
      append-to-body
      v-move-outside
      v-move-draw="800"
    >
      <div class="manageBox">
        <div class="searchBox">
          <div class="left">
            <el-input 
              v-model="params.purchaseNo" 
              suffix-icon="el-icon-search" 
              size="small"
              style="width: 160px;"
              :placeholder="$t('Files.Search')" 
              @keyup.native.enter="getPurchaseList(true)"
            />
            <el-input 
              v-model="params.username" 
              suffix-icon="el-icon-search" 
              size="small"
              style="width: 160px;"
              :placeholder="$t('customerInfo.Input People')" 
              @keyup.native.enter="getPurchaseList(true)"
            />
            <el-select
              v-model="params.buyerCompanyId"
              filterable
              size="small"
              :placeholder="$t('order.Please select')"
              style="width: 160px;"
              @change="getPurchaseList(true)"
              clearable
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-select
              v-model="params.purchaseStatus"
              size="small"
              :placeholder="$t('order.Status')"
              style="width: 120px;"
              clearable
              @change="getPurchaseList(true)"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </div>
          <div class="right">
            <el-button
              v-if="companyCurrentType == '0'"
              type="primary"
              @click="createPurchase"
              size="small"
              icon="el-icon-plus"
            >{{ $t('Purchase.Create purchase') }}</el-button>
            <el-button 
              size="small" 
              class="layoutBtn"
              :icon="`${purchaseLayout ? 'iconfont icon-liushi' : 'el-icon-menu'}`"
              @click="purchaseLayout = !purchaseLayout"
            />
          </div>
        </div>
        <div class="list" v-if="loading && purchaseLayout">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(purchaseItem, index) in list" :key="index">
              <div class="purchaseItem">
                <div class="header">
                  <el-tooltip 
                    class="item" 
                    effect="light"
                    popper-class="ec-tooltip"
                    :content="companyCurrentType == 0 ? $t(`Purchase.Status describe${purchaseItem.purchaseStatus}`) : $t(`Purchase.Status vendor describe${purchaseItem.purchaseStatus}`)" 
                    placement="top">
                    <div 
                      class="tag"
                      :class="purchaseItem.purchaseStatus == 1 ? 'send' : purchaseItem.purchaseStatus == 2 ? 'edit' : purchaseItem.purchaseStatus == 3 ? 'order' : ''"
                    >{{ purchaseItem.purchaseStatusName }}</div>
                  </el-tooltip>
                  <div class="text line1" v-showTootip>{{ purchaseItem.purchaseNo }}</div>
                  <el-popover
                    ref="popover_order"
                    class="el-pop"
                    placement="right-start"
                    trigger="click"
                    @hide="purchaseItem.check = false"
                    popper-class="ec-popper"
                  >
                    <div class="popperMenuItem" @click="exportFiles(purchaseItem)">
                      <i class="el-icon-download" />
                      <span>{{ $t("order.export") }}</span>
                    </div>
                    <div 
                      class="popperMenuItem" 
                      v-if="purchaseItem.purchaseStatus != 3 && companyCurrentType == 0 && (companyCurrentType == 0 ? purchaseItem.buyerUserId == businessUserId : purchaseItem.sellerUserId == businessUserId)"
                      @click="deletePurchase(purchaseItem, index)">
                      <i class="iconfont icon-shanchu" />
                      <span>{{ $t("order.Delete") }}</span>
                    </div>
                    <div 
                      class="popperMenuItem" 
                      @click="seePurchaseRecords(purchaseItem)" 
                      v-if="purchaseItem.purchaseStatus != 0">
                      <i class="el-icon-time" />
                      <span>{{ $t("Operation records") }}</span>
                    </div>
                    <i
                      class="iconfont icon-Ellipsis downEllipsis"
                      :class="purchaseItem.check ? 'active' : ''"
                      slot="reference"
                      @click.stop="activePopover(list, purchaseItem, 'popover_order')"
                    />
                  </el-popover>
                </div>
                <div class="main">
                  <div class="previewBtn">
                    <div class="btn" @click="openPurchaseDialog(purchaseItem, 3)">{{ $t('Click to preview') }}</div>
                  </div>
                  <div class="item">
                    <div class="label">{{ $t('Dashboard.Product') }}</div>
                    <div class="content" v-showTootip>
                      <span v-for="(row, index) in purchaseItem.detailJson" :key="index">
                        {{index+1}}.{{(row.productCode+row.productName) || '--'}}
                      </span>
                    </div>
                  </div>
                  <div class="item">
                    <div class="label">{{ $t('order.cny') }}</div>
                    <div class="content" v-showTootip>
                      {{ purchaseItem.totalPrice }} {{ purchaseItem.totalPriceUnit }}
                    </div>
                  </div>
                  <div class="itemFlex">
                    <div class="item">
                      <div class="label">{{ $t('Purchase.Mode of transport') }}</div>
                      <div class="content" v-showTootip>
                        <i :class="`iconfont icon-purchase-${purchaseItem.deliveryMethod}`"></i>
                        {{ $isFindData(transportMode, purchaseItem.deliveryMethod, 'value', 'key') || '--' }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">{{ $t('Purchase.Delivery terms') }}</div>
                      <div class="content" v-showTootip>
                        {{ $isFindData(payMentData, purchaseItem.transactionTerms, 'value', 'key') || '--' }}
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="label">{{ $t('Purchase.Delivery date') }}</div>
                    <div class="content" v-showTootip>
                      {{ purchaseItem.deliveryDate || '--' }} {{ purchaseItem.deliveryTimezone ? $isFindData(timezone, purchaseItem.deliveryTimezone, 'value', 'key').split(" ")[0] : '--' }}
                    </div>
                  </div>
                  <div class="item">
                    <div class="label">{{ $t('todo.Notes') }}</div>
                    <div class="content" v-showTootip>
                      {{ purchaseItem.remark || '--' }}
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <div class="left">
                    <member v-if="purchaseItem.openUserDto" :info="purchaseItem.openUserDto" :size="32" />
                    <div class="content">
                      {{ purchaseItem.purchaseStatus != 3 ? $t('Purchase.Updated On') : $t('Purchase.Generated on') }}<br/>
                      {{ $dayjs(purchaseItem.updateTime).format("YYYY/MM/DD HH:mm") }}
                    </div>
                  </div>
                  <div 
                    class="btn" 
                    @click="openPurchaseDialog(purchaseItem, 2)" 
                    v-if="purchaseItem.purchaseStatus != 3 && (companyCurrentType == 0 ? purchaseItem.buyerUserId == businessUserId : purchaseItem.sellerUserId == businessUserId)">
                    {{ $t('home.Edit') }}
                  </div>
                  <div 
                    class="btn" 
                    @click="seeOrderDetail(purchaseItem.orderId)" 
                    v-else-if="(companyCurrentType == 0 || companyCurrentType == 1) && purchaseItem.purchaseStatus == 3">
                    {{ $t('order.See order') }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          
        </div>
        <div style="margin-top: 30px;" v-else>
          <el-table
            :data="list"
            height="calc(100vh - 210px)"
            style="width: 100%">
            <el-table-column
              prop="date"
              :label="$t('order.Status')"
              width="120">
              <template slot-scope="{row}">
                <el-tooltip 
                  class="item" 
                  effect="light"
                  popper-class="ec-tooltip"
                  :content="companyCurrentType == 0 ? $t(`Purchase.Status describe${row.purchaseStatus}`) : $t(`Purchase.Status vendor describe${row.purchaseStatus}`)" 
                  placement="top">
                  <div 
                    class="tag"
                    :class="row.purchaseStatus == 1 ? 'send' : row.purchaseStatus == 2 ? 'edit' : row.purchaseStatus == 3 ? 'order' : ''"
                  >{{ row.purchaseStatusName }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchaseNo"
              :label="$t('Purchase.Purchase Number')"
              width="180">
            </el-table-column>
            <el-table-column
              :label="$t('Purchase.Details')"
              width="200">
              <template slot-scope="{row}">
                <span v-if="row.detailJson[0].productCode+row.detailJson[0].productName">
                  {{ `${row.detailJson[0].productCode+row.detailJson[0].productName}+${row.detailJson[0].productQuantity}+${row.detailJson[0].productPrice+row.detailJson[0].productUnit}`+`${row.detailJson.length > 1 ? '...' : ''}` }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="deliveryAddress"
              :label="$t('Purchase.Delivery address')"
              width="200">
            </el-table-column>
            <el-table-column
              prop="paymentMethod"
              :label="$t('Purchase.Payment method')"
              width="150">
              <template slot-scope="{row}">
                {{ $isFindData(payMentMethods, row.paymentMethod, 'value', 'key') || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Upload attachments')"
              width="200">
              <template slot-scope="{row}">
                <fileBlocks
                  v-if="row.attachmentUrl"
                  type="message"
                  :embedUrl="row.attachmentUrl"
                  :embedId="row.attachmentId"
                  background="#ffffff"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              :label="$t('todo.Notes')"
              width="150">
            </el-table-column>
            <el-table-column
              :label="companyCurrentType != 0 ? $t('home.customerTitle1') : $t('home.customerTitle')"
              width="150">
              <template slot-scope="{row}">
                <div class="customerName">
                  <member :info="row.openUserDto" :size="28"></member>
                  <span>{{ row.openUserDto && row.openUserDto.nickName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="updateTime"
              :label="$t('home.updateTime')"
              width="180">
            </el-table-column>
            <el-table-column :label="$t('Files.Action')" width="90" fixed="right">
              <template slot-scope="{row, $index}">
                <div class="action">
                  <el-tooltip 
                    effect="light" 
                    popper-class="ec-tooltip"
                    :content="$t('Preview')" 
                    placement="top">
                    <div
                      class="btn" 
                      @click.stop="openPurchaseDialog(row, 3)">
                      <i class="iconfont icon-xianshi"></i>
                    </div>
                  </el-tooltip>
                  <el-dropdown
                    trigger="click" 
                    placement="bottom-start"
                    @visible-change="visibleDropdownChange">
                    <div
                      class="btn" 
                      :class="tableSelectedShow === $index ? 'active' : ''"
                      @click="tableSelectedShow = $index">
                      <i class="iconfont icon-Ellipsis"></i>
                    </div>
                    <el-dropdown-menu class="ec-popper" slot="dropdown">
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="el-icon-download"
                        @click.native="exportFiles(row)"
                      >{{ $t("order.export") }}</el-dropdown-item>
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="iconfont icon-shanchu"
                        v-if="row.purchaseStatus != 3 && companyCurrentType == 0 && (companyCurrentType == 0 ? row.buyerUserId == businessUserId : row.sellerUserId == businessUserId)"
                        @click.native="deletePurchase(row, $index)"
                      >{{ $t("order.Delete") }}</el-dropdown-item>
                      <el-dropdown-item
                        class="popperMenuItem"
                        icon="el-icon-time"
                        v-if="row.purchaseStatus != 0"
                        @click.native="seePurchaseRecords(row)"
                      >{{ $t("Operation records") }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination
            @size-change="changePageSizeHandler"
            @current-change="changePageHandler"
            :current-page="params.page"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :total="total"
            background
            layout="prev, pager, next, sizes, total, jumper"
          />
        </div>
      </div>
    </el-drawer>
    <el-dialog 
      :visible.sync="dialogOperationVisible"
      :title="$t('Operation records')"
      :before-close="() => dialogOperationVisible = false"
      width="700px"
      append-to-body
      v-move-outside>
      <el-table
        :data="operationList"
        style="width: 100%"
        :show-header="false">
        <el-table-column
          prop="createUser"
          width="120">
          <template slot-scope="{row}">
            <div class="customerName" v-if="row.createUser">
              <member :info="row.createUser" :size="28"></member>
              <span>{{ row.createUser.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          width="500px">
          <template slot-scope="{row}">
            <div class="operationContent">
              {{ row.type == 1 ? $t('Create') : row.type == 2 ? $t('home.Edit') : $t('home.Delete') }}
              <div class="operationDetail">
                <p class="line1" v-showTootip>{{ row.fieldName }} {{ row.fieldValueOld || row.fieldValueNew }}</p>
                <span v-if="row.type == 2">{{ $t('do') }}</span>
                <p class="line1" v-if="row.type == 2" v-showTootip>{{ row.fieldName }} {{ row.fieldValueNew }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('Files.CreateTime')"
          width="180"
          fixed="right">
        </el-table-column>
      </el-table>
    </el-dialog>
    <exportFiles ref="exportFiles" :row="fileExportRow" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPurchaseListApi, getPurchaseStatusListApi, deletePurchaseApi, getPurchaseRecords } from "@/api/order";
import { getUserCustomList } from "@/api/customer";
import exportFiles from './exportFiles.vue';
export default {
  components: {
    exportFiles
  },
  data() {
    return {
      show: false,
      loading: true,
      purchaseLayout: false,
      tableSelectedShow: null,
      dialogOperationVisible: false,
      params: {
        keyword: "",
        username: "",
        buyerCompanyId: "",
        purchaseStatus: "",
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      companyList: [],
      statusList: [],
      operationList: [],
      fileExportRow: null
    }
  },
  computed: {
    ...mapGetters(["companyCurrentType", "transportMode", "payMentData", "timezone", "payMentMethods", "businessUserId"])
  },
  mounted() {
    this.$myEvent.on("onPurchaseList", this.getPurchaseList);
  },
  methods: {
    open() {
      this.show = true;
      this.getPurchaseStatusList();
      this.getCompanyArraySelect();
      this.getPurchaseList();
    },
    seePurchaseRecords(row) {
      getPurchaseRecords({
        page: 1,
        size: 100000,
        purchaseId: row.purchaseId
      }).then((data) => {
        this.operationList = data.list;
        this.dialogOperationVisible = true;
      })
    },
    exportFiles(row) {
      this.fileExportRow = row;
      this.$refs.exportFiles.open();
    },
    getPurchaseStatusList() {
      getPurchaseStatusListApi().then((data) => {
        this.statusList = data;
      })
    },
    getPurchaseList(clear = false) {
      if(clear) {
        this.params.page = 1;
      }
      this.loading = false;
      getPurchaseListApi(this.params).then((data) => {
        if(clear) this.list = [];
        this.total = data.total;
        this.list = data.list.map(item => ({
          ...item,
          check: false
        }));
      }).finally(() => {
        this.loading = true;
      })
    },
    async getCompanyArraySelect(name, cb) {
      const { list } = await getUserCustomList({
        companyName: name,
        companyType: this.companyCurrentType == 1 ? 0 : 1,
        currentPage: 1,
        pageSize: 10000
      });
      this.companyList = list.map((data) => ({
        value: data.companyId,
        label: data?.aliasName || data?.name
      }));
    },
    deletePurchase(row, index) {
      this.$confirm(this.$t("After deletion, it cannot be restored"), this.$t("Are you sure to delete it?"), {
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        deletePurchaseApi(row.purchaseId).then(() => {
          this.getPurchaseList();
          this.$message.success(this.$t('event.Delete successfully'));
        })
      }).catch(() => {});
    },
    openPurchaseDialog(row, mode) {
      this.$myEvent.emit("openPurchase", {
        mode,
        row
      });
    },
    createPurchase() {
      this.$myEvent.emit("openPurchase", {
        mode: 1
      });
    },
    seeOrderDetail(orderId) {
      this.CloseDrawer();
      this.$router.push({
        path: "/order/orderDetails",
        query: {
          orderId
        },
      });
    },
    changePageHandler(page) {
      this.params.page = page;
      this.getPurchaseList();
    },
    changePageSizeHandler(pageSize) {
      this.params.size = pageSize;
      this.getPurchaseList();
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    activePopover(arr, row, key) {
      row.check = !row.check;
      this.$nextTick(() => {
        arr.forEach((item, index) => {
          if(item.purchaseId != row.purchaseId) {
            item.check = false;
            this.$refs[key][index].showPopper = false;
          }
        });
      });
    },
    CloseDrawer() {
      this.show = false;
    }
  }
}
</script>

<style lang="less" scoped>
.manageBox {
  padding: 24px 24px 16px 24px;
  height: 100%;
  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
.searchBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  .left {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .right {
    .layoutBtn {
      padding: 8px;
      cursor: pointer;
      /deep/ i {
        font-size: 12px;
      }
      /deep/ .el-icon-menu {
        transform: scale(1.2);
      }
    }
  }
}
.tag {
  padding: 2px 5px;
  background: #FF8311;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  &.send {
    background: #076F49;
  }
  &.edit {
    background: #0094D5;
  }
  &.order {
    background: #A3B0C6;
  }
}
.list {
  margin-top: 30px;
  width: 100%;
  height: calc(100% - 100px);
  overflow: overlay;
  .purchaseItem {
    width: 100%;
    height: 280px;
    background: #ffffff;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #E0E4EA;
    .header {
      display: flex;
      align-items: center;
      gap: 4px;
      .text {
        flex: 1;
        min-width: 0;
        font-size: 14px;
        font-weight: 500;
        color: #122545;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 11px;
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        .content {
          font-size: 12px;
          color: #637381;
        }
      }
      .btn {
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #E0E4EA;
        font-size: 12px;
        font-weight: 500;
        color: #122545;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          opacity: .7;
        }
      }
    }
    .main {
      padding: 14px 12px;
      background: #F7F9FC;
      border-radius: 4px;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      .previewBtn {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        .btn {
          padding: 8px 16px;
          background: rgba(20, 39, 70, 0.60);
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 500;
          font-size: 12px;
          cursor: pointer;
        }
      }
      &:hover {
        .previewBtn {
          opacity: 1;
        }
      }
      .item {
        display: flex;
        align-items: center;
        .label {
          width: 70px;
          font-size: 14px;
          line-height: 22px;
          color: #637381;
        }
        .content {
          flex: 1;
          font-size: 14px;
          color: #122545;
          font-weight: 600;
          word-break: break-all;
          i {
            color: #637381;
          }
        }
      }
      .itemFlex {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.operationContent {
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 14px;
  color: #637381;
  .operationDetail {
    display: flex;
    align-items: center;
    gap: 15px;
    p {
      width: 110px;
      color: #076F49;
      font-weight: 600;
    }
  }
}
</style>